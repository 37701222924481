import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import {
  GET_COMPANY_API_KEY_ERROR,
  GET_COMPANY_API_KEY_REQUESTING,
  GET_COMPANY_API_KEY_SUCCESS,
  UPDATE_COMPANY_API_KEY_ERROR,
  UPDATE_COMPANY_API_KEY_REQUESTING,
  UPDATE_COMPANY_API_KEY_SUCCESS,
} from './constants'

function* getCompanyApiKeyFlow({ payload }) {
  try {
    const response = yield call(getCompanyApiKeyApi, payload)
    yield put({ type: GET_COMPANY_API_KEY_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: GET_COMPANY_API_KEY_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getCompanyApiKeyApi({ companyId }) {
  const QUERY_COMPANY_API_KEY = gql`
    query($companyId: ID!) {
      company(_id: $companyId) {
        misc {
          api {
            apiKey
            apiKeyActive
            apiKeyGeneratedAt
          }
        }
      }
    }
  `

  const query = print(QUERY_COMPANY_API_KEY)
  const variables = {
    companyId,
  }

  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables })

  return response.data.company
}

function* updateCompanyApiKeyFlow(action) {
  try {
    const payload = yield call(updateCompanyApiKeyApi, action.payload)
    yield put({ type: UPDATE_COMPANY_API_KEY_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_API_KEY_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function updateCompanyApiKeyApi({ companyId, groupId, newStatus, apiKeyActive, newKey }) {
  const MUTATION_COMPANY_API_KEY = gql`
    mutation (
        $groupId: String!
        $newStatus: Boolean
        $apiKeyActive: Boolean
        $newKey: Boolean
      ){
      updateCompanyApiKey(
        groupId: $groupId
        newStatus: $newStatus
        apiKeyActive: $apiKeyActive
        newKey: $newKey
      ){
        apiKey
        apiKeyActive
        apiKeyGeneratedAt
      }
    }
  `

  const query = print(MUTATION_COMPANY_API_KEY)
  const variables = {
    groupId,
    companyId,
    newStatus,
    apiKeyActive,
    newKey,
  }

  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables })

  return response.data.updateCompanyApiKey
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_COMPANY_API_KEY_REQUESTING, getCompanyApiKeyFlow),
    takeLatest(UPDATE_COMPANY_API_KEY_REQUESTING, updateCompanyApiKeyFlow),
  ])
}

import React from 'react'
import MessageContent from './messageContent.style'
import { message } from 'antd'

message.config({
  maxCount: 1,
  icon: null,
})

const feedbackMessage = (text, duration = 3) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
    color: '#FFF'
  }

  message.open({
    content: (
      <MessageContent style={style}>
        {text}
      </MessageContent>
    ),
    duration 
  })
}

export default feedbackMessage

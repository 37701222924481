import { encode, decode } from './encoding'

export function getFromLS(key) {
  const value = decode(localStorage.getItem(encode(key)))
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export function setInLS(key, value) {
  localStorage.setItem(encode(key), encode(JSON.stringify(value)))
}

export function getFromSS(key) {
  return decode(JSON.parse(sessionStorage.getItem(encode(key))))
}

export function setInSS(key, value) {
  sessionStorage.setItem(encode(key), encode(JSON.stringify(value)))
}
import {
  GET_ADMIN_PROFILE_REQUESTING,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_ERROR,
  UPDATE_ADMIN_PROFILE_REQUESTING,
  UPDATE_ADMIN_PSSWRD_SUCCESS,
  UPDATE_ADMIN_PROFILE_SUCCESS,
  UPDATE_ADMIN_PROFILE_ERROR
} from './constants'

const initialState = {
  requesting: false,
  updating: false,
  successful: false,
  data: {},
  error: '',
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_ADMIN_PROFILE_REQUESTING:
      return {
        ...state,
        requesting: true,
        updatedPsswrd: false,
        successful: false,
      }
    case GET_ADMIN_PROFILE_SUCCESS:
      const [ companyAdmin ] = payload

      return {
        ...state,
        requesting: false,
        successful: true,
        data: { ...companyAdmin },
        error: '',
      }
    case GET_ADMIN_PROFILE_ERROR:
      return {
        ...state,
        requesting: false,
        successful: false,
        error: payload.error,
      }

    case UPDATE_ADMIN_PROFILE_REQUESTING:
      return {
        ...state,
        updating: true,
        updatedPsswrd: false,
        successful: false,
      }
    case UPDATE_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        updating: false,
        successful: true,
        data: { ...payload },
        error: '',
      }
    case UPDATE_ADMIN_PSSWRD_SUCCESS:
      return {
        ...state,
        updating: false,
        successful: true,
        updatedPsswrd: true,
        data: { ...payload },
        error: '',
      }
    case UPDATE_ADMIN_PROFILE_ERROR:
      return {
        ...state,
        updating: false,
        successful: false,
        error: payload.error,
      }
    default:
      return state
  }
}

import {
  VALIDATE_TOKEN_REQUESTING,
  VALIDATE_TOKEN_SUCCESSFUL,
  VALIDATE_TOKEN_ERROR,
  SEND_FORGOT_EMAIL_REQUESTING,
  SEND_FORGOT_EMAIL_SUCCESSFUL,
  SEND_FORGOT_EMAIL_ERROR,
  UPDATE_PSSWRD_REQUESTING,
  UPDATE_PSSWRD_SUCCESSFUL,
  UPDATE_PSSWRD_ERROR,
} from './constants'

const initialState = {
  validateTokenRequesting: false,
  validateTokenSuccessful: false,
  validToken: false,
  validateTokenData: {},
  sendEmailRequesting: false,
  sendEmailSuccessful: false,
  updatePsswrdRequesting: false,
  updatePsswrdSuccessful: false,
  error: '',
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case VALIDATE_TOKEN_REQUESTING:
      return {
        ...state,
        validateTokenRequesting: true,
      }
    case VALIDATE_TOKEN_SUCCESSFUL:
      return {
        ...state,
        validateTokenRequesting: false,
        validateTokenSuccessful: true,
        validToken: true,
        validateTokenData: { ...payload },
      }
    case VALIDATE_TOKEN_ERROR:
      return {
        ...state,
        validateTokenRequesting: false,
        validToken: false,
        error: payload.error,
      }

    case SEND_FORGOT_EMAIL_REQUESTING:
      return {
        ...state,
        sendEmailRequesting: true,
        sendEmailSuccessful: false,
        error: '',
      }
    case SEND_FORGOT_EMAIL_SUCCESSFUL:
      return {
        ...state,
        sendEmailRequesting: false,
        sendEmailSuccessful: true,
        error: '',
      }
    case SEND_FORGOT_EMAIL_ERROR:
      return {
        ...state,
        sendEmailRequesting: false,
        sendEmailSuccessful: false,
        error: payload.error,
      }
    case UPDATE_PSSWRD_REQUESTING:
      return {
        ...state,
        updatePsswrdRequesting: true,
        updatePsswrdSuccessful: false,
      }
    case UPDATE_PSSWRD_SUCCESSFUL:
      return {
        ...state,
        updatePsswrdRequesting: false,
        updatePsswrdSuccessful: true,
        error: '',
      }
    case UPDATE_PSSWRD_ERROR:
      return {
        ...state,
        updatePsswrdRequesting: false,
        updatePsswrdSuccessful: false,
        error: payload.error,
      }
    default:
      return state
  }
}


export const GET_COMPANY_BENEFITS_REQUESTING = 'GET_COMPANY_BENEFITS_REQUESTING'
export const GET_COMPANY_BENEFITS_SUCCESS = 'GET_COMPANY_BENEFITS_SUCCESS'
export const GET_COMPANY_BENEFITS_ERROR = 'GET_COMPANY_BENEFITS_ERROR'

export const GET_POINTS_CUSTOM_BENEFITS_REQUESTING = 'GET_POINTS_CUSTOM_BENEFITS_REQUESTING'
export const GET_POINTS_CUSTOM_BENEFITS_SUCCESS = 'GET_POINTS_CUSTOM_BENEFITS_SUCCESS'
export const GET_POINTS_CUSTOM_BENEFITS_ERROR = 'GET_POINTS_CUSTOM_BENEFITS_ERROR'

export const GET_GRAPH_INFO_REQUESTING = 'GET_GRAPH_INFO_REQUESTING'
export const GET_GRAPH_INFO_SUCCESS = 'GET_GRAPH_INFO_SUCCESS'
export const GET_GRAPH_INFO_ERROR = 'GET_GRAPH_INFO_ERROR'

export const GET_MY_REPORTS_REQUESTING = 'GET_MY_REPORTS_REQUESTING'
export const GET_MY_REPORTS_SUCCESS = 'GET_MY_REPORTS_SUCCESS'
export const GET_MY_REPORTS_ERROR = 'GET_MY_REPORTS_ERROR'

export const SEND_REPORT_REQUESTING = 'SEND_REPORT_REQUESTING'
export const SEND_REPORT_SUCCESS = 'SEND_REPORT_SUCCESS'
export const SEND_REPORT_ERROR = 'SEND_REPORT_ERROR'

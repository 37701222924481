export const GET_BENEFIT_DISCOVERY_LIST = 'GET_BENEFIT_DISCOVERY_LIST'
export const GET_BENEFIT_DISCOVERY_LIST_SUCCESS = 'GET_BENEFIT_DISCOVERY_LIST_SUCCESS'
export const GET_BENEFIT_DISCOVERY_LIST_ERROR = 'GET_BENEFIT_DISCOVERY_LIST_ERROR'
export const GET_BENEFIT_DISCOVERY = 'GET_BENEFIT_DISCOVERY'
export const GET_BENEFIT_DISCOVERY_SUCCESS = 'GET_BENEFIT_DISCOVERY_SUCCESS'
export const GET_BENEFIT_DISCOVERY_ERROR = 'GET_BENEFIT_DISCOVERY_ERROR'
export const CREATE_BENEFIT_DISCOVERY = 'CREATE_BENEFIT_DISCOVERY'
export const CREATE_BENEFIT_DISCOVERY_SUCCESS = 'CREATE_BENEFIT_DISCOVERY_SUCCESS'
export const CREATE_BENEFIT_DISCOVERY_ERROR = 'CREATE_BENEFIT_DISCOVERY_ERROR'
export const UPDATE_BENEFIT_DISCOVERY = 'UPDATE_BENEFIT_DISCOVERY'
export const UPDATE_BENEFIT_DISCOVERY_SUCCESS = 'UPDATE_BENEFIT_DISCOVERY_SUCCESS'
export const UPDATE_BENEFIT_DISCOVERY_ERROR = 'UPDATE_BENEFIT_DISCOVERY_ERROR'
export const ADD_BENEFIT_CATEGORY = 'ADD_BENEFIT_CATEGORY'
export const ADD_BENEFIT_CATEGORY_SUCCESS = 'ADD_BENEFIT_CATEGORY_SUCCESS'
export const ADD_BENEFIT_CATEGORY_ERROR = 'ADD_BENEFIT_CATEGORY_ERROR'
export const ACTIVE_MODAL_BENEFIT_DISCOVERY = 'ACTIVE_MODAL_BENEFIT_DISCOVERY'
export const ACTIVE_MODAL_BENEFIT_DISCOVERY_REQUESTING = 'ACTIVE_MODAL_BENEFIT_DISCOVERY_REQUESTING'
export const ACTIVE_MODAL_BENEFIT_DISCOVERY_ERROR = 'ACTIVE_MODAL_BENEFIT_DISCOVERY_ERROR'
export const ADD_QUANTITY_COLLABORATORS = 'ADD_QUANTITY_COLLABORATORS'

import {
  CHECK_SSO_REQUESTING,
  CHECK_SSO_SUCCESSFUL,
  CHECK_SSO_ERROR,
  LOGIN_REQUESTING,
  LOGIN_SUCCESSFUL,
  LOGIN_ERROR,
  LOGOUT_REQUESTING,
  LOGOUT_SUCCESSFUL,
  LOGOUT_ERROR,
  RECAPTCHA_SAVE_TOKEN,
} from './constants'

const initState = {
  appClientId: '',
  shouldBeRedirectedToOS: false,
  ssoError: '',
  ssoRequesting: false,
  ssoSuccess: false,
  loginRequesting: false,
  logoutRequesting: false,
  success: false,
  error: '',
}

export default function reducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case CHECK_SSO_REQUESTING:
      return {
        ...state,
        ssoRequesting: true,
        ssoSuccess: false,
      }
    case CHECK_SSO_SUCCESSFUL: 
      return {
        ...state,
        appClientId: payload.appClientId,
        shouldBeRedirectedToOS: payload.shouldBeRedirectedToOS,
        ssoRequesting: false,
        ssoSuccess: true,
      }
    case CHECK_SSO_ERROR: 
      return {
        ...state,
        ssoRequesting: false,
        ssoSuccess: false,
        ssoError: payload.error,
      }

    case LOGIN_REQUESTING:
      return {
        ...state,
        loginRequesting: true,
        success: false,
        error: '',
      }
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        loginRequesting: false,
        success: true,
      }
    case LOGOUT_REQUESTING:
      return {
        ...state,
        logoutRequesting: true,
        success: false,
        error: '',
      }
    case LOGOUT_SUCCESSFUL:
      return {
        ...state,
        logoutRequesting: false,
        success: true,
      }
    case LOGIN_ERROR:
    case LOGOUT_ERROR:
      return {
        ...state,
        loginRequesting: false,
        logoutRequesting: false,
        success: false,
        error: payload.error
      }
    case RECAPTCHA_SAVE_TOKEN:
      return {
        ...state,
        recaptchaTokens: {
          ...state.recaptcha,
          [payload.action]: payload.token
        }
      }
    default:
      return state
  }
}

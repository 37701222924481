export const ADD_USER_REQUESTING = 'ADD_USER_REQUESTING'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_ERROR = 'ADD_USER_ERROR'

export const CHECK_CPF_REQUESTING = 'CHECK_CPF_REQUESTING'
export const CHECK_CPF_SUCCESS = 'CHECK_CPF_SUCCESS'
export const CHECK_CPF_ERROR = 'CHECK_CPF_ERROR'

export const GET_USERS_REQUESTING = 'GET_USERS_REQUESTING'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const GET_USER_PERMISSIONS_REQUESTING = 'GET_USER_PERMISSIONS_REQUESTING'
export const GET_USER_PERMISSIONS_SUCCESS = 'GET_USER_PERMISSIONS_SUCCESS'
export const GET_USER_PERMISSIONS_ERROR = 'GET_USER_PERMISSIONS_ERROR'

export const SEND_ADMIN_EMAIL_TOKEN_ERROR = 'SEND_ADMIN_EMAIL_TOKEN_ERROR'
export const SEND_ADMIN_EMAIL_TOKEN_REQUESTING = 'SEND_ADMIN_EMAIL_TOKEN_REQUESTING'
export const SEND_ADMIN_EMAIL_TOKEN_SUCCESS = 'SEND_ADMIN_EMAIL_TOKEN_SUCCESS'

export const UPDATE_USER_REQUESTING = 'UPDATE_USER_REQUESTING'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const UPDATE_USER_PERMISSIONS_REQUESTING = 'UPDATE_USER_PERMISSIONS_REQUESTING'
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS'
export const UPDATE_USER_PERMISSIONS_ERROR = 'UPDATE_USER_PERMISSIONS_ERROR'

export const UPDATE_USERS_PERMISSION_REQUESTING = 'UPDATE_USERS_PERMISSION_REQUESTING'
export const UPDATE_USERS_PERMISSION_SUCCESS = 'UPDATE_USERS_PERMISSION_SUCCESS'
export const UPDATE_USERS_PERMISSION_ERROR = 'UPDATE_USERS_PERMISSION_ERROR'

export const UPDATE_USER_STEEPS_REQUESTING = 'UPDATE_USER_STEEPS_REQUESTING'
export const UPDATE_USER_STEEPS_SUCCESS = 'UPDATE_USER_STEEPS_SUCCESS'
export const UPDATE_USER_STEEPS_ERROR = 'UPDATE_USER_STEEPS_ERROR'

export const DELETE_USERS_ERROR = 'DELETE_USER_ERROR'
export const DELETE_USERS_REQUESTING = 'DELETE_USER_REQUESTING'
export const DELETE_USERS_SUCCESS = 'DELETE_USER_SUCCESS'

export const VALIDATE_ADMIN_TOKEN_ERROR = 'VALIDATE_ADMIN_TOKEN_ERROR'
export const VALIDATE_ADMIN_TOKEN_REQUESTING = 'VALIDATE_ADMIN_TOKEN_REQUESTING'
export const VALIDATE_ADMIN_TOKEN_SUCCESS = 'VALIDATE_ADMIN_TOKEN_SUCCESS'

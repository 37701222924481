
export const ADD_COMPANY_CUSTOM_BENEFIT_REQUESTING = 'ADD_COMPANY_CUSTOM_BENEFIT_REQUESTING'
export const ADD_COMPANY_CUSTOM_BENEFIT_SUCCESS = 'ADD_COMPANY_CUSTOM_BENEFIT_SUCCESS'
export const ADD_COMPANY_CUSTOM_BENEFIT_ERROR = 'ADD_COMPANY_CUSTOM_BENEFIT_ERROR'

export const CREATE_COMPANY_BENEFITS_ERROR = 'CREATE_COMPANY_BENEFITS_ERROR'
export const CREATE_COMPANY_BENEFITS_REQUESTING = 'CREATE_COMPANY_BENEFITS_REQUESTING'
export const CREATE_COMPANY_BENEFITS_SUCCESS = 'CREATE_COMPANY_BENEFITS_SUCCESS'

export const DELETE_COMPANY_CUSTOM_BENEFIT_REQUESTING = 'DELETE_COMPANY_CUSTOM_BENEFIT_REQUESTING'
export const DELETE_COMPANY_CUSTOM_BENEFIT_SUCCESS = 'DELETE_COMPANY_CUSTOM_BENEFIT_SUCCESS'
export const DELETE_COMPANY_CUSTOM_BENEFIT_ERROR = 'DELETE_COMPANY_CUSTOM_BENEFIT_ERROR'

export const UPDATE_COMPANY_BENEFITS_REQUESTING = 'UPDATE_COMPANY_BENEFITS_REQUESTING'
export const UPDATE_COMPANY_BENEFITS_SUCCESS = 'UPDATE_COMPANY_BENEFITS_SUCCESS'
export const UPDATE_COMPANY_BENEFITS_ERROR = 'UPDATE_COMPANY_BENEFITS_ERROR'

export const UPDATE_COMPANY_CUSTOM_BENEFIT_REQUESTING = 'UPDATE_COMPANY_CUSTOM_BENEFIT_REQUESTING'
export const UPDATE_COMPANY_CUSTOM_BENEFIT_SUCCESS = 'UPDATE_COMPANY_CUSTOM_BENEFIT_SUCCESS'
export const UPDATE_COMPANY_CUSTOM_BENEFIT_ERROR = 'UPDATE_COMPANY_CUSTOM_BENEFIT_ERROR'

export const BenefitTypes = {
  Custom: 'custom',
  Plastic: 'plastic',
  PlasticExclusiveBalance: 'plasticExclusiveBalance',
  Virtual: 'virtual',
  Corporate: 'expenseManagement',
}
export const GET_BILLET_REPORT_LINK_REQUESTING = 'GET_BILLET_REPORT_LINK_REQUESTING'
export const GET_BILLET_REPORT_LINK_SUCCESS = 'GET_BILLET_REPORT_LINK_SUCCESS'
export const GET_BILLET_REPORT_LINK_ERROR = 'GET_BILLET_REPORT_LINK_ERROR'

export const GET_COMPANY_BILLETS_REQUESTING = 'GET_COMPANY_BILLETS_REQUESTING'
export const GET_COMPANY_BILLETS_SUCCESS = 'GET_COMPANY_BILLETS_SUCCESS'
export const GET_COMPANY_BILLETS_ERROR = 'GET_COMPANY_BILLETS_ERROR'

export const UPDATE_BILLET_REQUESTING = 'UPDATE_BILLET_REQUESTING'
export const UPDATE_BILLET_SUCCESS = 'UPDATE_BILLET_SUCCESS'
export const UPDATE_BILLET_ERROR = 'UPDATE_BILLET_ERROR'

export const UPDATE_DEPOSIT_DATE_REQUESTING = 'UPDATE_DEPOSIT_DATE_REQUESTING'
export const UPDATE_DEPOSIT_DATE_SUCCESS = 'UPDATE_DEPOSIT_DATE_SUCCESS'
export const UPDATE_DEPOSIT_DATE_ERROR = 'UPDATE_DEPOSIT_DATE_ERROR'

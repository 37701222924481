import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  SEND_CONFIRM_ACCOUNT_EMAIL_REQUESTING,
  SEND_CONFIRM_ACCOUNT_EMAIL_SUCCESSFUL,
  SEND_CONFIRM_ACCOUNT_EMAIL_ERROR,
} from './constants'
import { urlMap, request, handleApiError } from '../../services/api'
import gql from 'graphql-tag'
import { print } from 'graphql'


function* sendForgotEmailFlow({ payload }) {
  try {
    const apiFunc = payload.type === 'user'
      ? sendPersonConfirmAccountEmailApi
      : sendCompanyConfirmAccountEmailApi
    yield call(apiFunc, payload)
    yield put({ type: SEND_CONFIRM_ACCOUNT_EMAIL_SUCCESSFUL })
  } catch (error) {
    yield put({
      type: SEND_CONFIRM_ACCOUNT_EMAIL_ERROR,
      payload: { error: handleApiError(error) }
    })
  }
}

async function sendCompanyConfirmAccountEmailApi({ emailAssociated }) {
  await request.post(
    `${urlMap.company}/company/resendConfirmationEmail`,
    { emailAssociated },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

async function sendPersonConfirmAccountEmailApi({ personId }) {
  const url = `${urlMap.person}/person/graphql`

  const SEND_CONFIRMATION_EMAIL = gql`
    mutation SendConfirmationEmail {
      sendConfirmationEmail {
        sent
        errors {
          message
        }
      }
    }
  `
  const query = print(SEND_CONFIRMATION_EMAIL)

  const options = {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${personId}`,
    }
  }
  const response = await request.graphql(url, { query }, options)
  return { data: response.data['sendConfirmationEmail'] }
}

export default function* rootSaga() {
  yield all([
    takeLatest(SEND_CONFIRM_ACCOUNT_EMAIL_REQUESTING, sendForgotEmailFlow),
  ])
}
import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Caption, Link, StatusIcon, Subtitle2, feedbackMessage } from '@flash-tecnologia/flamingo-ui'
import {
  dirtyWhiteColor,
  greyColor,
} from '@flash-tecnologia/flamingo-ui/src/baseStyles'
import {
  cnpjMask,
  errorBackMessages,
} from '@flash-tecnologia/flamingo-ui/src/utils'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSharedDataProcessing } from '../../../actions/hooks'
import { ACKNOWLEDGE_ASYNC_ERROR_MUTATION, CANCEL_ASYNC_PROCESS } from '../../../actions/mutations'
import { CHECK_DATA_PROCESSING_QUERY } from '../../../actions/queries'
import { getFromLS } from '../../../services/security'
import { segmentTracking } from '../../../services/utils/segmentTrack'
import Loading from '../../loading'
import { RemiderItem, ReminderCard, ReminderListContainer, ReminderTitle } from '../../../containers/Company/Employees/components/modals/CheckoutModal2/components/CheckoutFeedback/style'

const StatusBar = styled.div`
  height: 32px;
  margin-bottom: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
`

const statusDictionary = {
  'done': 'Sucesso',
  'error': 'Erro',
}

const stepDictionary = {
  validating: 'Validações',
  addingEmployees: 'Adicionar Colaboradores',
  deposits: 'Criar Depósitos',
  generatingReports: 'Gerar Relatórios',
  payment: 'Concluir Pagamento',
}

const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: auto;
  svg {
    height: 16px;
    width: 16px;
    margin-left: auto;
  }
`

/**  */
const BackgroundProcessLoading = () => {
  const [dataProcessingState, updateDataProcessingLS] = useSharedDataProcessing([])
  const [onLoadingState, setOnloadingState] = useState(false)
  const [companies, setCompanies] = useState([])
  const [retryCount, setRetryCount] = useState(0)
  const [requestStatus, setRequestStatus] = useState('queue')
  const [processResponse, setProcessResponse] = useState('aguardando')
  const [statusMessage, setStatusMessage] = useState({})
  const { adminId, companyId } = getFromLS('clientData')

  const hasFinished = (status) => (
    ['done', 'error'].includes(status)
  )

  const isRequesting = (status) => (
    ['queue', 'processing', 'delaying'].includes(status)
  )

  const hasError = (status) => (
    status === 'error'
  )

  const handleConfirm = () => {
    segmentTracking('Portal_Employee_DepositSheetProcessing_Confirm')
    updateDataProcessingLS([{ massPayment: false, massRegister: false }])
  }

  const [cancelAsyncProcess, {
    loading: cancelAsyncProcessLoading
  }] = useMutation(CANCEL_ASYNC_PROCESS, {
    variables: {
      adminId,
      companyId,
    },
    onError: (error) => {
      feedbackMessage(errorBackMessages[error.message] || errorBackMessages.default)
    },
    onCompleted: () => {
      updateDataProcessingLS([{ massPayment: false, massRegister: false }])
    }
  })
  
  const handleCancel = () => {
    segmentTracking('Portal_Employee_DepositSheetProcessing_Cancel')
    if (cancelAsyncProcessLoading) return
    return cancelAsyncProcess()
  }

  const [acknowledgeError, {
    loading: acknowledgeErrorLoading
  }] = useMutation(ACKNOWLEDGE_ASYNC_ERROR_MUTATION, {
    variables: {
      companyId,
    },
    onError: (error) => {
      feedbackMessage(errorBackMessages[error.message] || errorBackMessages.default)
    },
    onCompleted: () => {
      updateDataProcessingLS([{ massPayment: false, massRegister: false }])
    }
  })
  const handleAcknowledgeError = () => {
    segmentTracking('Portal_Employee_DepositSheetProcessing_Error')
    if (acknowledgeErrorLoading) return
    return acknowledgeError()
  }

  const {
    data,
    refetch
  } = useQuery(CHECK_DATA_PROCESSING_QUERY, {
    variables: {
      companyId,
    }
  })
  useEffect(() => {
    if (retryCount) return
    const dataProcessing = data?.company?.dataProcessing || []
    handleStepAndStatusState(dataProcessing)
    updateDataProcessingLS(dataProcessing)
  }, [data])

  useEffect(() => {
    setOnloadingState(dataProcessingState[0]?.massPayment || dataProcessingState[0]?.massRegister)
  }, [dataProcessingState])

  useEffect(() => {
    if (!onLoadingState) {
      setRequestStatus('queue')
      setProcessResponse('aguardando')
      setRetryCount(0)
    }
    const timeout = setTimeout(() => {
      setRetryCount(retryCount+1)
    }, 2000)
    return () => clearTimeout(timeout)
  }, [onLoadingState])

  useEffect(() => {
    if (!retryCount || !onLoadingState) return
    if (hasFinished(requestStatus)) return
    const timout = refetchAndCheck()
    return () => clearTimeout(timout)
  }, [retryCount])

  const refetchAndCheck = () => {
    const refetchDelay = retryCount > 30
      ? 30000
      : 10000
    return setTimeout(async () => {
      const response = await refetch()
      const dataProcessing = response?.data?.company?.dataProcessing || []
      handleStepAndStatusState(
        dataProcessing
      )
      setRetryCount(retryCount+1)
    }, refetchDelay)
  }

  useEffect(() => {
    switch (requestStatus) {
      case 'done': 
        if (dataProcessingState[0]?.massPayment) {
          setStatusMessage({
            title: 'Pedido(s) gerado(s)',
            message: 'Pedido(s) concluído(s) com sucesso. Você também receberá mais informações sobre o pedido por email.',
          })
        } else {
          setStatusMessage({
            title: 'Processo finalizado',
            message: 'Colaboradores cadastrados/editados com sucesso.'
          })
        }
        break
      case 'error': 
        setStatusMessage({
          title: 'Tivemos um erro',
          message: 'Tivemos um erro inesperado ao processar o(s) seu(s) pedido(s).',
        })
        break
      case 'delaying':
        setStatusMessage({
          title: 'Processando o(s) seu(s) pedido(s)',
          message: <>
            Seu(s) pedido(s) está demorando mais do que o esperado. 
            Caso deseje interromper o processo, você ainda pode
            {<> <Link style={localStyle.contentText} onClick={() => handleCancel()}>cancelar</Link> </>}
            a solicitação ou aguardar mais um pouco conosco.
          </>
        })
        break
      default:
        if (dataProcessingState[0]?.massPayment) {
          setStatusMessage({
            title: 'Processando o(s) seu(s) pedido(s)',
            message: 'Aguarde enquanto estamos processando seu(s) pedido(s). Algumas ferramentas como pedidos, configuração de benefício, e gestão de colaboradores estarão momentaneamente indisponíveis.'
          })
        } else {
          setStatusMessage({
            title: 'Processando o(s) seu(s) pedido(s)',
            message: 'Aguarde enquanto estamos  processando o cadastro/edição dos colaboradores. Algumas ferramentas como pedidos, configuração de benefício, e gestão de colaboradores estarão momentaneamente indisponíveis.'
          })
        }
        break      
    }
  }, [requestStatus, dataProcessingState])

  const handleStepAndStatusState = (dataProcessing) => {
    if (!dataProcessing.length) return

    setProcessResponse('processando')
    setCompanies([])
    const hasAllProcessFinished = dataProcessing.every((process) => (
      hasFinished(process.status)
    ))

    if (hasAllProcessFinished) {
      const anyProcessHasError = dataProcessing.some(process => hasError(process.status))
      setRequestStatus(anyProcessHasError ? 'error' : 'done')
      setCompanies(dataProcessing.map((process) => ({
        cnpj: process.cnpj,
        status: process.status,
        step: process.step
      })))
      return setProcessResponse('')
    }
    const { requestDate } = dataProcessing.find(process => isRequesting(process.status))
    if (requestDate && moment().diff(requestDate) > 600000) {
      return setRequestStatus('delaying')
    }
    setRequestStatus('processing')
  }

  const CloseButton = () => {
    const onClick = hasError(requestStatus) ? handleAcknowledgeError : handleConfirm
    return (
      <Button
        disabled={acknowledgeErrorLoading}
        loading={acknowledgeErrorLoading}
        onClick={onClick}
        size={'default'}
        type='primary'
        style={{ marginTop: '8px' }}
      >
        OK
      </Button>
    )
  }

  const Footer = () => {
    const loading = isRequesting(requestStatus)
    return (
      <>
        <ReminderCard>
          <ReminderListContainer as='div'>
            <ReminderTitle>
              Lembre-se
            </ReminderTitle>
            <RemiderItem as='p'>
              Boletos tem prazo de compensação de até 3 dias uteis e apenas após esse prazo é possivel antecipar depósitos do pedido.  
            </RemiderItem>
          </ReminderListContainer>
        </ReminderCard>
        {processResponse !== '' && <StatusBar>{processResponse}</StatusBar>}
        {loading ? <Loading customSize={24} style={localStyle.loadingWrapper}/> : <CloseButton/>}
      </>
    )
    
  }

  return (
    onLoadingState 
      ? <div style={localStyle.card}>
        <Subtitle2 style={{ textAlign: 'center' }}>{statusMessage.title}</Subtitle2>
        <Caption style={localStyle.contentText} >
          {statusMessage.message}
        </Caption>
        {companies.length &&  hasFinished(requestStatus) ? (
        <StatusWrapper style={{ flexDirection: 'column' }}>
          {companies.map((company) => (
            <StatusWrapper key={company.cnpj} style={{ flexDirection: 'row' }}>
              <Caption style={{ ...localStyle.contentText, ...localStyle.contentTextBold, textAlign: 'left' }} >
                {cnpjMask(company.cnpj)} - {statusDictionary[company.status]} {hasError(company.status) ? `- ${stepDictionary[company.step]}` : ''}
              </Caption>
              <StatusIcon
                text={hasError(company.status) && 'Falha no pedido desta empresa, tente novamente'}
              />
            </StatusWrapper>
          ))}
        </StatusWrapper>
        ) : null}
        <Footer/>
       </div>
      : null
  )
}

const localStyle = {
  card: {
    background: dirtyWhiteColor,
    borderRadius: '8px',
    boxShadow: `0px 2px 5px ${greyColor}2B`,
    display: 'flex',
    flexDirection: 'column',
    left: '48px',
    position: 'fixed',
    padding: '18px',
    top: '72px',
    width: '320px',
    zIndex: 4,
  },
  title: {
    height: '26px',
    textAlign: 'center',
    marginBottom: '8px',
  },
  contentText: {
    alignItems: 'center',
    fontWeight: 'unset',
    fontSize: '12px',
    textAlign: 'justify',
    margin: '8px 0',
    justifyContent: 'space-evenly',
  },
  contentTextBold: {
    fontWeight: 'bold',
  },
  loadingWrapper: {
    width: '100%',
    height: '24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
  }
}

export default BackgroundProcessLoading

export const ADDRESS_ERROR = 'ADDRESS_ERROR'
export const ADDRESS_REQUESTING = 'ADDRESS_REQUESTING'
export const ADDRESS_SUCCESSFUL = 'ADDRESS_SUCCESSFUL'

export const GET_CONTRACT_ERROR = 'GET_CONTRACT_ERROR'
export const GET_CONTRACT_REQUESTING = 'GET_CONTRACT_REQUESTING'
export const GET_CONTRACT_SUCCESSFUL = 'GET_CONTRACT_SUCCESSFUL'

export const GET_DEFAULT_CONTRACT_ERROR = 'GET_DEFAULT_CONTRACT_ERROR'
export const GET_DEFAULT_CONTRACT_REQUESTING = 'GET_DEFAULT_CONTRACT_REQUESTING'
export const GET_DEFAULT_CONTRACT_SUCCESSFUL = 'GET_DEFAULT_CONTRACT_SUCCESSFUL'

export const GET_REGISTER_CONTRACT_ERROR = 'GET_REGISTER_CONTRACT_ERROR'
export const GET_REGISTER_CONTRACT_REQUESTING = 'GET_REGISTER_CONTRACT_REQUESTING'
export const GET_REGISTER_CONTRACT_SUCCESSFUL = 'GET_REGISTER_CONTRACT_SUCCESSFUL'

export const HAS_CLIENT_SIGNED_UP_ERROR = 'HAS_CLIENT_SIGNED_UP_ERROR'
export const HAS_CLIENT_SIGNED_UP_REQUESTING = 'HAS_CLIENT_SIGNED_UP_REQUESTING'
export const HAS_CLIENT_SIGNED_UP_SUCCESSFUL = 'HAS_CLIENT_SIGNED_UP_SUCCESSFUL'

export const REGISTER_NEW_COMPANY_ERROR = 'REGISTER_NEW_COMPANY_ERROR'
export const REGISTER_NEW_COMPANY_REQUESTING = 'REGISTER_NEW_COMPANY_REQUESTING'
export const REGISTER_NEW_COMPANY_SUCCESSFUL = 'REGISTER_NEW_COMPANY_SUCCESSFUL'

export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const SIGNUP_REQUESTING = 'SIGNUP_REQUESTING'
export const SIGNUP_SUCCESSFUL = 'SIGNUP_SUCCESSFUL'

export const GET_COMPANY_INFO_REQUESTING = 'GET_COMPANY_INFO_REQUESTING'
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS'
export const GET_COMPANY_INFO_ERROR = 'GET_COMPANY_INFO_ERROR'

export const GET_CONTRACTS_REQUESTING = 'GET_CONTRACTS_REQUESTING'
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS'
export const GET_CONTRACTS_ERROR = 'GET_CONTRACTS_ERROR'

export const GET_CONTRACT_LINK_REQUESTING = 'GET_CONTRACT_LINK_REQUESTING'
export const GET_CONTRACT_LINK_SUCCESS = 'GET_CONTRACT_LINK_SUCCESS'
export const GET_CONTRACT_LINK_ERROR = 'GET_CONTRACT_LINK_ERROR'

export const UPDATE_COMPANY_INFO_REQUESTING = 'UPDATE_COMPANY_INFO_REQUESTING'
export const UPDATE_COMPANY_INFO_SUCCESS = 'UPDATE_COMPANY_INFO_SUCCESS'
export const UPDATE_COMPANY_INFO_ERROR = 'UPDATE_COMPANY_INFO_ERROR'

import findIndex from 'lodash/findIndex'
import {
  ADD_EMPLOYEES_ERROR,
  ADD_EMPLOYEES_REQUESTING,
  ADD_EMPLOYEES_SUCCESS,
  CREATE_DEPOSITS_REQUESTING,
  CREATE_DEPOSITS_SUCCESS,
  CREATE_DEPOSITS_ERROR,
  CHECK_DELETE_SHEET_DATA_ERROR,
  CHECK_DELETE_SHEET_DATA_REQUESTING,
  CHECK_DELETE_SHEET_DATA_SUCCESS,
  CHECK_ADD_SHEET_DATA_ERROR,
  CHECK_ADD_SHEET_DATA_REQUESTING,
  CHECK_ADD_SHEET_DATA_SUCCESS,
  CHECK_SHEET_DATA_ERROR,
  CHECK_SHEET_DATA_REQUESTING,
  CHECK_SHEET_DATA_SUCCESS,
  CLEAN_EMPLOYEE_POINTS_CONFIGURATION_ERROR,
  CLEAN_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING,
  CLEAN_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_REQUESTING,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_MULTIPLE_EMPLOYEES_ERROR,
  DELETE_MULTIPLE_EMPLOYEES_REQUESTING,
  DELETE_MULTIPLE_EMPLOYEES_SUCCESS,
  GENERATE_BILLET_REQUESTING,
  GENERATE_BILLET_SUCCESS,
  GENERATE_BILLET_ERROR,
  GET_CHECKOUT_DATA_ERROR,
  GET_CHECKOUT_DATA_REQUESTING,
  GET_CHECKOUT_DATA_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COMPANY_GROUPS_ERROR,
  GET_COMPANY_GROUPS_REQUESTING,
  GET_COMPANY_GROUPS_SUCCESS,
  GET_COMPANY_ADMIN_REQUESTING,
  GET_COMPANY_ADMIN_SUCCESS,
  GET_COMPANY_ADMIN_ERROR,
  GET_SHOULD_RENDER_PAT_REQUESTING,
  GET_SHOULD_RENDER_PAT_SUCCESS,  
  GET_SHOULD_RENDER_PAT_ERROR,
  GET_COMPANY_TAGS_REQUESTING,
  GET_COMPANY_TAGS_SUCCESS,
  GET_COMPANY_TAGS_ERROR,
  GET_COMPANY_VIRTUAL_BALANCE_REQUESTING,
  GET_COMPANY_VIRTUAL_BALANCE_SUCCESS,
  GET_COMPANY_VIRTUAL_BALANCE_ERROR,
  GET_COMPANY_SHEETS_REQUESTING,
  GET_COMPANY_SHEETS_SUCCESS,
  GET_COMPANY_REQUESTING,
  GET_COMPANY_SUCCESS,
  GET_EMPLOYEES_ERROR,
  GET_EMPLOYEES_REQUESTING,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_POINTS_CONFIGURATION_ERROR,
  GET_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING,
  GET_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS,
  UPDATE_EMPLOYEE_POLICY_ERROR,
  UPDATE_EMPLOYEE_POLICY_REQUESTING,
  UPDATE_EMPLOYEE_POLICY_SUCCESS,
  PAY_WITH_BALANCE_REQUESTING,
  PAY_WITH_BALANCE_SUCCESS,
  PAY_WITH_BALANCE_ERROR,
  RESEND_WELCOME_NOTIFICATION_ERROR,
  RESEND_WELCOME_NOTIFICATION_REQUESTING,
  RESEND_WELCOME_NOTIFICATION_SUCCESS,
  SEND_EMPLOYEES_REPORT_REQUESTING,
  RESEND_POINTS_NOTIFICATION_ERROR,
  RESEND_POINTS_NOTIFICATION_REQUESTING,
  RESEND_POINTS_NOTIFICATION_SUCCESS,
  SEND_EMPLOYEES_REPORT_SUCCESS,
  SEND_EMPLOYEES_REPORT_ERROR,
  SEND_SINGLE_EMPLOYEE_DOCUMENTS_REQUESTING,
  SEND_SINGLE_EMPLOYEE_DOCUMENTS_SUCCESS,
  SEND_SINGLE_EMPLOYEE_DOCUMENTS_ERROR,
  SEND_EMPLOYEES_DOCUMENTS_REQUESTING,
  SEND_EMPLOYEES_DOCUMENTS_SUCCESS,
  SEND_EMPLOYEES_DOCUMENTS_ERROR,
  UPDATE_ALL_EMPLOYEES_BENEFITS_SUCCESS,
  UPDATE_ACCESS_DATE_ERROR,
  UPDATE_ACCESS_DATE_REQUESTING,
  UPDATE_ACCESS_DATE_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_REQUESTING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ADMIN_ERROR,
  UPDATE_COMPANY_ADMIN_REQUESTING,
  UPDATE_COMPANY_ADMIN_SUCCESS,
  UPDATE_COMPANY_TAGS_ERROR,
  UPDATE_COMPANY_TAGS_REQUESTING,
  UPDATE_COMPANY_TAGS_SUCCESS,
  UPDATE_EMPLOYEE_BENEFIT_ERROR,
  UPDATE_EMPLOYEE_BENEFIT_REQUESTING,
  UPDATE_EMPLOYEE_BENEFIT_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_REQUESTING,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_TAGS_ERROR,
  UPDATE_EMPLOYEE_TAGS_REQUESTING,
  UPDATE_EMPLOYEE_TAGS_SUCCESS,
  UPDATE_MULTIPLE_EMPLOYEES_ERROR,
  UPDATE_MULTIPLE_EMPLOYEES_REQUESTING,
  UPDATE_MULTIPLE_EMPLOYEES_SUCCESS,
  UPDATE_GROUP_NAME_ERROR,
  UPDATE_GROUP_NAME_REQUESTING,
  UPDATE_GROUP_NAME_RESET,
  UPDATE_GROUP_NAME_SUCCESS,
  UPSERT_DEDUCTIONS_REQUESTING,
  UPSERT_DEDUCTIONS_SUCCESS,
  UPSERT_DEDUCTIONS_ERROR,
  DELETE_EMPLOYEES_SHEET_REQUESTING,
  DELETE_EMPLOYEES_SHEET_SUCCESS,
  DELETE_EMPLOYEES_SHEET_ERROR,
  RESET_EMPLOYEE_POINTS_CONFIGURATION,
  UPDATE_PAT_TERMS_REQUESTING,
  UPDATE_PAT_TERMS_SUCCESS,
  UPDATE_PAT_TERMS_ERROR,
} from './constants'

const initialState = {
  checkoutBenefits: [],
  checkoutData: {},
  companyData: {},
  companyAdminData: {},
  companyGroups: [],
  companyTags: {},
  companySheet: {},
  depositsData: {},
  employees: [],
  employee: {},
  employeePointsConfiguration: {},
  employeesInfo: {},
  report: '',
  resendWelcomeNotificationError: '',
  resendPointsNotificationError: '',
  selectedEmployees: [],
  updateEmployeeBenefitData: {},
  updateEmployeeData: {},
  virtualBalance: 0,
  patModalVisible: false
}

export default (state = initialState, action) => {
  const { type, payload = {}} = action
  const { error } = payload
  switch (type) {
    case ADD_EMPLOYEES_REQUESTING:
      return {
        ...state,
        addEmployeesRequesting: true,
        addEmployeesSuccess: false,
        addEmployeesError: false,
      }
    case ADD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        addEmployeesData: payload,
        addEmployeesRequesting: false,
        addEmployeesSuccess: true,
        addEmployeesError: false,
      }
    case ADD_EMPLOYEES_ERROR:
      return {
        ...state,
        addEmployeesRequesting: false,
        addEmployeesSuccess: false,
        addEmployeesError: error,
      }
    case CREATE_DEPOSITS_REQUESTING:
      return {
        ...state,
        createDepositsRequesting: true,
        createDepositsSuccess: false,
        createDepositsError: false,
      }
    case CREATE_DEPOSITS_SUCCESS:
      return {
        ...state,
        depositsData: payload,
        createDepositsRequesting: false,
        createDepositsSuccess: true,
        createDepositsError: false,
      }
    case CREATE_DEPOSITS_ERROR:
      return {
        ...state,
        createDepositsRequesting: false,
        createDepositsSuccess: false,
        createDepositsError: error,
      }
    case CHECK_DELETE_SHEET_DATA_REQUESTING:
      return {
        ...state,
        checkDeleteSheetDataRequesting: true,
        checkDeleteSheetDataSuccess: false,
        checkDeleteSheetDataError: [],
      }
    case CHECK_DELETE_SHEET_DATA_SUCCESS:
      return {
        ...state,
        checkDeleteSheetDataRequesting: false,
        checkDeleteSheetDataSuccess: true,
        checkDeleteSheetDataWarnings: error,
        checkDeleteSheetDataError: [],
        employeesToBeDeleted: payload.employees,
        errorKey: payload.tempUrl
      }
    case CHECK_DELETE_SHEET_DATA_ERROR:
      return {
        ...state,
        checkDeleteSheetDataRequesting: false,
        checkDeleteSheetDataSuccess: false,
        checkDeleteSheetDataError: error,
      }
    case CHECK_ADD_SHEET_DATA_REQUESTING:
      return {
        ...state,
        checkAddSheetDataRequesting: true,
        checkAddSheetDataSuccess: false,
        checkAddSheetDataError: [],
      }
    case CHECK_ADD_SHEET_DATA_SUCCESS:
      return {
        ...state,
        errorKey: payload.tempUrl,
        checkAddSheetDataRequesting: false,
        checkAddSheetDataSuccess: true,
        checkAddSheetDataError: [],
        sheetData: payload.employees,
      }
    case CHECK_ADD_SHEET_DATA_ERROR:
      return {
        ...state,
        checkAddSheetDataRequesting: false,
        checkAddSheetDataSuccess: false,
        checkAddSheetDataError: error,
      }
    case CHECK_SHEET_DATA_REQUESTING:
      return {
        ...state,
        checkSheetDataRequesting: true,
        checkSheetDataSuccess: false,
        checkSheetDataError: [],
      }
    case CHECK_SHEET_DATA_SUCCESS:
      return {
        ...state,
        addEmployees: payload.addEmployees,
        errorKey: payload.tempUrl,
        checkoutTypes: payload.checkoutTypes,
        numberOfCompanies:  payload.numberOfCompanies,
        payErrorKey: payload.tempUrl,
        sheetData: payload.employees,
        checkSheetDataRequesting: false,
        checkSheetDataSuccess: true,
        checkSheetDataError: [],
      }
    case CHECK_SHEET_DATA_ERROR:
      return {
        ...state,
        checkSheetDataRequesting: false,
        checkSheetDataSuccess: false,
        checkSheetDataError: error,
      }
    case CLEAN_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING:
      return {
        ...state,
        cleanEmployeePointsConfigurationRequesting: true,
        cleanEmployeePointsConfigurationSuccess: false,
        cleanEmployeePointsConfigurationError: false,
      }
    case CLEAN_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS:
      return {
        ...state,
        cleanEmployeePointsConfigurationRequesting: false,
        cleanEmployeePointsConfigurationSuccess: true,
        cleanEmployeePointsConfigurationError: false,
      }
    case CLEAN_EMPLOYEE_POINTS_CONFIGURATION_ERROR:
      return {
        ...state,
        cleanEmployeePointsConfigurationRequesting: false,
        cleanEmployeePointsConfigurationSuccess: false,
        cleanEmployeePointsConfigurationError: error,
      }
    case DELETE_EMPLOYEES_SHEET_REQUESTING:
      return {
        ...state,
        deleteEmployeesRequesting: true,
        deleteEmployeesSuccess: false,
        deleteEmployeesError: false,
      }
    case DELETE_EMPLOYEES_SHEET_SUCCESS:
      return {
        ...state,
        deleteEmployeesRequesting: false,
        deleteEmployeesSuccess: true,
        deleteEmployeesError: false,
      }
    case DELETE_EMPLOYEES_SHEET_ERROR:
      return {
        ...state,
        deleteEmployeesRequesting: false,
        deleteEmployeesSuccess: false,
        deleteEmployeesError: error,
      }
    case DELETE_EMPLOYEE_REQUESTING:
      return {
        ...state,
        deleteEmployeeRequesting: true,
        deleteEmployeeSuccess: false,
        deleteEmployeeError: false,
      }
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        deleteEmployeeRequesting: false,
        deleteEmployeeSuccess: true,
        deleteEmployeeError: false,
      }
    case DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        deleteEmployeeRequesting: false,
        deleteEmployeeSuccess: false,
        deleteEmployeeError: error,
      }
    case DELETE_MULTIPLE_EMPLOYEES_REQUESTING:
      return {
        ...state,
        deleteMultipleEmployeesRequesting: true,
        deleteMultipleEmployeesSuccess: false,
        deleteMultipleEmployeesError: false,
      }
    case DELETE_MULTIPLE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        deleteMultipleEmployeesRequesting: false,
        deleteMultipleEmployeesSuccess: true,
        deleteMultipleEmployeesError: false,
      }
    case DELETE_MULTIPLE_EMPLOYEES_ERROR:
      return {
        ...state,
        deleteMultipleEmployeesRequesting: false,
        deleteMultipleEmployeesSuccess: false,
        deleteMultipleEmployeesError: error,
      }
    case GENERATE_BILLET_REQUESTING:
      return {
        ...state,
        generateBilletRequesting: true,
        generateBilletSuccess: false,
        generateBilletError: false,
      }
    case GENERATE_BILLET_SUCCESS:
      return {
        ...state,
        depositsData: { ...state.depositsData, ...payload },
        generateBilletRequesting: false,
        generateBilletSuccess: true,
        generateBilletError: false,
      }
    case GENERATE_BILLET_ERROR:
      return {
        ...state,
        generateBilletRequesting: false,
        generateBilletSuccess: false,
        generateBilletError: error,
      }

    case GET_CHECKOUT_DATA_REQUESTING:
      return {
        ...state,
        checkoutData: {},
        checkoutDataRequesting: true,
        checkoutDataSuccess: false,
        checkoutDataError: false,
        companyData: {
          ...state.companyData,
          employeesWithoutCards: false,
          cardsInStock: false,
        },
      }
    case GET_CHECKOUT_DATA_SUCCESS:
      const { selectedEmployees } = payload
      return {
        ...state,
        selectedEmployees,
        companyData: {
          ...state.companyData,
          cardsInStock: payload?.benefits?.cardsInStock,
          employeesIdsWoBenefits: payload?.benefits?.employeesIdsWoBenefits,
          employeesWithoutCards: payload?.benefits?.employeesWithoutCards,
          firstPayment: payload?.benefits?.firstPayment,
          swapCashAccountBalance: payload?.benefits?.swapCashAccountBalance,
          swapGetBalanceError: payload?.benefits?.swapGetBalanceError,
        },
        checkoutData: { ...payload, checkoutBenefits: payload?.benefits?.checkoutBenefitsArray || [], companiesDepositData: payload?.companiesDepositData },
        checkoutDataRequesting: false,
        checkoutDataSuccess: true,
        checkoutDataError: false,
      }
    case GET_CHECKOUT_DATA_ERROR:
      return {
        ...state,
        checkoutData: {},
        checkoutDataRequesting: false,
        checkoutDataSuccess: false,
        checkoutDataError: error,
      }
    case GET_COMPANY_REQUESTING:
      return {
        ...state,
        companyRequesting: true,
        companySuccess: false,
        companyError: false,
      }
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyData: { ...state.companyData, ...payload },
        companyRequesting: false,
        companySuccess: true,
        companyError: false,
      }
    case GET_COMPANY_ERROR:
      return {
        ...state,
        companyRequesting: false,
        companySuccess: false,
        companyError: error,
      }

    case GET_COMPANY_ADMIN_REQUESTING:
      return {
        ...state,
        companyAdminRequesting: true,
        companyAdminSuccess: false,
        companyAdminError: false,
      }
    case GET_COMPANY_ADMIN_SUCCESS:
      const [ companyAdmin ] = payload

      return {
        ...state,
        companyAdminData: { ...state.companyAdminData, ...companyAdmin },
        companyAdminRequesting: false,
        companyAdminSuccess: true,
        companyAdminError: false,
      }
    case GET_COMPANY_ADMIN_ERROR:
      return {
        ...state,
        companyAdminRequesting: false,
        companyAdminSuccess: false,
        companyAdminError: error,
      }

    case GET_SHOULD_RENDER_PAT_REQUESTING:
      return {
        ...state,
        shouldRenderPatRequesting: true,
        shouldRenderPatSuccess: false,
        shouldRenderPatError: false
      }

    case GET_SHOULD_RENDER_PAT_SUCCESS:
      const { companyAdminMustAcceptPAT } = payload

      return {
        ...state,
        patModalVisible: companyAdminMustAcceptPAT,
        shouldRenderPatRequesting: false,
        shouldRenderPatSuccess: true,
        shouldRenderPatError: false
      }

    case GET_SHOULD_RENDER_PAT_ERROR:
      return {
        ...state,
        shouldRenderPatRequesting: false,
        shouldRenderPatSuccess: false,
        shouldRenderPatError: true
      }

    case GET_COMPANY_GROUPS_REQUESTING:
      return {
        ...state,
        companyGroupsRequesting: true,
        companyGroupsSuccess: false,
        companyGroupsError: false,
      }
    case GET_COMPANY_GROUPS_SUCCESS:
      const {
        company: { groups },
      } = payload
      return {
        ...state,
        companyGroups: groups,
        employees: [],
        employeesInfo: { isSearch: false },
        companyGroupsRequesting: false,
        companyGroupsSuccess: true,
        companyGroupsError: false,
      }
    case GET_COMPANY_GROUPS_ERROR:
      return {
        ...state,
        companyGroupsRequesting: false,
        companyGroupsSuccess: false,
        companyGroupsError: error,
      }

    case GET_COMPANY_TAGS_REQUESTING:
      return {
        ...state,
        companyTagsRequesting: true,
        companyTagsSuccess: false,
        companyTagsError: false,
      }
    case GET_COMPANY_TAGS_SUCCESS:
      return {
        ...state,
        companyTags: payload,
        companyTagsRequesting: false,
        companyTagsSuccess: true,
        companyTagsError: false,
      }
    case GET_COMPANY_TAGS_ERROR:
      return {
        ...state,
        companyTagsRequesting: false,
        companyTagsSuccess: false,
        companyTagsError: error,
      }

    case GET_COMPANY_VIRTUAL_BALANCE_REQUESTING:
      return {
        ...state,
        virtualBalanceRequesting: true,
        virtualBalanceSuccess: false,
        virtualBalanceError: false,
      }
    case GET_COMPANY_VIRTUAL_BALANCE_SUCCESS:
      return {
        ...state,
        virtualBalance: payload,
        virtualBalanceRequesting: false,
        virtualBalanceSuccess: true,
        virtualBalanceError: false,
      }
    case GET_COMPANY_VIRTUAL_BALANCE_ERROR:
      return {
        ...state,
        virtualBalanceRequesting: false,
        virtualBalanceSuccess: false,
        virtualBalanceError: error,
      }

    case GET_COMPANY_SHEETS_SUCCESS : {
      return {
        ...state,
        companySheet: payload,
        loadingSheet: false
      }
    }


    case GET_COMPANY_SHEETS_REQUESTING: {

      return {
        ...state,
        loadingSheet: true,
      }
    }

    case GET_EMPLOYEES_REQUESTING:
      return {
        ...state,
        searchRequesting: payload.isSearch || false,
        employeesRequesting: true,
        employeesSuccess: false,
        employeesError: false,
      }
    case GET_EMPLOYEES_SUCCESS:
      const {
        data: {
          company: {
            groups: { 0: newEmployees },
          },
        },
      } = payload //, group, isSearch } = payload
      //const { totalCount, pageInfo, edges } = data
      //const newEmployees = edges.map(edge => omitBy(edge.node, isEmpty && !isNumber))
      return {
        ...state,
        searchRequesting: false,
        employees: newEmployees, //newEmployees,
        //employeesInfo: { totalCount, pageInfo, group, isSearch },
        employeesRequesting: false,
        employeesSuccess: true,
        employeesError: false,
      }

    case GET_EMPLOYEES_ERROR:
      return {
        ...state,
        employeesRequesting: false,
        employeesSuccess: false,
        employeesError: error,
      }

    case GET_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING:
      return {
        ...state,
        employeePointsConfigurationRequesting: true,
        employeePointsConfigurationSuccess: false,
        employeePointsConfigurationError: false,
      }
    case GET_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS:
      const {
        data: {
          employeePointsConfiguration
        }
      } = payload
      return {
        ...state,
        employeePointsConfiguration,
        employeePointsConfigurationRequesting: false,
        employeePointsConfigurationSuccess: true,
        employeePointsConfigurationError: false,
      }

    case GET_EMPLOYEE_POINTS_CONFIGURATION_ERROR:
      return {
        ...state,
        employeePointsConfigurationRequesting: false,
        employeePointsConfigurationSuccess: false,
        employeePointsConfigurationError: error,
      }

    case RESET_EMPLOYEE_POINTS_CONFIGURATION:
      return {
        ...state,
        employeePointsConfiguration: undefined,
      }

    
    case UPDATE_EMPLOYEE_POLICY_REQUESTING:
      return {
        ...state,
        updateEmployeePolicyRequesting: true,
        updateEmployeePolicySuccess: false,
        updateEmployeePolicyError: false,
      }
    case UPDATE_EMPLOYEE_POLICY_SUCCESS:
      return {
        ...state,
        updateEmployeePolicyRequesting: false,
        updateEmployeePolicySuccess: true,
        updateEmployeePolicyError: false,
      }
    case UPDATE_EMPLOYEE_POLICY_ERROR:
      return {
        ...state,
        updateEmployeePolicyRequesting: false,
        updateEmployeePolicySuccess: false,
        updateEmployeePolicyError: error,
      }

    case RESEND_WELCOME_NOTIFICATION_REQUESTING:
      return {
        ...state,
        resendWelcomeNotificationRequesting: true,
        resendWelcomeNotificationSuccess: false,
        resendWelcomeNotificationError: '',
      }

    case RESEND_WELCOME_NOTIFICATION_SUCCESS:
      return {
        ...state,
        resendWelcomeNotificationRequesting: false,
        resendWelcomeNotificationSuccess: true,
        resendWelcomeNotificationError: '',
      }

    case RESEND_WELCOME_NOTIFICATION_ERROR:
      return {
        ...state,
        resendWelcomeNotificationRequesting: false,
        resendWelcomeNotificationSuccess: false,
        resendWelcomeNotificationError: error,
      }

    case RESEND_POINTS_NOTIFICATION_REQUESTING:
      return {
        ...state,
        resendPointsNotificationRequesting: true,
        resendPointsNotificationSuccess: false,
        resendPointsNotificationError: '',
      }

    case RESEND_POINTS_NOTIFICATION_SUCCESS:
      return {
        ...state,
        resendPointsNotificationRequesting: false,
        resendPointsNotificationSuccess: true,
        resendPointsNotificationError: '',
      }

    case RESEND_POINTS_NOTIFICATION_ERROR:
      return {
        ...state,
        resendPointsNotificationRequesting: false,
        resendPointsNotificationSuccess: false,
        resendPointsNotificationError: error,
      }


    case SEND_EMPLOYEES_REPORT_REQUESTING:
      return {
        ...state,
        reportRequesting: true,
        reportSuccess: false,
        reportError: false,
      }

    case SEND_EMPLOYEES_REPORT_SUCCESS:
      return {
        ...state,
        reportRequesting: false,
        reportSuccess: true,
        report: payload,
        reportError: false,
      }

    case SEND_EMPLOYEES_REPORT_ERROR:
      return {
        ...state,
        reportRequesting: false,
        reportSuccess: false,
        reportError: error,
      }

    case SEND_SINGLE_EMPLOYEE_DOCUMENTS_REQUESTING:
      return {
        ...state,
        singleEmployeeDocumentsRequesting: true,
        singleEmployeeDocumentsSuccess: false,
        singleEmployeeDocumentsError: false,
      }

    case SEND_SINGLE_EMPLOYEE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        singleEmployeeDocumentsRequesting: false,
        singleEmployeeDocumentsSuccess: true,
        singleEmployeeDocumentsError: false,
      }

    case SEND_SINGLE_EMPLOYEE_DOCUMENTS_ERROR:
      return {
        ...state,
        singleEmployeeDocumentsRequesting: false,
        singleEmployeeDocumentsSuccess: false,
        singleEmployeeDocumentsError: error,
      }

    case SEND_EMPLOYEES_DOCUMENTS_REQUESTING:
      return {
        ...state,
        sendEmployeesDocumentsRequesting: true,
        sendEmployeesDocumentsSuccess: false,
        sendEmployeesDocumentsError: false,
      }

    case SEND_EMPLOYEES_DOCUMENTS_SUCCESS:
      return {
        ...state,
        sendEmployeesDocumentsResponse: payload,
        sendEmployeesDocumentsRequesting: false,
        sendEmployeesDocumentsSuccess: true,
        sendEmployeesDocumentsError: false,
      }

    case SEND_EMPLOYEES_DOCUMENTS_ERROR:
      return {
        ...state,
        sendEmployeesDocumentsRequesting: false,
        sendEmployeesDocumentsSuccess: false,
        sendEmployeesDocumentsError: error,
      }

    case UPDATE_ACCESS_DATE_REQUESTING:
      return {
        ...state,
        updateAccessDateRequesting: true,
        updateAccessDateSuccess: false,
        updateAccessDateError: false,
      }
    case UPDATE_ACCESS_DATE_SUCCESS:
      return {
        ...state,
        updateAccessDateRequesting: false,
        updateAccessDateSuccess: true,
        updateAccessDateError: false,
      }
    case UPDATE_ACCESS_DATE_ERROR:
      return {
        ...state,
        updateAccessDateRequesting: false,
        updateAccessDateSuccess: false,
        updateAccessDateError: error,
      }

    case UPDATE_PAT_TERMS_REQUESTING:
      return {
        ...state,
        updatePatTermsRequesting: true,
        updatePatTermsSuccess: false,
        updatePatTermsError: false
      }
    case UPDATE_PAT_TERMS_SUCCESS:
      return {
        ...state,
        updatePatTermsRequesting: false,
        updatePatTermsSuccess: true,
        updatePatTermsError: false
      }
    case UPDATE_PAT_TERMS_ERROR:
      return {
        ...state,
        updatePatTermsRequesting: false,
        updatePatTermsSuccess: false,
        updatePatTermsError: true
      }

    case UPDATE_COMPANY_REQUESTING:
      return {
        ...state,
        updateCompanyRequesting: true,
        updateCompanySuccess: false,
        updateCompanyError: false,
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompanyRequesting: false,
        updateCompanySuccess: true,
        updateCompanyError: false,
      }
    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        updateCompanyRequesting: false,
        updateCompanySuccess: false,
        updateCompanyError: error,
      }

    case UPDATE_COMPANY_ADMIN_REQUESTING:
      return {
        ...state,
        updateCompanyAdminRequesting: true,
        updateCompanyAdminSuccess: false,
        updateCompanyAdminError: false,
      }
    case UPDATE_COMPANY_ADMIN_SUCCESS:
      return {
        ...state,
        companyAdminData: { ...state.companyAdminData, ...payload },
        updateCompanyAdminRequesting: false,
        updateCompanyAdminSuccess: true,
        updateCompanyAdminError: false,
      }
    case UPDATE_COMPANY_ADMIN_ERROR:
      return {
        ...state,
        updateCompanyAdminRequesting: false,
        updateCompanyAdminSuccess: false,
        updateCompanyAdminError: error,
      }

    case UPDATE_COMPANY_TAGS_REQUESTING:
      return {
        ...state,
        updateCompanyTagsRequesting: true,
        updateCompanyTagsSuccess: false,
        updateCompanyTagsError: false,
      }
    case UPDATE_COMPANY_TAGS_SUCCESS:
      return {
        ...state,
        updateCompanyTagsRequesting: false,
        updateCompanyTagsSuccess: true,
        updateCompanyTagsError: false,
      }
    case UPDATE_COMPANY_TAGS_ERROR:
      return {
        ...state,
        updateCompanyTagsRequesting: false,
        updateCompanyTagsSuccess: false,
        updateCompanyTagsError: error,
      }

    case PAY_WITH_BALANCE_REQUESTING:
      return {
        ...state,
        payWithBalanceRequesting: true,
        payWithBalanceSuccess: false,
        payWithBalanceError: false,
      }
    case PAY_WITH_BALANCE_SUCCESS:
      return {
        ...state,
        payWithBalanceRequesting: false,
        payWithBalanceSuccess: true,
        payWithBalanceError: false,
      }
    case PAY_WITH_BALANCE_ERROR:
      return {
        ...state,
        payWithBalanceRequesting: false,
        payWithBalanceSuccess: false,
        payWithBalanceError: error,
      }
    case UPDATE_EMPLOYEE_REQUESTING:
      return {
        ...state,
        updateEmployeeRequesting: true,
        updateEmployeeSuccess: false,
        updateEmployeeError: false,
      }
    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        updateEmployeeRequesting: false,
        updateEmployeeSuccess: true,
        updateEmployeeError: false,
      }
    case UPDATE_EMPLOYEE_ERROR:
      return {
        ...state,
        updateEmployeeRequesting: false,
        updateEmployeeSuccess: false,
        updateEmployeeError: error,
      }

    case UPDATE_EMPLOYEE_TAGS_REQUESTING:
      return {
        ...state,
        updateEmployeeTagsRequesting: true,
        updateEmployeeTagsSuccess: false,
        updateEmployeeTagsError: false,
      }
    case UPDATE_EMPLOYEE_TAGS_SUCCESS:
      return {
        ...state,
        updateEmployeeTagsRequesting: false,
        updateEmployeeTagsSuccess: true,
        updateEmployeeTagsError: false,
        updateEmployeeTags: payload,
      }
    case UPDATE_EMPLOYEE_TAGS_ERROR:
      return {
        ...state,
        updateEmployeeTagsRequesting: false,
        updateEmployeeTagsSuccess: false,
        updateEmployeeTagsError: error,
      }

    case UPDATE_MULTIPLE_EMPLOYEES_REQUESTING:
      return {
        ...state,
        updateMultipleEmployeesRequesting: true,
        updateMultipleEmployeesSuccess: false,
        updateMultipleEmployeesError: false,
      }
    case UPDATE_MULTIPLE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        updateMultipleEmployeesRequesting: false,
        updateMultipleEmployeesSuccess: true,
        updateMultipleEmployeesError: false,
      }
    case UPDATE_MULTIPLE_EMPLOYEES_ERROR:
      return {
        ...state,
        updateMultipleEmployeesRequesting: false,
        updateMultipleEmployeesSuccess: false,
        updateMultipleEmployeesError: error,
      }
    case UPDATE_EMPLOYEE_BENEFIT_REQUESTING:
      return {
        ...state,
        updateEmployeeBenefitRequesting: true,
        updateEmployeeBenefitSuccess: false,
        updateEmployeeBenefitError: false,
      }
    case UPDATE_EMPLOYEE_BENEFIT_SUCCESS:
      return {
        ...state,
        employees: state?.employees?.employee && state?.employees?.employee.length && state.employees.employees.map(employee => {
          const { _id: employeeId, product: benefits } = employee
          const {
            employeeId: payloadEmployeeId,
            _id: payloadBenefitId,
          } = payload[0]
          if (employeeId === payloadEmployeeId) {
            employee.benefits = Object.keys(benefits).reduce(
              (acc, benefitType) => {
                let benefit = benefits[benefitType]
                if (benefit) {
                  const { _id: benefitId } = benefit
                  if (benefitId === payloadBenefitId || !benefitId) {
                    benefit = payload[0]
                  }
                }
                return {
                  ...acc,
                  [benefitType]: benefit,
                }
              },
              {},
            )
          }
          return employee
        }),
        updateEmployeeBenefitData: payload,
        updateEmployeeBenefitRequesting: false,
        updateEmployeeBenefitSuccess: true,
        updateEmployeeBenefitError: false,
      }
    case UPDATE_ALL_EMPLOYEES_BENEFITS_SUCCESS:
      return {
        ...state,
        updateEmployeeBenefitData: payload,
        updateEmployeeBenefitRequesting: false,
        updateEmployeeBenefitSuccess: true,
        updateEmployeeBenefitError: false,
      }
    case UPDATE_EMPLOYEE_BENEFIT_ERROR:
      return {
        ...state,
        updateEmployeeBenefitRequesting: false,
        updateEmployeeBenefitSuccess: false,
        updateEmployeeBenefitError: error,
      }
    case UPDATE_GROUP_NAME_REQUESTING:
      return {
        ...state,
        groupNameRequesting: true,
        groupNameSuccess: false,
        groupNameError: false,
      }
    case UPDATE_GROUP_NAME_SUCCESS:
      return {
        ...state,
        groupNameRequesting: false,
        groupNameSuccess: true,
        groupNameError: false,
      }
    case UPDATE_GROUP_NAME_ERROR:
      return {
        ...state,
        groupNameRequesting: false,
        groupNameSuccess: false,
        groupNameError: error,
      }
    case UPDATE_GROUP_NAME_RESET:
      return {
        ...state,
        groupNameRequesting: false,
        groupNameSuccess: false,
        groupNameError: false,
      }

    case UPSERT_DEDUCTIONS_REQUESTING:
      return {
        ...state,
        upsertDeductionsRequesting: true,
        upsertDeductionsSuccess: false,
        upsertDeductionsError: false,
      }
    case UPSERT_DEDUCTIONS_SUCCESS:
      const { employeeId, month, year } = payload
      let { employees } = state.employees
      const employeeIndex = findIndex(
        employees,
        ({ _id }) => employeeId === _id,
      )
      const employeeDeductions = employees[employeeIndex].deductions
      const employeeDeductionsIndex = findIndex(
        employeeDeductions,
        deduction => deduction.month === month && deduction.year == year,
      )
      if (employeeDeductionsIndex > 0) {
        employeeDeductions[employeeDeductionsIndex] = payload
      } else {
        employeeDeductions.push(payload)
      }

      return {
        ...state,
        upsertDeductionsRequesting: false,
        upsertDeductionsSuccess: true,
        upsertDeductionsError: false,
      }
    case UPSERT_DEDUCTIONS_ERROR:
      return {
        ...state,
        upsertDeductionsRequesting: false,
        upsertDeductionsSuccess: false,
        upsertDeductionsError: error,
      }

    default:
      return state
  }
}

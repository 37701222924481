import { momentBusiness } from '@flash-tecnologia/flamingo-ui/src/utils/businessConfig'
import moment from 'moment-timezone'

export * from '@flash-tecnologia/flamingo-ui/src/utils'
export * from './sheetConfig'

export function addWorkDay(date, workDays = 0) {
  const newDate = moment(date)

  for (let days = 0; days < workDays; days++) {
    newDate.add(1, 'days')
    if (!momentBusiness(newDate).isBusinessDay()) {
      days--
    }
  }
  return newDate
}

export function calculateTotalByBenefitType(depositAmountsConfig, type) {
  const numberOfBusinessDays = (momentBusiness().startOf('month').monthBusinessDays()).length
  return depositAmountsConfig?.[type].reduce((int, benefit) => {
    const conversion = benefit.timeUnit === 'day'
      ? numberOfBusinessDays
      : 1
    return benefit.active
      ? int += (benefit.parsedAmount * conversion)
      : int
  }, 0)
}

export function calculateTotalValueAllBenefits(depositAmountsConfig) {
  return {
    totalPlastic: calculateTotalByBenefitType(depositAmountsConfig, 'plastic'),
    totalExclusive: calculateTotalByBenefitType(depositAmountsConfig, 'plasticExclusiveBalance'),
    totalVirtual: calculateTotalByBenefitType(depositAmountsConfig, 'virtual'),
  }
}

export function returnExpirationDate(creditDate) {
  if (!creditDate) return ''
  const { firstPayment } = this.props.employeesReducer.companyData

  const selectedDate = moment(creditDate).tz('America/Sao_Paulo').startOf('day')
  const formattedDate = selectedDate.format('DDMMYYYY')
  if (formattedDate === '02012020') return moment('2019-12-27').tz('America/Sao_Paulo').startOf('day')
  if (formattedDate === '03012020') return moment('2019-12-30').tz('America/Sao_Paulo').startOf('day')
  if (
    formattedDate === '27022020' ||
    formattedDate === '28022020'
  ) return moment('2020-02-21').tz('America/Sao_Paulo').startOf('day')

  const workDays = firstPayment ? 5 : 2
  for (let days = 0; days < workDays; days++) {
    selectedDate.subtract(1, 'days')
    if (!momentBusiness(selectedDate).isBusinessDay()) {
      days--
    }
  }
  return selectedDate
}

export function returnMinDate(paymentMethod) {
  const today = moment().startOf('day')
  let minDate = moment(today)

  if (!momentBusiness(minDate).isBusinessDay()) {
    minDate = addWorkDay(minDate, 1)
  }

  if (paymentMethod === 'companyBalance') return minDate

  const { firstPayment } = this.props.employeesReducer.companyData
  const days = firstPayment ? 5 : 3

  return addWorkDay(minDate, days)
}

export function isValidPhone(phone) {
  return /^\(?0?[0-9]{2}\)?\s?[0-9]{4,5}-?[0-9]{4}$/.test(phone)
}
import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  GET_COMPANY_BENEFITS_REQUESTING,
  GET_COMPANY_BENEFITS_SUCCESS,
  GET_COMPANY_BENEFITS_ERROR,
  GET_COMPANY_DEPOSITS_ERROR,
  GET_COMPANY_DEPOSITS_REQUESTING,
  GET_COMPANY_DEPOSITS_SUCCESS,
  SEND_DEPOSITS_REPORT_ERROR,
  SEND_DEPOSITS_REPORT_REQUESTING,
  SEND_DEPOSITS_REPORT_SUCCESS,
  UPDATE_DEPOSIT_ERROR,
  UPDATE_DEPOSIT_REQUESTING,
  UPDATE_DEPOSIT_SUCCESS,
} from './constants'

function* getCompanyBenefitsFlow(action) {
  try {
    const response = yield call(getCompanyBenefits, action.payload)
    yield put({ type: GET_COMPANY_BENEFITS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_COMPANY_BENEFITS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCompanyBenefits() {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const GET_COMPANY_BENEFITS_QUERY = gql`
    query($companyId: ID!) {
      getCompanyBenefits(
        companyId: $companyId
      ) {
        plastic {
          _id
          depositCategories {
            key
            name
          }
          key
          name
        }
        plasticExclusiveBalance {
          _id
          key
          name
        }
        virtual {
          _id
          key
          name
        }
      }
    }
  `
  const query = print(GET_COMPANY_BENEFITS_QUERY)
  const variables = { companyId }

  const response = await request.graphql(url, { query, variables })
  return response.data.getCompanyBenefits
}

function* getCompanyDepositsFlow(action) {
  try {
    const response = yield call(getCompanyDeposits, action.payload)
    yield put({ type: GET_COMPANY_DEPOSITS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_COMPANY_DEPOSITS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCompanyDeposits({
  limit,
  skip,
  sortBy,
  filter,
}) {
  const url = `${urlMap.company}/company/graphql`
  const companyId = getFromLS('clientData').companyId
  const GET_DEPOSITS_QUERY = gql`
    query($companyId: ID! $limit: Int $skip: Int $sortBy: DepositsTableSortBy $filter: DepositsTableFilters ) {
      depositsTable(
        companyId: $companyId
        limit: $limit
        skip: $skip
        sortBy: $sortBy
        filter: $filter
      ) {
        employeeDeposits {
          _id
          amount
          exclusiveAmount
          employeeCreditDate
          name
          cpf
          flashProduct
          status
          description
        },
        totalDepositsCount
      }
    }
  `
  const query = print(GET_DEPOSITS_QUERY)
  const variables = {
    companyId,
    limit,
    skip,
    sortBy,
    filter,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.depositsTable
}

function* sendDepositsReportFlow(action) {
  try {
    const response = yield call(sendDepositsReportApi, action.payload)
    yield put({ type: SEND_DEPOSITS_REPORT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: SEND_DEPOSITS_REPORT_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function sendDepositsReportApi() {
  const { companyId, adminId } = getFromLS('clientData')
  const depositsType = 'deposits'
  const url = `${urlMap.company}/company/graphql`

  const SEND_DEPOSITS_REPORT_QUERY = gql`
    query($companyId: ID! $adminId: ID! $depositsType: String) {
      sendReport(
        companyId: $companyId,
        adminId: $adminId,
        type: $depositsType
      )
    }
  `

  const query = print(SEND_DEPOSITS_REPORT_QUERY)
  const variables = { companyId, adminId, depositsType }

  return await request.graphql(url, { query, variables })
}

function* updateDepositFlow({ payload }) {
  try {
    const response = yield call(updateDeposit, payload)
    yield put({ type: UPDATE_DEPOSIT_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_DEPOSIT_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateDeposit({ depositId }) {
  const companyId = getFromLS('clientData').companyId
  const UPDATE_DEPOSIT_QUERY = gql`
  mutation($depositId: ID! $companyId: ID!) {
      cancelDeposit(
        depositId: $depositId,
        companyId: $companyId
        )  {
          hasRefund
        }
    }
  `
  const query = print(UPDATE_DEPOSIT_QUERY)

  const url = `${urlMap.company}/company/graphql`
  const variables = { depositId, companyId }

  const response = await request.graphql(url, { query, variables })

  return response.data.cancelDeposit
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_COMPANY_BENEFITS_REQUESTING, getCompanyBenefitsFlow),
    takeLatest(GET_COMPANY_DEPOSITS_REQUESTING, getCompanyDepositsFlow),
    takeLatest(SEND_DEPOSITS_REPORT_REQUESTING, sendDepositsReportFlow),
    takeLatest(UPDATE_DEPOSIT_REQUESTING, updateDepositFlow),
  ])
}

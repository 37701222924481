
export const disabledButtonSend = (answeredList, othersBenefits) => {
  const hasValeTransporte = answeredList.some(benefitCategory => benefitCategory.value === 'vale_transporte')
  const hasQuantityCollaborators = answeredList.some(benefitCategory => benefitCategory.value === 'vale_transporte' && benefitCategory.quantityCollaborators)
  if (hasValeTransporte && !hasQuantityCollaborators) return true
  
  const hasInputOutros = answeredList.some(benefitCategory => benefitCategory.value === 'outros')
  if (answeredList.length) {
    if (hasInputOutros) {
      if (othersBenefits !== '') { 
        return false
      } else { 
        return true 
      }
    }
    return false
  }
  return true
}

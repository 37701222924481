import {
  RESEND_EMAIL_REQUESTING,
  RESEND_EMAIL_SUCCESSFUL,
  RESEND_EMAIL_ERROR,
} from './constants'

const initialState = {
  requesting: false,
  success: false,
  type: '',
  error: '',
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case RESEND_EMAIL_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        type: '',
      }
    case RESEND_EMAIL_SUCCESSFUL:
      return {
        ...state,
        requesting: false,
        success: true,
        type: payload.type,
      }
    case RESEND_EMAIL_ERROR:
      return {
        ...state,
        requesting: false,
        type: '',
      }
    default:
      return state
  }
}


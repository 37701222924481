import {
  ADD_COMPANY_CUSTOM_BENEFIT_ERROR,
  ADD_COMPANY_CUSTOM_BENEFIT_REQUESTING,
  ADD_COMPANY_CUSTOM_BENEFIT_SUCCESS,
  CREATE_COMPANY_BENEFITS_ERROR,
  CREATE_COMPANY_BENEFITS_REQUESTING,
  CREATE_COMPANY_BENEFITS_SUCCESS,
  DELETE_COMPANY_CUSTOM_BENEFIT_ERROR,
  DELETE_COMPANY_CUSTOM_BENEFIT_REQUESTING,
  DELETE_COMPANY_CUSTOM_BENEFIT_SUCCESS,
  UPDATE_COMPANY_BENEFITS_REQUESTING,
  UPDATE_COMPANY_BENEFITS_SUCCESS,
  UPDATE_COMPANY_BENEFITS_ERROR,
  UPDATE_COMPANY_CUSTOM_BENEFIT_ERROR,
  UPDATE_COMPANY_CUSTOM_BENEFIT_REQUESTING,
  UPDATE_COMPANY_CUSTOM_BENEFIT_SUCCESS,
} from './constants'

const initialState = {
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_COMPANY_CUSTOM_BENEFIT_REQUESTING:
      return {
        ...state,
        addCompanyCustomBenefitRequesting: true,
        addCompanyCustomBenefitSuccess: false,
        addCompanyCustomBenefitError: '',
      }
    case ADD_COMPANY_CUSTOM_BENEFIT_SUCCESS:
      return {
        ...state,
        addCompanyCustomBenefitRequesting: false,
        addCompanyCustomBenefitSuccess: true,
        addCompanyCustomBenefitError: '',
      }
    case ADD_COMPANY_CUSTOM_BENEFIT_ERROR:
      return {
        ...state,
        addCompanyCustomBenefitRequesting: false,
        addCompanyCustomBenefitSuccess: false,
        addCompanyCustomBenefitError: payload.error,
      }
    case CREATE_COMPANY_BENEFITS_REQUESTING:
      return {
        ...state,
        createCompanyBenefitRequesting: true,
        createCompanyBenefitSuccess: false,
        createCompanyBenefitError: '',
      }
    case CREATE_COMPANY_BENEFITS_SUCCESS:
      return {
        ...state,
        createCompanyBenefitRequesting: false,
        createCompanyBenefitSuccess: true,
        createCompanyBenefitError: '',
      }
    case CREATE_COMPANY_BENEFITS_ERROR:
      return {
        ...state,
        createCompanyBenefitRequesting: false,
        createCompanyBenefitSuccess: false,
        createCompanyBenefitError: payload.error,
      }
    case DELETE_COMPANY_CUSTOM_BENEFIT_REQUESTING:
      return {
        ...state,
        deleteCompanyCustomBenefitRequesting: true,
        deleteCompanyCustomBenefitSuccess: false,
        deleteCompanyCustomBenefitError: '',
      }
    case DELETE_COMPANY_CUSTOM_BENEFIT_SUCCESS:
      return {
        ...state,
        deleteCompanyCustomBenefitRequesting: false,
        deleteCompanyCustomBenefitSuccess: true,
        deleteCompanyCustomBenefitError: '',
      }
    case DELETE_COMPANY_CUSTOM_BENEFIT_ERROR:
      return {
        ...state,
        deleteCompanyCustomBenefitRequesting: false,
        deleteCompanyCustomBenefitSuccess: false,
        deleteCompanyCustomBenefitError: payload.error,
      }
    case UPDATE_COMPANY_BENEFITS_REQUESTING:
      return {
        ...state,
        updateCompanyBenefitsRequesting: true,
        updateCompanyBenefitsSuccess: false,
        updateCompanyBenefitsError: '',
      }
    case UPDATE_COMPANY_BENEFITS_SUCCESS:
      return {
        ...state,
        updateCompanyBenefitsRequesting: false,
        updateCompanyBenefitsSuccess: true,
        updateCompanyBenefitsError: '',
      }
    case UPDATE_COMPANY_BENEFITS_ERROR:
      return {
        ...state,
        updateCompanyBenefitsRequesting: false,
        updateCompanyBenefitsSuccess: false,
        updateCompanyBenefitsError: payload.error,
      }
    case UPDATE_COMPANY_CUSTOM_BENEFIT_REQUESTING:
      return {
        ...state,
        updateCompanyCustomBenefitRequesting: true,
        updateCompanyCustomBenefitSuccess: false,
        updateCompanyCustomBenefitError: '',
      }
    case UPDATE_COMPANY_CUSTOM_BENEFIT_SUCCESS:
      return {
        ...state,
        updateCompanyCustomBenefitRequesting: false,
        updateCompanyCustomBenefitSuccess: true,
        updateCompanyCustomBenefitError: '',
      }
    case UPDATE_COMPANY_CUSTOM_BENEFIT_ERROR:
      return {
        ...state,
        updateCompanyCustomBenefitRequesting: false,
        updateCompanyCustomBenefitSuccess: false,
        updateCompanyCustomBenefitError: payload.error,
      }
    default:
      return state
  }
}

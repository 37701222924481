import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  VALIDATE_TOKEN_REQUESTING,
  VALIDATE_TOKEN_SUCCESSFUL,
  VALIDATE_TOKEN_ERROR,
  SEND_FORGOT_EMAIL_REQUESTING,
  SEND_FORGOT_EMAIL_SUCCESSFUL,
  SEND_FORGOT_EMAIL_ERROR,
  UPDATE_PSSWRD_REQUESTING,
  UPDATE_PSSWRD_SUCCESSFUL,
  UPDATE_PSSWRD_ERROR,
} from './constants'
import { urlMap, request, handleApiError } from '../../services/api'


function* sendForgotEmailFlow({ payload }) {
  try {
    yield call(sendForgotEmailApi, payload)
    yield put({ type: SEND_FORGOT_EMAIL_SUCCESSFUL })
  } catch (error) {
    yield put({ type: SEND_FORGOT_EMAIL_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function sendForgotEmailApi({ emailAssociated, token, type, recaptchaResponse }) {
  const resolver = 'forgotPsswrdSendEmail'
  const accountType = type === 'user'
    ? 'person'
    : type
  const body = {
    query: `{ 
      ${resolver}
      ${emailAssociated ? 
    (accountType === 'person' 
      ? `(email: "${emailAssociated}")` 
      : `(emailAssociated: "${emailAssociated}" recaptchaResponse: "${recaptchaResponse}")`) 
    : `(token: "${token}")`}
    }`
  }
  await request.graphql(
    `${urlMap[accountType]}/${accountType}/public`,
    body,
    { headers: { 'Content-Type': 'application/json' }}
  )
}

function* validateForgotTokenFlow({ payload }) {
  try {
    const data = yield call(validateForgotTokenApi, payload)
    yield put({ type: VALIDATE_TOKEN_SUCCESSFUL, payload: data })
  } catch (error) {
    yield put({ type: VALIDATE_TOKEN_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function validateForgotTokenApi({ type, token }) {
  const resolver = 'forgotPsswrdValidateToken'
  const response = await request.graphql(
    `${urlMap[type]}/${type}/public`,
    { query: `{${resolver}(token: "${token}") {message _id ${type.toLowerCase() === 'person' ? 'email' : 'emailAssociated'}}}` },
    { headers: { 'Content-Type': 'application/json' }}
  )
  return response.data[resolver]
}

function* updatePsswrdFlow({ payload }) {
  try {
    const { data } = yield call(updatePsswrdFlowApi, payload)
    yield put({ type: UPDATE_PSSWRD_SUCCESSFUL, payload: data })
  } catch (error) {
    yield put({ type: UPDATE_PSSWRD_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updatePsswrdFlowApi({ type, id, psswrd, token }) {
  const resolver = 'forgotPsswrdUpdate'
  const input = {
    ...(type === 'company' ? { adminId: id } : { personId: id }),
    newPsswrd: psswrd,
    token,
  }
  const url = `${urlMap[type]}/${type}/public`
  const FORGOT_PSSWRD_QUERY = gql`
    mutation($input: ForgotPsswrdUpdate!){
      forgotPsswrdUpdate(input: $input)
    }
  `
  const query = print(FORGOT_PSSWRD_QUERY)

  const variables = { input }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }

  const response = await request.graphql(url, { query, variables }, options)
  if (!response.errors) return true
  return response.data[resolver]
}


export default function* rootSaga() {
  yield all([
    takeLatest(SEND_FORGOT_EMAIL_REQUESTING, sendForgotEmailFlow),
    takeLatest(VALIDATE_TOKEN_REQUESTING, validateForgotTokenFlow),
    takeLatest(UPDATE_PSSWRD_REQUESTING, updatePsswrdFlow),
  ])
}
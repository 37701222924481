import _isNil from 'lodash/isNil'
import {
  GET_COMPANY_INFO_ERROR,
  GET_COMPANY_INFO_REQUESTING,
  GET_COMPANY_INFO_SUCCESS,
  GET_CONTRACTS_ERROR,
  GET_CONTRACTS_REQUESTING,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACT_LINK_REQUESTING,
  GET_CONTRACT_LINK_SUCCESS,
  GET_CONTRACT_LINK_ERROR,
  UPDATE_COMPANY_INFO_ERROR,
  UPDATE_COMPANY_INFO_REQUESTING,
  UPDATE_COMPANY_INFO_SUCCESS,
} from './constants'

const initialState = {
  contractLink: '',
  contractLinkError: '',
  contractLinkRequesting: false,
  contractLinkSuccessful: false,
  contracts: [],
  data: {},
  error: '',
  getCompanyInfoRequesting: false,
  getContractsRequesting: false,
  successful: false,
  updateCompanyInfoRequesting: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_COMPANY_INFO_REQUESTING:
      return {
        ...state,
        getCompanyInfoRequesting: true,
        successful: false,
      }
    case GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        getCompanyInfoRequesting: false,
        successful: true,
        data: {
          address: payload.address.address,
          addressNumber: !_isNil(payload.address.addressNumber) ? String(payload.address.addressNumber) : '',
          complement: payload.address.complement ? payload.address.complement.toLowerCase() : '',
          neighborhood: payload.address.neighborhood,
          zipCode: payload.address.zipCode,
          city: payload.address.city,
          state: payload.address.state,
          cnpj: payload.cnpj,
          companyName: payload.companyName,
          financialEmails: payload.financialEmails || [],
          tradeName: payload.tradeName || '',
        },
        error: '',
      }
    case GET_COMPANY_INFO_ERROR:
      return {
        ...state,
        getCompanyInfoRequesting: false,
        successful: false,
        error: payload.error,
      }

    case GET_CONTRACTS_REQUESTING:
      return {
        ...state,
        getContractsRequesting: true,
        successful: false,
      }
    case GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: payload,
        getContractsRequesting: false,
        successful: true,
        error: '',
      }
    case GET_CONTRACTS_ERROR:
      return {
        ...state,
        getContractsRequesting: false,
        successful: false,
        error: payload.error,
      }

    case GET_CONTRACT_LINK_REQUESTING:
      return {
        ...state,
        contractLink: '',
        contractLinkError: '',
        contractLinkRequesting: true,
        contractLinkSuccessful: false,
      }
    case GET_CONTRACT_LINK_SUCCESS:
      return {
        ...state,
        contractLink: payload.tempURL,
        contractLinkError: '',
        contractLinkRequesting: false,
        contractLinkSuccessful: true,
      }
    case GET_CONTRACT_LINK_ERROR:
      return {
        ...state,
        contractLink: '',
        contractLinkError: payload.error,
        contractLinkRequesting: false,
        contractLinkSuccessful: false,
      }

    case UPDATE_COMPANY_INFO_REQUESTING:
      return {
        ...state,
        updateCompanyInfoRequesting: true,
        successful: false,
      }
    case UPDATE_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        updateCompanyInfoRequesting: false,
        successful: true,
        data: {
          address: payload.address.address,
          addressNumber: !_isNil(payload.address.addressNumber) ? String(payload.address.addressNumber) : '',
          complement: payload.address.complement ? payload.address.complement.toLowerCase() : '',
          neighborhood: payload.address.neighborhood,
          zipCode: payload.address.zipCode,
          city: payload.address.city,
          state: payload.address.state,
          cnpj: payload.cnpj,
          companyName: payload.companyName,
          financialEmails: payload.financialEmails || [],
          tradeName: payload.tradeName,
        },
        error: '',
      }
    case UPDATE_COMPANY_INFO_ERROR:
      return {
        ...state,
        updateCompanyInfoRequesting: false,
        successful: false,
        error: payload.error
      }

    default:
      return state
  }
}

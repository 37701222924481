import {
  CANCEL_ORDER_REQUESTING,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  CONFIRM_DELIVERED_ORDER_REQUESTING,
  CONFIRM_DELIVERED_ORDER_SUCCESS,
  CONFIRM_DELIVERED_ORDER_ERROR,
  CREATE_ORDER_REQUESTING,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  GET_CARDS_REQUESTING,
  GET_CARDS_SUCCESS,
  GET_CARDS_ERROR,
  GET_CARDS_BIG_NUMBERS_REQUESTING,
  GET_CARDS_BIG_NUMBERS_SUCCESS,
  GET_CARDS_BIG_NUMBERS_ERROR,
  GET_ORDER_DETAILS_REQUESTING,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_ERROR,
  GET_ORDER_DELIVERIES_REQUESTING,
  GET_ORDER_DELIVERIES_SUCCESS,
  GET_ORDER_DELIVERIES_ERROR,
  GET_NEW_ORDERS_REQUESTING,
  GET_NEW_ORDERS_SUCCESS,
  GET_NEW_ORDERS_ERROR,
  NOTIFY_ORDER_NOT_RECEIVED_REQUESTING,
  NOTIFY_ORDER_NOT_RECEIVED_SUCCESS,
  NOTIFY_ORDER_NOT_RECEIVED_ERROR,
  POPULATE_ORDER_REQUESTING,
  POPULATE_ORDER_SUCCESS,
  POPULATE_ORDER_ERROR,
  GET_CARD_ORDER_CREATION_FORM_DATA_REQUESTING,
  GET_CARD_ORDER_CREATION_FORM_DATA_SUCCESS,
  GET_CARD_ORDER_CREATION_FORM_DATA_ERROR,
  SEND_REPORT_SUB_ORDERS_REQUESTING,
  SEND_REPORT_SUB_ORDERS_SUCCESS,
  SEND_REPORT_SUB_ORDERS_ERROR,
  SELECT_GROUP_CARD_MODEL_REQUESTING,
  SELECT_GROUP_CARD_MODEL_SUCCESS,
  SELECT_GROUP_CARD_MODEL_ERROR,
} from './constants'

const initState = {
  cards: {},
  cardsBigNumbers: {},
  error: '',
  orderDetails: {},
  populateData: {},
  requestingOrderDetails: false,
  requestingOrders: false,
  requestingPopulateFields: false,
  requestingCards: false,
  success: false,
  successPopulateFields: false,
}

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case CANCEL_ORDER_REQUESTING:
      return {
        ...state,
        updatingOrder: true,
        success: false,
        error: '',
      }
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        updatingOrder: false,
        success: true,
        error: '',
      }
    case CANCEL_ORDER_ERROR:
      return {
        ...state,
        updatingOrder: false,
        success: false,
        error: payload.error
      }

    case CONFIRM_DELIVERED_ORDER_REQUESTING:
      return {
        ...state,
        updatingOrder: true,
        success: false,
        error: '',
      }
    case CONFIRM_DELIVERED_ORDER_SUCCESS:
      return {
        ...state,
        updatingOrder: false,
        success: true,
        error: '',
      }
    case CONFIRM_DELIVERED_ORDER_ERROR:
      return {
        ...state,
        updatingOrder: false,
        success: false,
        error: payload.error
      }

    case CREATE_ORDER_REQUESTING:
      return {
        ...state,
        createOrderRequesting: true,
        createOrderSuccess: false,
        error: '',
      }
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        createOrderRequesting: false,
        createOrderSuccess: true,
        error: '',
      }
    case CREATE_ORDER_ERROR:
      return {
        ...state,
        createOrderRequesting: false,
        createOrderSuccess: false,
        requesting: false,
        error: payload.error,
      }

    case GET_CARDS_REQUESTING:
      return {
        ...state,
        requestingCards: true,
        createOrderSuccess: false,
        success: false,
        error: '',
      }
    case GET_CARDS_SUCCESS:
      return {
        ...state,
        requestingCards: false,
        success: true,
        error: '',
        cards: { ...payload },
      }
    case GET_CARDS_ERROR:
      return {
        ...state,
        requestingCards: false,
        success: false,
        error: payload.error,
      }

    case GET_CARDS_BIG_NUMBERS_REQUESTING:
      return {
        ...state,
        requestingCardsBigNumbers: true,
        createOrderSuccess: false,
        success: false,
        error: '',
      }
    case GET_CARDS_BIG_NUMBERS_SUCCESS:
      return {
        ...state,
        requestingCardsBigNumbers: false,
        success: true,
        error: '',
        cardsBigNumbers: { ...payload },
      }
    case GET_CARDS_BIG_NUMBERS_ERROR:
      return {
        ...state,
        requestingCardsBigNumbers: false,
        success: false,
        error: payload.error,
      }

    case GET_ORDER_DETAILS_REQUESTING:
      return {
        ...state,
        requestingOrderDetails: true,
        success: false,
        error: '',
      }
    case GET_ORDER_DETAILS_SUCCESS:
      const [ orderDetails ] = payload.orders

      return {
        ...state,
        requestingOrderDetails: false,
        success: true,
        error: '',
        orderDetails,
      }
    case GET_ORDER_DETAILS_ERROR:
      return {
        ...state,
        requestingOrderDetails: false,
        success: false,
        error: payload.error,
      }

    case GET_ORDER_DELIVERIES_REQUESTING:
      return {
        ...state,
        requestingOrderDeliveries: true,
        requestingOrderDeliveriesSuccess: false,
        error: '',
      }
    case GET_ORDER_DELIVERIES_SUCCESS:
      return {
        ...state,
        requestingOrderDeliveries: false,
        requestingOrderDeliveriesSuccess: true,
        error: '',
        orderDeliveries:  payload,
      }
    case GET_ORDER_DELIVERIES_ERROR:
      return {
        ...state,
        requestingOrderDeliveries: false,
        requestingOrderDeliveriesSuccess: false,
        error: payload.error,
      }

    case GET_NEW_ORDERS_REQUESTING:
      return {
        ...state,
        requestingNewOrders: true,
        requestingNewOrdersSuccess: false,
        error: '',
      }
    case GET_NEW_ORDERS_SUCCESS:
      return {
        ...state,
        requestingNewOrders: false,
        requestingNewOrdersSuccess: true,
        error: '',
        newOrders: { ...payload }
      }
    case GET_NEW_ORDERS_ERROR:
      return {
        ...state,
        requestingNewOrders: false,
        requestingNewOrdersSuccess: false,
        error: payload.error,
      }

    case NOTIFY_ORDER_NOT_RECEIVED_REQUESTING:
      return {
        ...state,
        updatingOrder: true,
        success: false,
        error: '',
      }
    case NOTIFY_ORDER_NOT_RECEIVED_SUCCESS:
      return {
        ...state,
        updatingOrder: false,
        success: true,
        error: '',
      }
    case NOTIFY_ORDER_NOT_RECEIVED_ERROR:
      return {
        ...state,
        updatingOrder: false,
        success: false,
        error: payload.error
      }

    case POPULATE_ORDER_REQUESTING:
      return {
        ...state,
        requestingPopulateFields: true,
        successPopulateFields: false,
        populateData: {},
        error: '',
      }
    case POPULATE_ORDER_SUCCESS:
      return {
        ...state,
        requestingPopulateFields: false,
        successPopulateFields: true,
        error: '',
        populateData: { ...payload }
      }
    case POPULATE_ORDER_ERROR:
      return {
        ...state,
        requestingPopulateFields: false,
        successPopulateFields: false,
        error: payload.error,
      }

    case GET_CARD_ORDER_CREATION_FORM_DATA_REQUESTING:
      return {
        ...state,
        requestingCardOrderCreationFormData: true,
        successCardOrderCreationFormData: false,
        cardOrderCreationFormData: {},
        error: '',
      }
    case GET_CARD_ORDER_CREATION_FORM_DATA_SUCCESS:
      return {
        ...state,
        requestingCardOrderCreationFormData: false,
        successCardOrderCreationFormData: true,
        cardOrderCreationFormData: { ...payload },
        error: '',
      }
    case GET_CARD_ORDER_CREATION_FORM_DATA_ERROR:
      return {
        ...state,
        requestingCardOrderCreationFormData: false,
        successCardOrderCreationFormData: false,
        error: payload.error,
      }

    case SEND_REPORT_SUB_ORDERS_REQUESTING:
      return {
        ...state,
        requestingSendReportSubOrders: true,
        successSendReportSubOrders: false,
        error: '',
      }
    case SEND_REPORT_SUB_ORDERS_SUCCESS:
      return {
        ...state,
        requestingSendReportSubOrders: false,
        successSendReportSubOrders: true,
        error: '',
      }
    case SEND_REPORT_SUB_ORDERS_ERROR:
      return {
        ...state,
        requestingSendReportSubOrders: false,
        successSendReportSubOrders: false,
        error: payload.error,
      }

    case SELECT_GROUP_CARD_MODEL_REQUESTING:
      return {
        ...state,
        requestingSelectGroupCardModel: true,
        successSelectGroupCardModel: false,
        error: '',
      }

    case SELECT_GROUP_CARD_MODEL_SUCCESS:
      return {
        ...state,
        requestingSelectGroupCardModel: false,
        successSelectGroupCardModel: true,
        groupCardModel: payload,
        error: '',
      }

    case SELECT_GROUP_CARD_MODEL_ERROR:
      return {
        ...state,
        requestingSelectGroupCardModel: false,
        successSelectGroupCardModel: false,
        error: payload.error,
      }

    default:
      return state
  }
}

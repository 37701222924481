import React, { useState, useEffect } from 'react'
import {
  Button,
  Subtitle,
  Modal,
  Checkbox,
  InputV2,
  InputWithBorder,
  Tooltip,
  P2,
} from '@flash-tecnologia/flamingo-ui'
import { onlyNumbers, toCurrencyString } from '@flash-tecnologia/flamingo-ui/src/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateBenefitDiscovery,
  addBenefitCategory,
  setDisplayModal,
  addListQuantityCollaborators,
} from '../actions'
import {
  getListBenefitDiscovery,
  getAnsweredList,
  getIsAnswered,
  getDisplayModal,
} from '../benefitDiscovery-selectors'
import { UlStyled, UlBoxStyled, SuccessReturnStyled } from './styles'
import { segmentTracking } from '../../../services/utils/segmentTrack'
import { disabledButtonSend } from '../utils/disabledButtonSend'
import { listQuantityCollaborators } from '../utils/listCollaboratorsQuatity'

const ModalBenefitsDiscovery = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [activeOthersBenefits, setActiveOthersBenefits] = useState<boolean>(false)
  const [othersBenefits, setOthersBenefits] = useState<string>('')
  const [quantityCollaborators, setQuantityCollaborators] = useState(0)

  const benefitDiscoveryList = useSelector((state) => getListBenefitDiscovery(state))
  const answeredList = useSelector((state) => getAnsweredList(state))
  const isAnswered = useSelector((state) => getIsAnswered(state))
  const displayModal = useSelector((state) => getDisplayModal(state))

  useEffect(() => {
    displayModal && segmentTracking('Portal_BenefitsDiscovery_SurveyModal_View')    
  },[displayModal])
  isAnswered && segmentTracking('Portal_BenefitsDiscovery_SurveySuccess_View')
  
  const handleCloseModal = () => {
    dispatch(setDisplayModal(false))
    segmentTracking('Portal_BenefitsDiscovery_SurveyModal_Close')
  }
  const handleCheckboxOnChange = (valueCheckbox) => dispatch(addBenefitCategory(valueCheckbox))
  const handleListQuantityCollaborators = (benefitDiscovery, valueCheckbox) => {
    const payload = { benefitDiscovery, valueCheckbox }
    dispatch(addListQuantityCollaborators(payload))
  }
  const handleOthersBenefits = (valueCheckbox) => {
    setActiveOthersBenefits(!activeOthersBenefits)
    dispatch(addBenefitCategory(valueCheckbox))
  }
  const handleOnSubmit = () => {
    const payload = { othersBenefits, quantityCollaborators }
    setLoading(true)    
    dispatch(updateBenefitDiscovery(payload))
  }
  const returnModalButtons = () => (
    <div>
      <Button
        key="next"
        disabled={disabledButtonSend(answeredList, othersBenefits)}
        onClick={handleOnSubmit}
        type="primary"
        loading={loading}
      >
        Enviar
      </Button>
    </div>
  )

  const SuccessMessage = () => (
    <SuccessReturnStyled>
      <Subtitle>
        Obrigada por ajudar a Flash!
        <br />
        Em breve teremos novidades
      </Subtitle>
      <span>🤩</span>
    </SuccessReturnStyled>
  )

  return (
    <Modal
      footer={!isAnswered && returnModalButtons()}
      visible={displayModal}
      width={'664px'}
      onCancel={handleCloseModal}
    >
      {isAnswered ? (
        <SuccessMessage />
      ) : (
        <>
          <Subtitle style={{ padding: '0 50px', fontWeight: 400 }}>
            Sua empresa também oferece benefícios <strong>fora da Flash?</strong> Queremos saber quais, leva só um minutinho!
          </Subtitle>
          <UlStyled>
            {benefitDiscoveryList.map((benefitDiscovery) => {
              return (
                benefitDiscovery.value !== 'outros' ? (
                  <li 
                    key={benefitDiscovery.value}
                    className={benefitDiscovery.displayQuantityCollaborators && answeredList.find((answered) => answered.value === benefitDiscovery.value) && 'active'}
                  >
                    <Checkbox
                      checked={answeredList.find((answered) => answered.benefitListId === benefitDiscovery._id)}
                      onChange={() => handleCheckboxOnChange(benefitDiscovery)}
                      label={benefitDiscovery.description ? `${benefitDiscovery.label} (${benefitDiscovery.description})` : benefitDiscovery.label}
                      style={{ fontSize: 14, fontWeight: 'bold' }}
                    />
                    
                    {benefitDiscovery.displayQuantityCollaborators && (
                      <div className='displayOptions'>
                        <P2 style={{ fontWeight: 700, color: '#15191C', padding: '11px 0 16px' }}>Aproximadamente, quantos dos seus colaboradores recebem esse benefício?</P2>
                        <UlBoxStyled style={{ margin: 0, padding: 0 }}>
                          {listQuantityCollaborators.map((collab, index) => (
                            <li key={collab.value}>
                              <Checkbox
                                checked={answeredList.some((i) => i.quantityCollaborators === collab.value)}
                                label={collab.label}
                                onChange={() => handleListQuantityCollaborators(benefitDiscovery, collab.value)}
                              />
                              {listQuantityCollaborators.length - 1 === index && (
                                <InputWithBorder
                                  onChange={(value) => setQuantityCollaborators(Number(onlyNumbers(value)))}
                                  disabled={!answeredList.some((i) => i.quantityCollaborators === collab.value)}                                
                                  value={toCurrencyString(quantityCollaborators / 100, true)}
                                  suffix="%"
                                />
                              )}
                            </li>
                          ))}
                        </UlBoxStyled>
                      </div>
                    )}
                  </li>
                ) : (
                  <li key={benefitDiscovery.value}>
                    <Checkbox
                      checked={activeOthersBenefits}
                      onChange={() => handleOthersBenefits(benefitDiscovery)}
                      label={benefitDiscovery.label}
                      style={{ fontSize: 14, fontWeight: 700 }}
                    />
                    <Tooltip
                      placement="top"
                      title='Os benefícios devem ser separados por ponto e vírgula ";"'
                    >
                      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                      </svg>
                    </Tooltip>

                    <InputV2 
                      disabled={!activeOthersBenefits} 
                      name="outros" 
                      onChange={(e) => setOthersBenefits(e)}
                      errorMessage={(activeOthersBenefits && othersBenefits === '') && 'Favor preencher o campo'}
                    />
                  </li>
                )
              )
            })}
          </UlStyled>
        </>
      )}
    </Modal>
  )
}
export default ModalBenefitsDiscovery

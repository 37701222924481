import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../services/api'
import { getFromLS } from '../../services/security'
import {
  GET_COMPANY_INFO_ERROR,
  GET_COMPANY_INFO_REQUESTING,
  GET_COMPANY_INFO_SUCCESS,
  GET_CONTRACTS_ERROR,
  GET_CONTRACTS_REQUESTING,
  GET_CONTRACTS_SUCCESS,
  GET_CONTRACT_LINK_ERROR,
  GET_CONTRACT_LINK_REQUESTING,
  GET_CONTRACT_LINK_SUCCESS,
  UPDATE_COMPANY_INFO_ERROR,
  UPDATE_COMPANY_INFO_REQUESTING,
  UPDATE_COMPANY_INFO_SUCCESS,
} from './constants'

function* getCompanyBasicInfoFlow() {
  try {
    const data = yield call(getCompanyBasicInfoApi)
    yield put({ type: GET_COMPANY_INFO_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_COMPANY_INFO_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCompanyBasicInfoApi() {
  const { companyId } = getFromLS('clientData')
  const GET_COMPANY_QUERY = gql`
    query($companyId: ID!) {
      company(_id: $companyId) {
        address {
          address
          addressNumber
          complement
          neighborhood
          zipCode
          city
          state
        }
        cnpj
        companyName
        financialEmails
        tradeName
      }
    }
  `
  const query = print(GET_COMPANY_QUERY)
  const variables = { companyId }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.company
}

function* getContractTempLinkFlow({ payload }) {
  try {
    const response = yield call(getContractTempLinkApi, payload)
    yield put({ type: GET_CONTRACT_LINK_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_CONTRACT_LINK_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getContractTempLinkApi({ contractId }) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const seconds = 60 * 60 // link valid for 1 hour

  const QUERY_STRING = gql`
    query($companyId: ID! $contractId: ID! $seconds: Int!) {
      getContract(
        companyId: $companyId
        contractId: $contractId
      ) {
        tempURL(seconds: $seconds)
      }
    }
  `

  const query = print(QUERY_STRING)
  const variables = {
    companyId,
    contractId,
    seconds,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.getContract
}

function* getContractsFlow() {
  try {
    const response = yield call(getContractsApi)
    yield put({ type: GET_CONTRACTS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_CONTRACTS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getContractsApi() {
  const { companyId } = getFromLS('clientData')
  const GET_CONTRACTS_QUERY = gql`
    query($companyId: ID!) {
      getContracts(companyId: $companyId) {
        _id
        signatories {
          hasSigned
          name
          signedAt
        }
        status
        type
        validUntil
      }
    }
  `
  const query = print(GET_CONTRACTS_QUERY)
  const variables = { companyId }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.getContracts
}

function* updateCompanyBasicInfoFlow({ payload }) {
  try {
    const data = yield call(updateCompanyBasicInfoApi, payload)
    yield put({ type: UPDATE_COMPANY_INFO_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_INFO_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateCompanyBasicInfoApi(payload) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const UPDATE_COMPANY_QUERY = gql`
    mutation($companyId: ID! $input: UpdateBasicCompanyInfoInput!) {
      updateCompanyBasicInfo(
        companyId: $companyId
        input: $input
      ) {
        address {
          address
          addressNumber
          city
          complement
          neighborhood
          state
          zipCode
        }
        companyName
        cnpj
        financialEmails
        tradeName
      }
    }
  `
  const query = print(UPDATE_COMPANY_QUERY)
  const variables = {
    companyId,
    input: {
      address: {
        address: payload.address,
        addressNumber: payload.addressNumber,
        city: payload.city,
        complement: payload.complement,
        neighborhood: payload.neighborhood,
        state: payload.state,
        zipCode: payload.zipCode,
      },
      companyName: payload.companyName,
      cnpj: payload.cnpj,
      financialEmails: payload.financialEmails,
      tradeName: payload.tradeName,
    },
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.updateCompanyBasicInfo
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_COMPANY_INFO_REQUESTING, getCompanyBasicInfoFlow),
    takeLatest(GET_CONTRACT_LINK_REQUESTING, getContractTempLinkFlow),
    takeLatest(GET_CONTRACTS_REQUESTING, getContractsFlow),
    takeLatest(UPDATE_COMPANY_INFO_REQUESTING, updateCompanyBasicInfoFlow),
  ])
}

import axios from 'axios'
import { print } from 'graphql'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../services/api'
import { getFromLS } from '../../services/security'
import {
  ADDRESS_ERROR,
  ADDRESS_REQUESTING,
  ADDRESS_SUCCESSFUL,
  GET_CONTRACT_ERROR,
  GET_CONTRACT_REQUESTING,
  GET_CONTRACT_SUCCESSFUL,
  GET_DEFAULT_CONTRACT_ERROR,
  GET_DEFAULT_CONTRACT_REQUESTING,
  GET_DEFAULT_CONTRACT_SUCCESSFUL,
  GET_REGISTER_CONTRACT_ERROR,
  GET_REGISTER_CONTRACT_REQUESTING,
  GET_REGISTER_CONTRACT_SUCCESSFUL,
  HAS_CLIENT_SIGNED_UP_ERROR,
  HAS_CLIENT_SIGNED_UP_REQUESTING,
  HAS_CLIENT_SIGNED_UP_SUCCESSFUL,
  REGISTER_NEW_COMPANY_ERROR,
  REGISTER_NEW_COMPANY_REQUESTING,
  REGISTER_NEW_COMPANY_SUCCESSFUL,
  SIGNUP_ERROR,
  SIGNUP_REQUESTING,
  SIGNUP_SUCCESSFUL,
} from './constants'


function* getAddressFlow({ payload }) {
  try {
    const { data } = yield call(getAddressApi, payload)
    yield put({ type: ADDRESS_SUCCESSFUL, payload: data })
  } catch (error) {
    yield put({ type: ADDRESS_ERROR, error: handleApiError(error) })
  }
}

async function getAddressApi(payload) {
  return await axios.get(
    'https://api.postmon.com.br/v1/cep/' + payload.payload
  )
}

function* getContractFlow({ payload }) {
  try {
    const response = yield call(getContractApi, payload)
    yield put({ type: GET_CONTRACT_SUCCESSFUL, payload: response })
  } catch (error) {
    yield put({ type: GET_CONTRACT_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getContractApi({ cnpj, companyName, ID, hasDealId }) {
  const url = `${urlMap.company}/company/public`
  const GET_CONTRACT_QUERY = gql`
    query($input: SignUpContractInput!) {
      getSignUpContract(input: $input)
    }
  `

  const query = print(GET_CONTRACT_QUERY)
  const variables = {
    input: {
      cnpj,
      companyName,
      ID,
      hasDealId,
    }
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.getSignUpContract
}

function* getDefaultContractFlow({ payload }) {
  try {
    const response = yield call(getDefaultContractApi, payload)
    yield put({ type: GET_DEFAULT_CONTRACT_SUCCESSFUL, payload: response })
  } catch (error) {
    yield put({ type: GET_DEFAULT_CONTRACT_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getDefaultContractApi({ cnpj, companyName }) {
  const url = `${urlMap.company}/company/public`
  const GET_DEFAULT_CONTRACT_QUERY = gql`
    query($input: SignUpContractInput!) {
      getDefaultSignUpContract(input: $input)
    }
  `

  const query = print(GET_DEFAULT_CONTRACT_QUERY)
  const variables = {
    input: {
      cnpj,
      companyName,
    }
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.getDefaultSignUpContract
}

function* getRegisterContractFlow({ payload }) {
  try {
    const response = yield call(getRegisterContractApi, payload)
    yield put({ type: GET_REGISTER_CONTRACT_SUCCESSFUL, payload: response })
  } catch (error) {
    yield put({ type: GET_REGISTER_CONTRACT_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getRegisterContractApi({ cnpj, companyName, groupId }) {
  const url = `${urlMap.company}/company/graphql`
  const GET_GROUP_CONTRACT_QUERY = gql`
    query($groupId: String! $cnpj: String! $companyName: String!) {
      getRegisterContract(
        cnpj: $cnpj
        companyName: $companyName
        groupId: $groupId
      )
    }
  `

  const query = print(GET_GROUP_CONTRACT_QUERY)
  const variables = {
    cnpj,
    companyName,
    groupId,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.getRegisterContract
}

function* hasClientSignedUpFlow({ payload }) {
  try {
    const response = yield call(hasClientSignedUpApi, payload)
    yield put({ type: HAS_CLIENT_SIGNED_UP_SUCCESSFUL, payload: response })
  } catch (error) {
    yield put({ type: HAS_CLIENT_SIGNED_UP_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function hasClientSignedUpApi({ ID }) {
  const url = `${urlMap.company}/company/public`
  const HAS_CLIENT_SIGNED_UP_QUERY = gql`
    query($ID: ID!) {
      hasClientSignedUp(ID: $ID)
    }
  `

  const query = print(HAS_CLIENT_SIGNED_UP_QUERY)
  const variables = { ID }

  const response = await request.graphql(url, { query, variables })

  return response.data.hasClientSignedUp
}

function* registerFlow({ payload }) {
  try {
    const response = yield call(registerApi, payload)
    yield put({ type: REGISTER_NEW_COMPANY_SUCCESSFUL, payload: response })
  } catch (error) {
    yield put({ type: REGISTER_NEW_COMPANY_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function registerApi(payload) {
  const {
    adminId,
    companyId,
    groupId,
  } = getFromLS('clientData')

  const REGISTER_COMPANY_QUERY = gql`
    mutation($adminId: ID! $companyId: ID! $input: RegisterCompanyInput!) {
      registerCompany(
        adminId: $adminId
        companyId: $companyId
        input: $input
      ) {
          _id
          cnpj
          companyName
          companySteps {
            companyConfig
            onboarding
          }
          featureConfig {
            apiEnabled
            awardsCustomDescriptionEnabled
            awardsEnabled
            benefitsEnabled
            depositAdditionalInfoEnabled
            employeeCardOrderEnabled
            expenseManagementEnabled
            flashCashEnabled
            flashPointsEnabled
            groceryExclusiveBalanceEnabled
            homeOfficeEnabled
            mealExclusiveBalanceEnabled
            mealGroceryEnabled
            mealGroceryExclusiveBalanceEnabled
            mobilityExclusiveBalanceEnabled
            restDayCreditEnabled
            subOrdersEnabled
            overwriteShippingTypeEnabled
            topUpEnabled
            depositRefundEnabled
          }
          groupId
          path
          permissions {
            admins {
              adminPermissionsEdit
              adminRemove
              adminUpsert
            }
            benefits {
              benefitsEdit
            }
            billets {
              pageView
              reportView
            }
            deposits {
              pageView
              reportView
            }
            cardOrders {
              cardOrderCancel
              cardOrderCreate
              cardOrderDetailsView
              subOrdersReportView
            }
            company {
              companyRegister
              infoEdit
            }
            employees {
              awardsOrderView
              benefitsEdit
              cardsOrderView
              discountEdit
              employeesAdd
              employeesEdit
              employeesRemove
              homeOfficeOrderView
              notificationResend
              orderBalanceCreate
              orderCreate
            }
            flashCash {
              balanceAdd
              pageView
            }
          }
      }
    }
  `
  const query = print(REGISTER_COMPANY_QUERY)
  const variables = {
    adminId,
    companyId,
    input: {
      address: payload.address,
      addressNumber: payload.addressNumber,
      city: payload.city,
      cnpj: payload.cnpj,
      companyName: payload.companyName,
      companyDomain: payload.companyDomain,
      complement: payload.complement,
      contractSignerCPF: payload.contractSignerCPF,
      contractSignerName: payload.contractSignerName,
      countyRegistration: payload.countyRegistration,
      gaCookie: Cookies.get('_ga'),
      groupId,
      neighborhood: payload.neighborhood,
      phone: payload.phone,
      state: payload.state,
      stateRegistration: payload.stateRegistration,
      totalEmployees: payload.totalEmployees,
      tradeName: payload.tradeName,
      zipCode: payload.zipCode,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.registerCompany
}

function* signupFlow({ payload }) {
  try {
    const { data } = yield call(signupApi, payload)
    yield put({ type: SIGNUP_SUCCESSFUL, payload: data.emailAssociated })
  } catch (error) {
    yield put({ type: SIGNUP_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function signupApi(payload) {
  const body = {
    ...payload,
    gaCookie: Cookies.get('_ga')
  }
  return await request.post(
    `${urlMap['company']}/company/signup`,
    body,
    { headers: { 'Content-Type': 'application/json' }}
  )
}

export default function* rootSaga() {
  yield all([
    takeLatest(ADDRESS_REQUESTING, getAddressFlow),
    takeLatest(GET_CONTRACT_REQUESTING, getContractFlow),
    takeLatest(GET_DEFAULT_CONTRACT_REQUESTING, getDefaultContractFlow),
    takeLatest(GET_REGISTER_CONTRACT_REQUESTING, getRegisterContractFlow),
    takeLatest(HAS_CLIENT_SIGNED_UP_REQUESTING, hasClientSignedUpFlow),
    takeLatest(REGISTER_NEW_COMPANY_REQUESTING, registerFlow),
    takeLatest(SIGNUP_REQUESTING, signupFlow),
  ])
}

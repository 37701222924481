import axios from 'axios'
import { errorBackMessages } from '@flash-tecnologia/flamingo-ui/src/utils'
import { urlMap } from '../api'
import { getFromLS } from '../security'
import { decode, encode } from '../security/encoding'
import { logout, refreshToken } from '../security/session'
import { getAccessToken } from '@flash-tecnologia/hros-web-utility'

const HROS = process.env.HROS

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(async (request) => {
  let authToken
  if (Number(HROS)) {
    authToken = await getAccessToken()
  } else {
    authToken = getFromLS('authData')?.idToken
  }
  if (authToken) {
    request.headers.Authorization = authToken
  }
  request.headers['X-Request-Id'] =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  return request
})

axiosInstance.interceptors.response.use(null, (error) => {
  const { config, response: { status }} = error
  const isRetry = config._retry
  if (status === 401 && !isRetry) {
    return refreshToken()
      .then(() => {
        config._retry = true
        return axiosInstance(config)
      })
      .catch(logout)
  }
  if (isRetry) {
    logout()
  }
  return Promise.reject(error)
})

export const request = {
  get: async (url, options) => (
    treatIncomingRes(await axiosInstance.get(url, options))
  ),
  post: async (url, body, options) => (
    treatIncomingRes(await axiosInstance.post(
      url,
      prepOutgoingBody(body, options),
      options
    ))
  ),
  graphql: async (
    url = `${urlMap.company}/company/graphql`,
    body,
    options = {
      headers: { 'Content-Type': 'application/json' }
    }
  ) => (
    handleGraphqlRes(await axiosInstance.post(url, body, options))
  ),
}

const prepOutgoingBody = (body, options) => (
  options?.encoding
    ? encode(JSON.stringify(body))
    : JSON.stringify(body)
)

const treatIncomingRes = (res) => {
  try {
    return { ...res, data: JSON.parse(decode(res.data)) }
  } catch (e) {
    return res
  }
}

const handleGraphqlRes = (response) => {
  if (response.data && response.data.errorMessage) {
    throw new Error(JSON.stringify({
      graphqlRes: {
        status: 500,
        data: { message: 'server' }
      }
    }))
  }
  if (response.data && response.data.errors) {
    throw new Error(JSON.stringify({
      graphqlRes: {
        status: 400,
        data: { message: response.data.errors[0].message, params: response.data.errors[0].params }
      }
    }))
  }
  return response.data
}

export const handleApiError = (error) => {
  let errorMessage
  if (error && error.response && (error.response.status === 400 || error.response.status === 500)) {
    errorMessage = getMessage(errorBackMessages, treatIncomingRes(error.response).data.message)
  } else {
    try {
      const {
        graphqlRes: {
          data: {
            message,
            params,
          }
        }
      } = JSON.parse(error.message)
      errorMessage = getMessage(errorBackMessages, message, params)
    } catch (err) {
      errorMessage = getMessage(errorBackMessages, error.message)
    }
  }
  return errorMessage
}

const getMessage = (messageMap, message, params) => {
  if (messageMap[message]) {
    return params
      ? errorBackMessages[message](params)
      : errorBackMessages[message]
  }

  return /[.!]/.test(message) ? message : messageMap.default
}
import styled from 'styled-components'
import { flexGradient } from '@flash-tecnologia/flamingo-ui/src/baseStyles'

export const ContainerStyled = styled.div`
  position: fixed;
  bottom: 160px;
  right: 40px;
  button {
    background: ${flexGradient};
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 5px 0px rgb(255 110 96 / 50%);
    }
  }
`
export const UlStyled = styled.ul`
  margin: 24px 0 0px 0;
  padding: 0 20px;
  max-height: calc(100vh - 320px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #D6D7D8;
    border-radius: 500px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(74, 78, 87, 0.2);
    border-radius: 500px;
  }
  li {
    text-align: left;
    list-style: none;
    padding-bottom: 12px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:last-of-type {
      display: flex;            
      & > div {
        max-width: 282px;
      }
      svg {
        margin-right: 12px; 
        opacity: 0.4; 
        width: 18px;
      }
    }
    .displayOptions { 
      transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      max-height: 0;
      overflow: hidden;
    }
    &.active {
      background: #F6F8F9;
      border-radius: 10px;
      padding: 13px 13px 0 13px;
      margin: 0 0 16px -13px;
      & .displayOptions {
        transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
        max-height: 500px;
      }
    }
  } 
`
export const UlBoxStyled = styled.ul`
  margin: 24px 0 0px 0;
  padding: 0 20px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  li {
    text-align: left;
    list-style: none;
    padding-bottom: 12px;
    & label {
      width: 100% !important;
      p {
      font-size: 14px; 
      font-weight: 700;
      }
    }
    &:last-of-type {
      display: flex;
      padding-bottom: 0px;
      & label {
        max-width: 310px;
      }
      & > div {
        max-width: 180px;
        margin-top: -6px;
        display: flex;
        & > input {
          background: none ;
        }
        & > div {
          position: relative;
          left: -24px;
          top: -3px;
        }
      }      
    }
  }
  
`
export const SuccessReturnStyled = styled.div`
  padding: 60px 0 90px 0;
  span {
    font-size: 92px;
    margin-top: 34px;
    display: block;
  }
`
import { segmentTracking as hrosSegmentTracking } from '@flash-tecnologia/hros-web-utility'

export function segmentTracking(track, params = {}) {
  const URL = process.env.URL
  const isHROSenv = !!Number(process.env.HROS)

  const trackName = ['featureBranch', 'dev'].includes(URL) ? `[TESTE]${track}` : track

  if (isHROSenv) {
    hrosSegmentTracking({
      track: trackName,
      params,
      businessUnit: 'benefits',
      module: 'embeeded-benefits-order',
    })
    return
  }
  // eslint-disable-next-line no-console
  console.debug('tracking:', trackName)
  window.analytics.track(trackName, params)
}
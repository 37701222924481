import { segmentTracking } from '@flash-tecnologia/hros-web-utility'
import * as Sentry from '@sentry/react'
import { print } from 'graphql'
import gql from 'graphql-tag'
import { createBrowserHistory } from 'history'
import moment from 'moment-timezone'
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  handleApiError,
  request,
  urlMap,
} from '../../services/api'
import { getFromLS, setInLS } from '../../services/security'
import {
  CHECK_SSO_ERROR,
  CHECK_SSO_REQUESTING,
  CHECK_SSO_SUCCESSFUL,
  LOGIN_ERROR,
  LOGIN_REQUESTING,
  LOGIN_SUCCESSFUL,
  LOGOUT_ERROR,
  LOGOUT_REQUESTING,
  LOGOUT_SUCCESSFUL,
} from './constants'

const URL = process.env.URL
const HROS = process.env.HROS
const NODE_ENV = process.env.NODE_ENV

const browserHistory = createBrowserHistory({ forceRefresh: true, ...(Number(HROS) && { basename: '/benefits' }) })

function* loginFlow({ payload }) {
  try {
    const { type } = payload
    const requiredKeys = [
      '_id',
      'nameAssociated',
      'companyId',
    ]
    const { data } = yield call(
      companyLoginApi,
      payload,
    )
    if (data.errors && data.errors.length) {
      yield put({
        type: LOGIN_ERROR, payload: { error: data.errors[0].message }
      })
      return
    }
    let storageData = {
      type,
      timestamp: moment(),
    }

    if (!requiredKeys.every(key => data[key])) throw new Error('server')

    storageData = {
      ...storageData,
      acceptedPatTerms: data.acceptedPatTerms,
      adminId: data._id,
      adminName: data.nameAssociated,
      companies: data.companyId,
      companyId: data.companyId[0]._id,
      companySteps: data.companyId[0].companySteps || {},
      emailAssociated: data.emailAssociated,
      employeesQuantity: data.companyId[0].employeesQuantity || 0,
      env: data.env,
      featureConfig: data.companyId[0].featureConfig || {},
      groupId: data.companyId[0].groupId,
      haveExpenseManagement: data.companyId[0].haveExpenseManagement,
      havePlasticActiveBenefit: data.companyId[0].havePlasticActiveBenefit,
      haveVirtualActiveBenefit: data.companyId[0].haveVirtualActiveBenefit,
      hasContracts: data.companyId[0].hasContracts,
      invoice: data.companyId[0].invoice,
      isFirstOrder: data.companyId[0].isFirstOrder,
      path: data.companyId[0].path,
      permissions: data.companyId[0].permissions || {},
      reports: data.companyId[0].reports,
      shouldChangePassword: data.shouldChangePassword,
      superUser: data.superUser,
      userSteps: data.userSteps || {},
      steps: data.steps || {},
    }
    setInLS('clientData', storageData)
    setInLS('authData', data.auth)
    identifyUserAnalytics()
    yield put({ type: LOGIN_SUCCESSFUL, payload: data })
  } catch (error) {
    yield put({
      type: LOGIN_ERROR, payload: { error: handleApiError(error) }
    })
  }
}

export const identifyUserAnalytics = () => {
  const { adminId, companyId, employeesQuantity, isFirstOrder, emailAssociated } = getFromLS('clientData')
  if (NODE_ENV === 'production') Sentry.setUser({ username: adminId, email: emailAssociated })
  if (Number(HROS)) {
    segmentTracking({
      businessUnit: 'benefits',
      module: 'embeeded-benefits-order',
      track: 'identify',
      identify: {
        id: adminId,
        params: {
          enviroment: URL,
          companyId,
          employeesQuantity,
          isFirstOrder,
          platform: navigator?.userAgentData?.platform || 'unknown'
        }
      }
    })
  } else {
    window.analytics.identify(adminId, {
      enviroment: URL,
      companyId,
      employeesQuantity,
      isFirstOrder,
      platform: navigator?.userAgentData?.platform || 'unknown'
    })
  }
}

function companyLoginApi(payload) {
  const type = payload.type
  return request.post(
    `${urlMap[type]}/${type}/login`,
    payload,
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

function* logoutFlow({ payload }) {
  try {
    const { replaceURL } = payload
    call(logoutApi)
    setInLS('clientData', {})
    setInLS('authData', {})
    replaceURL
      ? window.location.replace(replaceURL)
      : browserHistory.push('/')
    yield put({ type: LOGOUT_SUCCESSFUL })
  } catch (error) {
    setInLS('clientData', {})
    setInLS('authData', {})
    yield put({
      type: LOGOUT_ERROR, payload: { error: handleApiError(error) }
    })
  }
}

async function logoutApi() {
  const { type, adminId } = getFromLS('clientData')
  return await request.post(
    `${urlMap[type]}/${type}/logout`,
    { _id: adminId },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

function* checkSSOFlow({ payload }) {
  try {
    const { email } = payload
    const { appClientId, shouldBeRedirectedToOS } = yield call(checkSSOApi, { email })
    yield put({
      type: CHECK_SSO_SUCCESSFUL,
      payload: { appClientId, shouldBeRedirectedToOS }
    })
  } catch (error) {
    yield put({
      type: CHECK_SSO_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function checkSSOApi({ email }) {
  const url = `${urlMap.company}/company/public`
  const query = print(gql`
    query($email: String!) {
      hasSSO(email: $email) {
        appClientId
        shouldBeRedirectedToOS
      }
    }
  `)
  const variables = {
    email,
  }
  const response = await request.graphql(url, { query, variables })
  return response.data.hasSSO
}

export default function* rootSaga() {
  yield all([
    takeLatest(CHECK_SSO_REQUESTING, checkSSOFlow),
    takeLatest(LOGIN_REQUESTING, loginFlow),
    takeLatest(LOGOUT_REQUESTING, logoutFlow),
  ])
}

export {
  logoutFlow
}

import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { pinkColor } from '../assets/css/baseTextStyles.css'


export default class Loading extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  static propTypes = {
    customWrapperStyle: PropTypes.object,
    customColor: PropTypes.string,
    customSize: PropTypes.number,
    customHeight: PropTypes.string
  }


  render() {
    const { customColor = '', customSize, customHeight, style } = this.props
    const divStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: customHeight || '50vh',
      ...style
    }
    const loadingStyle = { color: customColor || pinkColor }
    const loadingSize = customSize || 100


    return (
      <div style={divStyle}>
        <CircularProgress size={loadingSize} style={loadingStyle} />
      </div>
    )
  }
}
import {
  ADDRESS_ERROR,
  ADDRESS_REQUESTING,
  ADDRESS_SUCCESSFUL,
  GET_CONTRACT_ERROR,
  GET_CONTRACT_REQUESTING,
  GET_CONTRACT_SUCCESSFUL,
  GET_DEFAULT_CONTRACT_ERROR,
  GET_DEFAULT_CONTRACT_REQUESTING,
  GET_DEFAULT_CONTRACT_SUCCESSFUL,
  GET_REGISTER_CONTRACT_ERROR,
  GET_REGISTER_CONTRACT_REQUESTING,
  GET_REGISTER_CONTRACT_SUCCESSFUL,
  HAS_CLIENT_SIGNED_UP_ERROR,
  HAS_CLIENT_SIGNED_UP_REQUESTING,
  HAS_CLIENT_SIGNED_UP_SUCCESSFUL,
  REGISTER_NEW_COMPANY_ERROR,
  REGISTER_NEW_COMPANY_REQUESTING,
  REGISTER_NEW_COMPANY_SUCCESSFUL,
  SIGNUP_ERROR,
  SIGNUP_REQUESTING,
  SIGNUP_SUCCESSFUL,
} from './constants'

const initState = {
  requesting: false,
  requestingAddress: false,
  success: false,
  getContractRequesting: false,
  getContractSuccess: true,
  getDefaultContractRequesting: false,
  getDefaultContractSuccess: true,
  getRegisterContractRequesting: false,
  getRegisterContractSuccess: false,
  hasClientSignedUpRequesting: false,
  hasClientSignedUp: false,
  contractURL: '',
  email: '',
  error: '',
  address: {},
  company: {},
  contract: {},
  groupContract: {},
}

export default function reducer(state = initState, action) {
  const { type, payload } = action
  switch (type) {
    case ADDRESS_REQUESTING:
      return {
        ...state,
        requestingAddress: true,
        success: false,
        email: '',
        error: '',
        address: {},
      }
    case ADDRESS_SUCCESSFUL:
      return {
        ...state,
        requestingAddress: false,
        success: true,
        address: {
          neighborhood: payload.bairro,
          city: payload.cidade,
          state: payload.estado,
          street: payload.logradouro
        }
      }
    case ADDRESS_ERROR:
      return {
        ...state,
        requestingAddress: false,
        success: false,
        address: {
          neighborhood: '',
          city: '',
          state: '',
          street: ''
        }
      }
  
    case GET_CONTRACT_REQUESTING:
      return {
        ...state,
        getContractRequesting: true,
        getContractSuccess: false,
        contractURL: '',
        error: '',
      }
    case GET_CONTRACT_SUCCESSFUL:
      return {
        ...state,
        getContractRequesting: false,
        getContractSuccess: true,
        contractURL: payload,
        error: '',
      }
    case GET_CONTRACT_ERROR:
      return {
        ...state,
        getContractRequesting: false,
        getContractSuccess: false,
        contractURL: '',
        error: payload.error,
      }

    case GET_DEFAULT_CONTRACT_REQUESTING:
      return {
        ...state,
        getDefaultContractRequesting: true,
        getDefaultContractSuccess: false,
        contractURL: '',
        error: '',
      }
    case GET_DEFAULT_CONTRACT_SUCCESSFUL:
      return {
        ...state,
        getDefaultContractRequesting: false,
        getDefaultContractSuccess: true,
        contractURL: payload,
        error: '',
      }
    case GET_DEFAULT_CONTRACT_ERROR:
      return {
        ...state,
        getDefaultContractRequesting: false,
        getDefaultContractSuccess: false,
        contractURL: '',
        error: payload.error,
      }

    case GET_REGISTER_CONTRACT_REQUESTING:
      return {
        ...state,
        contractURL: '',
        getRegisterContractRequesting: true,
        getRegisterContractSuccess: false,
        error: '',
      }
    case GET_REGISTER_CONTRACT_SUCCESSFUL:
      return {
        ...state,
        contractURL: payload,
        getRegisterContractRequesting: false,
        getRegisterContractSuccess: true,
        error: '',
      }
    case GET_REGISTER_CONTRACT_ERROR:
      return {
        ...state,
        contractURL: '',
        getRegisterContractRequesting: false,
        getRegisterContractSuccess: false,
        error: payload.error,
      }

    case HAS_CLIENT_SIGNED_UP_REQUESTING:
      return {
        ...state,
        hasClientSignedUpRequesting: true,
      }
    case HAS_CLIENT_SIGNED_UP_SUCCESSFUL:
      return {
        ...state,
        hasClientSignedUpRequesting: false,
        hasClientSignedUp: payload,
      }
    case HAS_CLIENT_SIGNED_UP_ERROR:
      return {
        ...state,
        hasClientSignedUpRequesting: false,
        hasClientSignedUp: false,
      }

    case REGISTER_NEW_COMPANY_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        email: '',
        error: '',
        address: {},
        company: {},
      }
    case REGISTER_NEW_COMPANY_SUCCESSFUL:
      return {
        ...state,
        requesting: false,
        company: payload,
        success: true,
      }
    case REGISTER_NEW_COMPANY_ERROR:
      return {
        ...state,
        requesting: false,
        success: false,
        error: payload.error,
        company: {},
      }

    case SIGNUP_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        email: '',
        error: '',
        address: {},
      }
    case SIGNUP_SUCCESSFUL:
      return {
        ...state,
        requesting: false,
        email: payload,
        success: true,
      }
    case SIGNUP_ERROR:
      return {
        ...state,
        requesting: false,
        success: false,
        error: payload.error,

      }

    default:
      return state
  }
}

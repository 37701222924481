import gql from 'graphql-tag'

export const CORE_EMPLOYEE_POINTS_CONFIGURATION = gql`
  fragment CoreEmployeePointsConfiguration on PublicEmployeePayload {
    employee {
        _id
        cpfAssociated
        company { 
          flashPoints { 
            customBenefits { 
              key
              name
              dependents {
                paymentMethods
              }
              paymentMethods
              subGroups {
                key
                name
              }
            }
          }
        }
        name
        defaultCardProduct
        benefitCategories {
          category
          amount
          exclusiveAmount
        }
        virtualBenefitsCategories {
          category
          amount
          exclusiveAmount
        }
        flashPoints {
          policy {
            name
            totalPoints
            requiredPointsBenefits {
              description
              customBenefitKey
              points
            }
          }
          selectedCustomBenefits {
            customBenefitKey
            dependents {
              key
              payment {
                points
                payroll
              }
            }
            dueDate
            payment {
              points
              payroll
            }
            subGroupKey
          }
          status
        }
        company {
          flashPoints {
            customBenefits {
              key
              name
            }
          } 
        }
      }
  }`

import {
  SEND_CONFIRM_ACCOUNT_EMAIL_REQUESTING,
  SEND_CONFIRM_ACCOUNT_EMAIL_SUCCESSFUL,
  SEND_CONFIRM_ACCOUNT_EMAIL_ERROR,
} from './constants'

const initialState = {
  sendEmailRequesting: false,
  sendEmailSuccessful: false,
  error: '',
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SEND_CONFIRM_ACCOUNT_EMAIL_REQUESTING:
      return {
        ...state,
        sendEmailRequesting: true,
        sendEmailSuccessful: false,
      }
    case SEND_CONFIRM_ACCOUNT_EMAIL_SUCCESSFUL:
      return {
        ...state,
        sendEmailRequesting: false,
        sendEmailSuccessful: true,
        error: '',
      }
    case SEND_CONFIRM_ACCOUNT_EMAIL_ERROR:
      return {
        ...state,
        sendEmailRequesting: false,
        sendEmailSuccessful: false,
        error: payload.error,
      }
    default:
      return state
  }
}

import {
  GET_COMPANY_BENEFITS_REQUESTING,
  GET_COMPANY_BENEFITS_SUCCESS,
  GET_COMPANY_BENEFITS_ERROR,
  GET_COMPANY_DEPOSITS_ERROR,
  GET_COMPANY_DEPOSITS_REQUESTING,
  GET_COMPANY_DEPOSITS_SUCCESS,
  SEND_DEPOSITS_REPORT_ERROR,
  SEND_DEPOSITS_REPORT_REQUESTING,
  SEND_DEPOSITS_REPORT_SUCCESS,
  UPDATE_DEPOSIT_ERROR,
  UPDATE_DEPOSIT_REQUESTING,
  UPDATE_DEPOSIT_SUCCESS,
} from './constants'

const initialState = {
  benefitsRequesting: false,
  benefitsSuccessful: false,
  benefitsData: {},
  depositsRequesting: false,
  depositsSuccessful: false,
  depositsData: [],
  error: '',
  reportRequesting: false,
  reportSuccess: false,
  updateDepositRequesting: false,
  updateDepositSuccessful: false,
  updateDeposit: {}
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_COMPANY_BENEFITS_REQUESTING:
      return {
        ...state,
        benefitsRequesting: true,
        error: false,
      }

    case GET_COMPANY_BENEFITS_SUCCESS:
      return {
        ...state,
        benefitsRequesting: false,
        benefitsSuccessful: true,
        benefitsData: payload,
        error: false,
      }

    case GET_COMPANY_BENEFITS_ERROR:
      return {
        ...state,
        benefitsRequesting: false,
        benefitsSuccessful: false,
        error: payload.error,
      }

    case GET_COMPANY_DEPOSITS_REQUESTING:
      return {
        ...state,
        depositsRequesting: true,
        error: false,
      }

    case GET_COMPANY_DEPOSITS_SUCCESS:
      return {
        ...state,
        depositsRequesting: false,
        depositsSuccessful: true,
        depositsData: payload,
        error: false,
      }

    case GET_COMPANY_DEPOSITS_ERROR:
      return {
        ...state,
        depositsRequesting: false,
        depositsSuccessful: false,
        error: payload.error,
      }

    case SEND_DEPOSITS_REPORT_REQUESTING:
      return {
        ...state,
        reportRequesting: true,
        reportSuccess: false,
        error: false,
      }

    case SEND_DEPOSITS_REPORT_SUCCESS:
      return {
        ...state,
        reportRequesting: false,
        reportSuccess: true,
        error: false,
      }

    case SEND_DEPOSITS_REPORT_ERROR:
      return {
        ...state,
        reportRequesting: false,
        reportSuccess: false,
        error: payload.error,
      }

    case UPDATE_DEPOSIT_REQUESTING:
      return {
        ...state,
        updateDepositRequesting: true,
        updateDepositSuccessful: false,
        error: false,
      }

    case UPDATE_DEPOSIT_SUCCESS:
      return {
        ...state,
        updateDepositRequesting: false,
        updateDepositSuccessful: true,
        error: false,
        updateDeposit: payload
      }

    case UPDATE_DEPOSIT_ERROR:
      return {
        ...state,
        updateDepositRequesting: false,
        updateDepositSuccessful: false,
        error: payload.error,
      }

    default:
      return state
  }
}

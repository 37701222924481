const PropTypes = require('prop-types')

// determines the order permissions are displayed
const permTypesMap = {
  employees: 'Colaboradores',
  benefits: 'Benefícios',
  deposits: 'Depósitos',
  flashCash: 'Flash Cash',
  cardOrders: 'Cartões',
  billets: 'Boletos',
  company: 'Empresa',
  reports: 'Relatórios',
  admins: 'Gerenciar Administradores',
}

// determines the names and default value of each individual permission
const permValuesMap = {
  admins: {
    adminsView: {
      name: 'Visualizar Página de Gerenciar Administradores',
      defaultValue: true,
    },
    adminUpsert: {
      name: 'Adicionar / Editar Administrador',
      defaultValue: true,
    },
    adminPermissionsEdit: {
      name: 'Editar Permissão de Administrador',
      defaultValue: true,
    },
    adminRemove: {
      name: 'Deletar Administrador',
      defaultValue: true,
    },
  },
  benefits: {
    benefitsView: {
      name: 'Visualizar Página de Benefícios',
      defaultValue: true,
    },
    benefitsEdit: {
      name: 'Editar benefícios, Saldo Exclusivo e Forma de Cálculo',
      defaultValue: true,
    },
  },
  billets: {
    pageView: {
      name: 'Visualizar Página de Boletos',
      defaultValue: true,
    },
    reportView: {
      name: 'Baixar Relatório de Boleto',
      defaultValue: true,
    },
    billetCancel: {
      name: 'Cancelar Boleto',
      defaultValue: true,
    },
  },
  deposits: {
    pageView: {
      name: 'Visualizar Página de Depositos',
      defaultValue: true,
    },
    reportView: {
      name: 'Baixar Relatório de Depósitos',
      defaultValue: true,
    },
    depositCancel: {
      name: 'Cancelar Depósito',
      defaultValue: true,
    },
  },
  cardOrders: {
    cardOrdersView: {
      name: 'Visualizar Página de Cartões',
      defaultValue: true,
    },
    cardOrderCreate: {
      name: 'Fazer Pedido',
      defaultValue: true,
    },
    cardOrderCancel: {
      name: 'Cancelar Pedido',
      defaultValue: true,
    },
    cardOrderDetailsView: {
      name: 'Visualizar Informações do Pedido',
      defaultValue: true,
    },
    subOrdersReportView: {
      name: 'Baixar Relatório de Pedido de Cartões',
      defaultValue: true,
    },
  },
  company: {
    companyView: {
      name: 'Visualizar Dados da Empresa',
      defaultValue: true,
    },
    infoEdit: {
      name: 'Editar Informações da Empresa',
      defaultValue: true,
    },
    companyRegister: {
      name: 'Adicionar Nova Empresa no Grupo',
      defaultValue: true,
    },
  },
  employees: {
    benefitsList: {
      name: 'Visualizar Página de Colaboradores',
      defaultValue: true,
    },
    cardsOrderView: {
      name: 'Visualizar Aba de Cartão Flash',
      defaultValue: true,
    },
    awardsOrderView: {
      name: 'Visualizar Aba de Premiação Virtual',
      defaultValue: true,
    },
    homeOfficeOrderView: {
      name: 'Visualizar Aba de Auxílio Home Office',
      defaultValue: true,
    },
    benefitsEdit: {
      name: 'Editar Benefícios',
      defaultValue: true,
    },
    discountEdit: {
      name: 'Editar Descontos',
      defaultValue: true,
    },
    employeesAdd: {
      name: 'Adicionar Colaboradores',
      defaultValue: true,
    },
    employeesEdit: {
      name: 'Editar Colaboradores',
      defaultValue: true,
    },
    employeesRemove: {
      name: 'Deletar Colaboradores',
      defaultValue: true,
    },
    notificationResend: {
      name: 'Reenviar Comunicação',
      defaultValue: true,
    },
    orderCreate: {
      name: 'Fazer Pedido',
      defaultValue: true,
    },
    orderBalanceCreate: {
      name: 'Fazer Pedido com saldo Flash Cash',
      defaultValue: true,
    },
  },
  flashCash: {
    pageView: {
      name: 'Visualizar Página de Flash Cash',
      defaultValue: true,
    },
    balanceAdd: {
      name: 'Adicionar Saldo',
      defaultValue: true,
    },
  },
  reports: {
    pageView: {
      name: 'Visualizar Página Relatórios',
      defaultValue: true,
    },
  },
}

const permissionsPropType = PropTypes.shape({
  admins: PropTypes.shape({
    adminUpsert: PropTypes.bool,
    adminPermissionsEdit: PropTypes.bool,
    adminRemove: PropTypes.bool,
  }),
  benefits: PropTypes.shape({
    benefitsEdit: PropTypes.bool,
  }),
  billets: PropTypes.shape({
    pageView: PropTypes.bool,
    reportView: PropTypes.bool,
  }),
  deposits: PropTypes.shape({
    pageView: PropTypes.bool,
    reportView: PropTypes.bool,
  }),
  cardOrders: PropTypes.shape({
    cardOrderCreate: PropTypes.bool,
    cardOrderCancel: PropTypes.bool,
    cardOrderDetailsView: PropTypes.bool,
    subOrdersReportView: PropTypes.bool,
  }),
  company: PropTypes.shape({
    infoEdit: PropTypes.bool,
    companyRegister: PropTypes.bool,
  }),
  employees: PropTypes.shape({
    benefitsList: PropTypes.bool,
    benefitsEdit: PropTypes.bool,
    discountEdit: PropTypes.bool,
    employeesAdd: PropTypes.bool,
    employeesEdit: PropTypes.bool,
    employeesRemove: PropTypes.bool,
    notificationResend: PropTypes.bool,
    orderBalanceCreate: PropTypes.bool,
    orderCreate: PropTypes.bool,
  }),
  flashCash: PropTypes.shape({
    pageView: PropTypes.bool,
    balanceAdd: PropTypes.bool,
  }),
  reports: PropTypes.shape({
    pageView: PropTypes.bool,
  }),
})

// these permissions do not exist, for view only
const viewOnlyPermissions = [
  'admins.adminsView',
  'benefits.benefitsView',
  'cardOrders.cardOrdersView',
  'company.companyView',
  'employees.benefitsList',
]

function createEmptyPermissionsObj() {
  return Object.keys(permTypesMap).reduce((permissions, permType) => {
    const permValues = Object.entries(permValuesMap[permType]).reduce(
      (permValues, [permName, { defaultValue }]) => ({ ...permValues, [permName]: defaultValue }), {}
    )
    return {
      ...permissions,
      [permType]: permValues,
    }
  }, {})
}

module.exports = {
  createEmptyPermissionsObj,
  viewOnlyPermissions,
  permTypesMap,
  permValuesMap,
  permissionsPropType,
}

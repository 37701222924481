import styled from 'styled-components';
import { orangeColor, pinkColor } from '../../../assets/css/baseTextStyles.css'

const Popovers = ComponentName => styled(ComponentName)`
  &.ant-popover-content {
    
    > .ant-popover-arrow {
      background-color: ${pinkColor} !important; 
    }
  }

  &.ant-popover-inner {
    background: linear-gradient(${pinkColor}, ${orangeColor});
  }
`;

export { Popovers };
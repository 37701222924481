import {
  CHECK_SSO_REQUESTING,
  LOGIN_REQUESTING,
  LOGOUT_REQUESTING,
} from './constants'

export function checkSSO({ email }) {
  return {
    type: CHECK_SSO_REQUESTING,
    payload: {
      email,
    }
  }
}

export function login(payload) {
  return {
    type: LOGIN_REQUESTING,
    payload,
  }
}

export function logout(payload) {
  return {
    type: LOGOUT_REQUESTING,
    payload,
  }
}
import {
  GET_COMPANY_BENEFITS_REQUESTING,
  GET_COMPANY_DEPOSITS_REQUESTING,
  SEND_DEPOSITS_REPORT_REQUESTING,
  UPDATE_DEPOSIT_REQUESTING,
} from './constants'

export function getCompanyBenefits(payload) {
  return { type: GET_COMPANY_BENEFITS_REQUESTING, payload }
}

export function getCompanyDeposits(payload) {
  return { type: GET_COMPANY_DEPOSITS_REQUESTING, payload }
}

export function sendDepositsReport(payload) {
  return { type: SEND_DEPOSITS_REPORT_REQUESTING, payload }
}

export function updateDeposit(payload) {
  return { type: UPDATE_DEPOSIT_REQUESTING, payload }
}

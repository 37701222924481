import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  RESEND_EMAIL_REQUESTING,
  RESEND_EMAIL_SUCCESSFUL,
  RESEND_EMAIL_ERROR,
} from './constants'
import { urlMap, request, handleApiError } from '../../services/api'

function* resendConfirmationEmailFlow({ payload }) {
  try {
    yield call(sendEmailApi, payload)
    yield put({ type: RESEND_EMAIL_SUCCESSFUL, payload: { type: payload.type }})
  } catch (error) {
    yield put({ type: RESEND_EMAIL_ERROR, payload: { error: handleApiError(error), type: payload.type }})
  }
}

async function sendEmailApi({ email, type }) {
  return await request.post(
    `${urlMap[type]}/${type}/resendConfirmationEmail`,
    { emailAssociated: email },
    { headers: { 'Content-Type': 'application/json' }}
  )
}

export default function* rootSaga() {
  yield all([
    takeLatest(RESEND_EMAIL_REQUESTING, resendConfirmationEmailFlow),
  ])
}
export const fontFamily = 'Nunito'
export const fontWeight = 'bold'
export const blackColor = '#15191C'
export const greyColor = '#4A4E57'
export const lightGreyColor = '#E5E5E5'
export const buttonColor = '#FFFFFF'
export const pinkColor = '#FE2B8F'
export const orangeColor = '#FF6E60'
export const shadedBlackColor = '#15191C50'
export const greenColor = '#4CD964'
export const blueColor = '#00D1FF'
export const purpleColor = '#9475FF'
export const dirtyWhiteColor = '#F6F8F9'
export const yellowColor = '#FFCE00'
export const lightOrangeColor = '#FF9D00'

export const flexGradient = `linear-gradient(${pinkColor}, ${orangeColor})`
export const foodGradient = `linear-gradient(${yellowColor}, ${lightOrangeColor})`

const bodyStyle = {
  color: greyColor,
  fontFamily: fontFamily,
  fontSize: '18px',
  lineHeight: '30px',
  cursor: 'default',
}
const captionStyle = {
  color: greyColor,
  fontFamily: fontFamily,
  fontWeight: fontWeight,
  fontSize: '14px',
  lineHeight: '20px',
  cursor: 'default',
}

export default {
  benefitCellStyle: {
    height: '40px',
    width: '40px',
    borderRadius: '20px',
    marginRight: '10px',
    color: '#fff',
    background: `${greyColor}14`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'default',
  },
  benefitCellWrapperStyle: { 
    display: 'flex', 
    flex: '1', 
    flexDirection: 'row-reverse', 
    alignItems: 'center',
  }, 
  bodyStyle: { ...bodyStyle }, 
  buttonStyle: {
    color: buttonColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '16px',
    lineHeight: '30px',
    textTransform: 'uppercase',  
  },
  burguerMenuLink: {
    color: 'white',
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '20px',
    marginBottom: '10px',
  },
  captionStyle: { ...captionStyle },
  cardStyle: {
    height: '212px',
    display: 'flex',
    alignItems: 'flex-end',
    borderRadius: '10px',
  },
  cardContentStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '5px',
    cursor: 'default',
  },
  cellHeaderStyle: {
    ...bodyStyle,
    fontWeight: fontWeight,
    color: blackColor,
    cursor: 'default',
  },
  cellCaptionStyle: {
    ...captionStyle,
    textTransform: 'uppercase',
    cursor: 'default',
  },
  checkmarkLayout: {
    color: '#00a854', 
    marginLeft: '25px',
    fontSize: '20px',
    position: 'relative',
    float: 'right'
  },
  disabledInputStyle: {
    border: 'none',
    borderBottom: `2px dotted ${greyColor}`,
    borderRadius: 0,
    color: greyColor,
    fontWeight: 'bold',
    height: '24px',
    opacity: '0.5',
  },
  dropdownMenuItemStyle: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    width: 'inherit',
  },
  errorLayout: {
    color: '#f04134',
    marginLeft: '25px',
    fontSize: '20px', 
    position: 'relative',
    float: 'right',
  },
  employeeCheckboxStyle: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  feedbackMessageStyle: { 
    display: 'flex', 
    alignItems: 'center', 
    color: '#FFF' 
  },
  firstLetterIconStyle: {
    width: '50px', 
    height: '50px', 
    borderRadius: '10px', 
    marginRight: '10px',
    background: flexGradient, 
    color: '#FFF', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    fontSize: '20px',
    fontWeight: 'bold',
    cursor: 'default',
  },
  firstTitleStyle: {
    color: blackColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '40px',
    lineHeight: '50px',
    cursor: 'default',
  },
  footer: { 
    display: 'flex', 
    width: '100%',
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: '0 12%', 
    backgroundColor: '#FFF', 
    height: '99px', 
  },
  footerMenu: {
    display: 'flex', 
    flex: 2, 
    justifyContent: 'space-between',
  },
  footerIcon: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: '25px', 
    height: '25px', 
    borderRadius: '15px',
    background: flexGradient,
    cursor: 'pointer',
  },
  footerIconsWrapper: {
    display: 'flex', 
    flex: 1, 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
  },
  headlineStyle: {
    color: blackColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '50px',
    lineHeight: '60px',
    cursor: 'default',
  }, 
  hrStyle: {
    color: greyColor,
    marginBottom: '20px',
    width: '100%',
  },
  inputStyle: {
    border: 'none',
    borderBottom: `2px solid ${blackColor}`,
    borderRadius: 0,
    color: blackColor,
    fontWeight: 'bold',
    height: '24px',
  },
  inputWrapperDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  linkStyle: {
    color: pinkColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '18px',
    lineHeight: '30px',
    textDecoration: 'underline',
  },
  menuStyle: {
    color: blackColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '20px',
    lineHeight: '30px',
  },
  secondTitleStyle: {
    color: blackColor,
    fontFamily: fontFamily,
    fontWeight: fontWeight,
    fontSize: '30px',
    lineHeight: '40px',
    cursor: 'default',
  },

}

import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  ADD_COMPANY_CUSTOM_BENEFIT_ERROR,
  ADD_COMPANY_CUSTOM_BENEFIT_REQUESTING,
  ADD_COMPANY_CUSTOM_BENEFIT_SUCCESS,
  CREATE_COMPANY_BENEFITS_ERROR,
  CREATE_COMPANY_BENEFITS_REQUESTING,
  CREATE_COMPANY_BENEFITS_SUCCESS,
  DELETE_COMPANY_CUSTOM_BENEFIT_ERROR,
  DELETE_COMPANY_CUSTOM_BENEFIT_REQUESTING,
  DELETE_COMPANY_CUSTOM_BENEFIT_SUCCESS,
  UPDATE_COMPANY_BENEFITS_ERROR,
  UPDATE_COMPANY_BENEFITS_REQUESTING,
  UPDATE_COMPANY_BENEFITS_SUCCESS,
  UPDATE_COMPANY_CUSTOM_BENEFIT_ERROR,
  UPDATE_COMPANY_CUSTOM_BENEFIT_REQUESTING,
  UPDATE_COMPANY_CUSTOM_BENEFIT_SUCCESS,
} from './constants'

function* addCompanyCustomBenefitFlow(action) {
  try {
    const payload = yield call(addCompanyCustomBenefitApi, action.payload)
    yield put({ type: ADD_COMPANY_CUSTOM_BENEFIT_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: ADD_COMPANY_CUSTOM_BENEFIT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function addCompanyCustomBenefitApi({ input }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId, adminId } = getFromLS('clientData')
  const ADD_COMPANY_CUSTOM_BENEFIT_MUTATION = gql`
    mutation($input: CompanyCustomBenefitInput!) {
      addCompanyCustomBenefit(input: $input)
    }
  `
  const query = print(ADD_COMPANY_CUSTOM_BENEFIT_MUTATION)
  const variables = { input: { ...input, adminId, companyId }}

  const response = await request.graphql(url, { query, variables })

  return response.data
}

function* createCompanyBenefitsFlow(action) {
  try {
    const payload = yield call(createCompanyBenefitsApi, action.payload)
    yield put({ type: CREATE_COMPANY_BENEFITS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: CREATE_COMPANY_BENEFITS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function createCompanyBenefitsApi({ input }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const CREATE_COMPANY_BENEFITS_MUTATION = gql`
    mutation($companyId: ID!) {
      createCompanyBenefits(companyId: $companyId) {
        expenseManagement {
          key
        }
        plastic {
          key
        }
        virtual {
          key
        }
      }
    }
  `
  const query = print(CREATE_COMPANY_BENEFITS_MUTATION)
  const variables = { companyId, input }
  const options = { headers: { 'Content-Type': 'application/json' }}

  const response = await request.graphql(url, { query, variables }, options)

  return response.data
}

function* deleteCompanyCustomBenefitFlow(action) {
  try {
    const payload = yield call(deleteCompanyCustomBenefitApi, action.payload)
    yield put({ type: DELETE_COMPANY_CUSTOM_BENEFIT_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: DELETE_COMPANY_CUSTOM_BENEFIT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function deleteCompanyCustomBenefitApi({ _id }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId, adminId } = getFromLS('clientData')
  const DELETE_COMPANY_CUSTOM_BENEFIT_MUTATION = gql`
    mutation($_id: ID!, $adminId: ID, $companyId: ID!) {
      deleteCompanyCustomBenefit(_id: $_id, adminId: $adminId, companyId: $companyId)
    }
  `
  const query = print(DELETE_COMPANY_CUSTOM_BENEFIT_MUTATION)
  const variables = { _id, adminId, companyId }

  const response = await request.graphql(url, { query, variables })

  return response.data
}

function* updateCompanyBenefitsFlow({ payload }) {
  try {
    const response = yield call(updateCompanyBenefitsApi, payload)
    yield put({ type: UPDATE_COMPANY_BENEFITS_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_BENEFITS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function updateCompanyBenefitsApi({ benefits }) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const UPDATE_COMPANY_BENEFITS_QUERY = gql`
    mutation($companyId: ID! $benefits: CompanyBenefitsCategoriesInput!) {
      updateCompanyBenefits(companyId: $companyId benefits: $benefits) {
        _id
      }
    }
  `
  const query = print(UPDATE_COMPANY_BENEFITS_QUERY)
  const variables = {
    benefits,
    companyId,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.updateCompanyBenefits
}

async function updateCompanyCustomBenefitApi({ input }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId, adminId } = getFromLS('clientData')
  const UPDATE_COMPANY_CUSTOM_BENEFIT_MUTATION = gql`
    mutation($input: CompanyFlashPointsInput!) {
      updateCompanyFlashPoints(input: $input)
    }
  `
  const query = print(UPDATE_COMPANY_CUSTOM_BENEFIT_MUTATION)
  const variables = { input: { adminId, companyId, customBenefits: input, type: 'customBenefits' }}

  const response = await request.graphql(url, { query, variables })

  return response.data
}

function* updateCompanyCustomBenefitFlow(action) {
  try {
    const payload = yield call(updateCompanyCustomBenefitApi, action.payload)
    yield put({ type: UPDATE_COMPANY_CUSTOM_BENEFIT_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_CUSTOM_BENEFIT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(ADD_COMPANY_CUSTOM_BENEFIT_REQUESTING, addCompanyCustomBenefitFlow),
    takeLatest(CREATE_COMPANY_BENEFITS_REQUESTING, createCompanyBenefitsFlow),
    takeLatest(DELETE_COMPANY_CUSTOM_BENEFIT_REQUESTING, deleteCompanyCustomBenefitFlow),
    takeLatest(UPDATE_COMPANY_BENEFITS_REQUESTING, updateCompanyBenefitsFlow),
    takeLatest(UPDATE_COMPANY_CUSTOM_BENEFIT_REQUESTING, updateCompanyCustomBenefitFlow),
  ])
}

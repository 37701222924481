export const CANCEL_ORDER_REQUESTING = 'CANCEL_ORDER_REQUESTING'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR'

export const CONFIRM_DELIVERED_ORDER_REQUESTING = 'CONFIRM_DELIVERED_ORDER_REQUESTING'
export const CONFIRM_DELIVERED_ORDER_SUCCESS = 'CONFIRM_DELIVERED_ORDER_SUCCESS'
export const CONFIRM_DELIVERED_ORDER_ERROR = 'CONFIRM_DELIVERED_ORDER_ERROR'

export const CREATE_ORDER_REQUESTING = 'CREATE_ORDER_REQUESTING'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_ERROR = 'CREATE_ORDER_ERROR'

export const GET_CARDS_REQUESTING = 'GET_CARDS_REQUESTING'
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS'
export const GET_CARDS_ERROR = 'GET_CARDS_ERROR'

export const GET_CARDS_BIG_NUMBERS_REQUESTING = 'GET_CARDS_BIG_NUMBERS_REQUESTING'
export const GET_CARDS_BIG_NUMBERS_SUCCESS = 'GET_CARDS_BIG_NUMBERS_SUCCESS'
export const GET_CARDS_BIG_NUMBERS_ERROR = 'GET_CARDS_BIG_NUMBERS_ERROR'

export const GET_ORDER_DETAILS_REQUESTING = 'GET_ORDER_DETAILS_REQUESTING'
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS'
export const GET_ORDER_DETAILS_ERROR = 'GET_ORDER_DETAILS_ERROR'

export const GET_ORDER_DELIVERIES_REQUESTING = 'GET_ORDER_DELIVERIES_REQUESTING'
export const GET_ORDER_DELIVERIES_SUCCESS = 'GET_ORDER_DELIVERIES_SUCCESS'
export const GET_ORDER_DELIVERIES_ERROR = 'GET_ORDER_DELIVERIES_ERROR'

export const GET_NEW_ORDERS_REQUESTING = 'GET_NEW_ORDERS_REQUESTING'
export const GET_NEW_ORDERS_SUCCESS = 'GET_NEW_ORDERS_SUCCESS'
export const GET_NEW_ORDERS_ERROR = 'GET_NEW_ORDERS_ERROR'

export const NOTIFY_ORDER_NOT_RECEIVED_REQUESTING = 'NOTIFY_ORDER_NOT_RECEIVED_REQUESTING'
export const NOTIFY_ORDER_NOT_RECEIVED_SUCCESS = 'NOTIFY_ORDER_NOT_RECEIVED_SUCCESS'
export const NOTIFY_ORDER_NOT_RECEIVED_ERROR = 'NOTIFY_ORDER_NOT_RECEIVED_ERROR'

export const POPULATE_ORDER_REQUESTING = 'POPULATE_ORDERS_REQUESTING'
export const POPULATE_ORDER_SUCCESS = 'POPULATE_ORDERS_SUCCESS'
export const POPULATE_ORDER_ERROR = 'POPULATE_ORDERS_ERROR'

export const GET_CARD_ORDER_CREATION_FORM_DATA_REQUESTING = 'GET_CARD_ORDER_CREATION_FORM_DATA_REQUESTING'
export const GET_CARD_ORDER_CREATION_FORM_DATA_SUCCESS = 'GET_CARD_ORDER_CREATION_FORM_DATA_SUCCESS'
export const GET_CARD_ORDER_CREATION_FORM_DATA_ERROR = 'GET_CARD_ORDER_CREATION_FORM_DATA_ERROR'

export const SEND_REPORT_SUB_ORDERS_REQUESTING = 'SEND_REPORT_SUB_ORDERS_REQUESTING'
export const SEND_REPORT_SUB_ORDERS_SUCCESS = 'SEND_REPORT_SUB_ORDERS_SUCCESS'
export const SEND_REPORT_SUB_ORDERS_ERROR = 'SEND_REPORT_SUB_ORDERS_ERROR'

export const SELECT_GROUP_CARD_MODEL_REQUESTING = 'SELECT_GROUP_CARD_MODEL_REQUESTING'
export const SELECT_GROUP_CARD_MODEL_SUCCESS = 'SELECT_GROUP_CARD_MODEL_SUCCESS'
export const SELECT_GROUP_CARD_MODEL_ERROR = 'SELECT_GROUP_CARD_MODEL_ERROR'
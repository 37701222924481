export { handleApiError, request } from './request'

const URL = process.env.URL || 'dev'

const endpointMap = {
  dev: 'https://dev.api.flashapp.com.br/c',
  featureBranch: 'https://dev.api.flashapp.com.br/c',
  production: 'https://api.flashapp.com.br/c',
}

const url = endpointMap[URL] || endpointMap.dev

export const urlMap = {
  // company: 'http://localhost:4949',
  // person: 'http://localhost:3000',
  company: `${url}`,
  person: `${
    ['featureBranch'].includes(URL) ? endpointMap.dev : endpointMap[URL]
  }/p`,
}

const RECAPTCHA_MAP = {
  dev: '6LeiPf0ZAAAAAJ3nPGBz9tG0uSxLiaqQjwYfHXTd',
  featureBranch: '6LeiPf0ZAAAAAJ3nPGBz9tG0uSxLiaqQjwYfHXTd',
  production: '6Le21PsZAAAAAEldOn7i8oQCKASLQhoy5QsOk_Xu',
}

export const RECAPTCHA_TOKEN = RECAPTCHA_MAP[URL]

import {
  GET_ADMIN_ERROR,
  GET_ADMIN_REQUESTING,
  GET_ADMIN_SUCCESS,

  GET_EMPLOYEE_QTY_ERROR,
  GET_EMPLOYEE_QTY_REQUESTING,
  GET_EMPLOYEE_QTY_SUCCESS,
} from './constants'

const initState = {
  admin: null,
  employeeQuantities: null
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case GET_ADMIN_REQUESTING:
      return {
        ...state,
        getAdminRequesting: true,
        getAdminSuccess: false,
        getAdminError: false,
      }
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        admin: payload[0],
        getAdminRequesting: false,
        getAdminSuccess: true,
        getAdminError: false,
      }
    case GET_ADMIN_ERROR:
      return {
        ...state,
        getAdminRequesting: false,
        getAdminSuccess: false,
        getAdminError: payload.error,
      }

    case GET_EMPLOYEE_QTY_REQUESTING:
      return {
        ...state,
        employeeQtyRequesting: true,
        employeeQtySuccess: false,
        employeeQtyError: false,
      }
    case GET_EMPLOYEE_QTY_SUCCESS:
      return {
        ...state,
        employeeQtyRequesting: false,
        employeeQtySuccess: true,
        employeeQtyError: false,
        employeeQuantities: payload.data.getEmployeeQtyMultipleCompanies
      }
    case GET_EMPLOYEE_QTY_ERROR:
      return {
        ...state,
        employeeQtyRequesting: false,
        employeeQtySuccess: false,
        employeeQtyError: payload.error,
      }
    default:
      return state
  }
}

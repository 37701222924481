import { urls } from '@flash-tecnologia/flamingo-ui/src/config/env'
import * as Sentry from '@sentry/react'
import { Layout } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Footer from '../../components/footer'
import BenefitDiscovery from '../BenefitDiscovery/components'
import Topbar from '../Topbar/Topbar'
import AppRouter from './AppRouter'

import BackgroundProcessLoading from '../../components/Molecules/background-process-loading/BackgroundProcessLoading'
import { BannerProvider } from '../../components/contexts/Banner'
import { getFromLS } from '../../services/security'
import { handleWindowResize } from './redux/actions'

const NODE_ENV = process.env.NODE_ENV
const EXTRA_FOOTER_PATHS = ['/dashboard/company/benefits', '/dashboard/company/employees']

const { Content } = Layout
export class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: window.innerWidth > 426,
    }
  }

  componentDidCatch(error, info) {
    if (NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setExtras(info)
        scope.setLevel('fatal')
        Sentry.captureException(error)
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.props.handleWindowResize(window.innerWidth <= 425)
  }

  handleMenuClick = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }


  renderBanner = () => {
    const url = window.location.href
    if (url.includes('company/employees')) return <BannerProvider componentKey={'employees'} />
    if (url.includes('deposits')) return <BannerProvider componentKey={'deposits'} />
    if (url.includes('benefits')) return <BannerProvider componentKey={'benefits'} />
    if (url.includes('payments')) return <BannerProvider componentKey={'billets'} />
    if (url.includes('api')) return <BannerProvider componentKey={'api'} />
    if (url.includes('balance')) return <BannerProvider componentKey={'balance'} />
    if (url.includes('reports')) return <BannerProvider componentKey={'reports'} />
    if (url.includes('cards')) return <BannerProvider componentKey={'cards'} />
  }

  render() {
    const isHROSenv = !!Number(process.env.HROS)

    const {
      match: { url },
      location: { pathname },
      appReducer: { isMobile },
    } = this.props
    const { isMenuOpen } = this.state
    const { type } = getFromLS('clientData') || {}

    return (
      <div style={localStyles.baseDiv}>
        {isMobile && (
          <div style={localStyles.menuDiv}>
            <img
              style={localStyles.menuIcon}
              onClick={this.handleMenuClick}
              src={`${urls.s3Images}/icon-menu-negative.png`}
              alt={'menu icon'}
            />
          </div>
        )}

        {!isHROSenv && (
          <Topbar
            pathname={pathname}
            isMenuOpen={isMenuOpen}
            toggleDrawer={this.handleMenuClick}
          />
        )}
        <BannerProvider componentKey={'global'} />
        {this.renderBanner()}
        {isHROSenv && (
          <>
            <BackgroundProcessLoading />
          </>
        )}
        <Content className="isomorphicContent" style={localStyles.content(type, isMobile)}>
          <AppRouter url={url} />
        </Content>
        {EXTRA_FOOTER_PATHS.includes(pathname) && <div style={localStyles.extraFooter} />}
        <BenefitDiscovery />
        <Footer />
      </div>
    )
  }
}

const localStyles = {
  baseDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#fff',
  },
  menuDiv: {
    position: 'fixed',
    top: '13px',
    right: '25px',
    zIndex: 1,
  },
  menuIcon: { filter: 'invert(100%)' },
  content: (type, isMobile) => ({
    maxHeight: 'unset',
    width: type === 'user' && !isMobile ? '90%' : '100%',
    marginLeft: type === 'user' && !isMobile ? '10%' : '0',
    flexShrink: '0',
    background: '#fff',
  }),
  extraFooter: {
    height: '76px',
    width: '100%',
  },
}

const mapStateToProps = ({ appReducer }) => ({
  appReducer,
})

const mapDispatchToProps = {
  handleWindowResize,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
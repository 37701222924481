export const cardsSubOrderTemplate = {
  name: 'company-cards-order-template-V1.xlsx',
  version: 1,
  headerLine: 3,
  skipSheetLines: 4,
}

export const cardsSubOrderHeaderMap = {
  'QUANTIDADE DE CARTÕES*': 'flexAmount',
  'ENDEREÇO*': 'deliveryAddress.address',
  'NÚMERO*':'deliveryAddress.addressNumber',
  'COMPLEMENTO':'deliveryAddress.complement',
  'BAIRRO*': 'deliveryAddress.neighborhood',
  'CEP*': 'deliveryAddress.zipCode',
  'CIDADE*': 'deliveryAddress.city',
  'ESTADO*': 'deliveryAddress.state',
  'PONTO DE REFERÊNCIA': 'deliveryAddress.referencePoint',
  'DESTINATÁRIO*': 'recipientName',
  'E-MAIL DO DESTINATÁRIO*': 'recipientEmail',
  'TELEFONE DO DESTINATÁRIO  (PREFERENCIALMENTE WHATSAPP)': 'recipientPhone',
}

export const cardsSubOrderObservationsRow = {
  'QUANTIDADE DE CARTÕES*': 'Campo obrigatório',
  'ENDEREÇO*': 'Endereço completo de entrega                           (sem o número e complemento)',
  'NÚMERO*': 'O número do local. Caso não tenha, colocar S/N',
  'COMPLEMENTO': 'Algo que identifique unicamente o local de entrega',
  'BAIRRO*': 'Campo obrigatório',
  'CEP*': 'Campo obrigatório',
  'CIDADE*': 'Campo obrigatório',
  'ESTADO*': 'Campo obrigatório\nSigla do Estado',
  'PONTO DE REFERÊNCIA': 'Esta é alguma direção para auxiliar no momento da entrega.',
  'DESTINATÁRIO*': 'Campo obrigatório',
  'E-MAIL DO DESTINATÁRIO*': 'Quem iremos contatar no caso de problemas com o endereço informado.',
  'TELEFONE DO DESTINATÁRIO  (PREFERENCIALMENTE WHATSAPP)': 'Quem iremos contatar no caso de problemas com o endereço informado.',
}

export const companyPaymentTemplate = {
  name: 'company-payment-template-V4.xls',
  version: 4,
  headerLine: 5,
  skipSheetLines: 7,
}

export const companyVirtualBenefitsPaymentTemplate = {
  name: 'company-virtual-benefits-payment-template-V1.xlsx',
  version: 4,
  headerLine: 5,
  skipSheetLines: 7,
}

export const paymentHeaderMap = {
  'NOME COMPLETO*': 'name',
  'CPF*': 'cpf',
  'TIPO DE CARTÃO*': 'benefitType',
  'VALOR A SER DEPOSITADO*': 'value',
  'DESCRIÇÃO DO DEPÓSITO*': 'description',
}

export const virtualBenefitsPaymentHeaderMap = {
  'NOME COMPLETO*': 'name',
  'CPF*': 'cpf',
  'VALOR A SER DEPOSITADO*': 'value',
}

export const companyAddEmployeeTemplate = {
  name: 'company-employees-registration-template-V3.xlsx',
  version: 3,
  headerLine: 3,
  skipSheetLines: 5,
}

export const companyAddPaymentTemplate = {
  name: 'company-add-payment-template-V5.xlsx',
  version: 5,
  headerLine: 1,
  skipSheetLines: 2,
}

export const addEmployeeHeaderMap = {
  'NOME COMPLETO': 'name',
  'CPF': 'cpf',
  'E-MAIL': 'email',
  'TELEFONE CELULAR': 'phone',
  'GRUPO DE TRABALHO (OPCIONAL)': 'group',
  'CONFIGURAÇÃO DO CARTÃO': 'cardType',
  'REFEIÇÃO (R$)': 'meal',
  'ALIMENTAÇÃO (R$)': 'grocery',
  'TRANSPORTE (R$)': 'transport',
  'MOBILIDADE (R$)': 'mobility',
  'SAÚDE (R$)': 'health',
  'BEM-ESTAR (R$)': 'wellbeing',
  'EDUCAÇÃO (R$)': 'education',
  'CULTURA & ENTRETENIMENTO (R$)': 'culture',
  'PREMIAÇÃO (R$)': 'awards',
}

export const addPaymentHeaderMap = {
  'CNPJ': 'cnpj',
  'NOME COMPLETO': 'name',
  'CPF': 'cpf',
  'IDENTIFICACAO ADICIONAL': 'externalId',
  'E-MAIL': 'email',
  'TELEFONE CELULAR': 'phone',
  'GRUPO DE TRABALHO (OPCIONAL)': 'group',
  'CONFIGURACAO DO CARTAO': 'cardType',
  'POLITICA': 'policy',
  'REFEICAO (R$)': 'meal',
  'GASTO EXCLUSIVO REFEICAO (R$)': 'exclusiveMeal',
  'ALIMENTACAO (R$)': 'grocery',
  'GASTO EXCLUSIVO ALIMENTACAO (R$)': 'exclusiveGrocery',
  'REFEICAO E ALIMENTACAO (R$)': 'mealGrocery',
  'GASTO EXCLUSIVO REFEICAO E ALIMENTACAO (R$)': 'exclusiveMealGrocery',
  'VALE-TRANSPORTE (R$)': 'transport',
  'MOBILIDADE (R$)': 'mobility',
  'GASTO EXCLUSIVO MOBILIDADE (R$)': 'exclusiveMobility',
  'SAUDE (R$)': 'health',
  'BEM-ESTAR (R$)': 'wellbeing',
  'EDUCACAO (R$)': 'education',
  'CULTURA E ENTRETENIMENTO (R$)': 'culture',
  'PREMIACAO (R$)': 'awards',
  'PREMIACAO VIRTUAL (R$)': 'virtualAwards',
  'AUXILIO HOME OFFICE (R$)': 'virtualHomeOffice',
  'TAGS': 'tags'
}

export const companyDeleteEmployeeSheetTemplate = {
  name: 'company-employee-delete-template-V1.xlsx',
  version: 1,
  headerLine: 1,
  skipSheetLines: 1,
}

export const deleteEmployeeSheetHeaderMap = {
  'CNPJ': 'cnpj',
  'NOME COMPLETO': 'name',
  'CPF': 'cpf'
}

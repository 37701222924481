import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  GET_ADMIN_ERROR,
  GET_ADMIN_REQUESTING,
  GET_ADMIN_SUCCESS,
  
  GET_EMPLOYEE_QTY_ERROR,
  GET_EMPLOYEE_QTY_REQUESTING,
  GET_EMPLOYEE_QTY_SUCCESS,
} from './constants'


function* getAdminFlow({ payload }) {
  try {
    const response = yield call(getAdminApi, payload)
    yield put({ type: GET_ADMIN_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: GET_ADMIN_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getAdminApi() {
  const { adminId, companyId } = getFromLS('clientData')

  const GET_ADMIN_QUERY = gql`
    query($companyId: ID! $query: CompanyAdminQuery) {
      companyAdmins(companyId: $companyId query: $query) {
        lastAccesses {
          companyId
          accessedAt
        }
      }
    }`
  const query = print(GET_ADMIN_QUERY)
  const variables = {
    query: { adminId },
    companyId,
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)
  return response.data.companyAdmins
}

function* getEmployeeQtyFlow({ payload }) {
  try {
    const response = yield call(getEmployeeQtyApi, payload)
    yield put({ type: GET_EMPLOYEE_QTY_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: GET_EMPLOYEE_QTY_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getEmployeeQtyApi(payload) {

  const { companyIds } = payload

  const GET_EMPLOYEE_QTY_QUERY = gql`
    query($companyIds: [ID]) {
      getEmployeeQtyMultipleCompanies( companyIds: $companyIds ) {
        _id
        employeeQty
      }
    }`
  const query = print(GET_EMPLOYEE_QTY_QUERY)
  const variables = {
    companyIds,
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)
  return response
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_ADMIN_REQUESTING, getAdminFlow),
    takeLatest(GET_EMPLOYEE_QTY_REQUESTING, getEmployeeQtyFlow),
  ])
}

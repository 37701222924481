import {
  GET_S3_SIGNED_URL_REQUESTING,
  RESIZE,
} from './constants'

export function handleWindowResize(payload) {
  return {
    type: RESIZE,
    payload
  }
}

export function getS3SignedUrl({ bucket, path, expiration }) {
  return {
    type: GET_S3_SIGNED_URL_REQUESTING,
    payload: {
      bucket,
      path,
      expiration,
    }
  }
}
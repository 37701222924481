import { ApolloProvider } from '@apollo/react-hooks'
import {
  CaptureConsole as CaptureConsoleIntegration,
} from '@sentry/integrations'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore, { history } from './redux/store'
import PublicRoutes from './router.jsx'
import makeApolloClient from './services/utils/apollo'
const store = configureStore()

import { FlagProvider, UnleashClient } from '@unleash/proxy-client-react'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'antd/dist/antd.css'
import './containers/App/global.css'

const isProdEnv = process.env.NODE_ENV === 'production'
const isHROSenv = !!Number(process.env.HROS)

const { PROXY_KEY, URL } = {
  PROXY_KEY: process.env.UNLEASH_PROXY_KEY,
  URL: process.env.UNLEASH_PROXY_URL,
}

if (isProdEnv && !isHROSenv) {
  Sentry.init({
    dsn: 'https://09a5094f0e5c4c579556b3cb4fb50bf3@sentry.io/1802304',
    normalizeDepth: 10,
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === 'xhr') {
        const data = {
          status: hint.xhr.status,
          method: breadcrumb.data.method,
          requestBody: hint.xhr.__sentry_xhr_v2__.body,
          response: hint.xhr.response,
          responseUrl: hint.xhr.responseURL,
        }
        return { ...breadcrumb, data }
      }
      return breadcrumb
    },
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),    
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
      new CaptureConsoleIntegration({
        levels: ['error'],
      }),
    ],
  })
  // DO NOT REMOVE - this is for the chatbot injection
  const tagManagerArgs = {
    gtmId: 'GTM-WHWKZ6F',
  }
  TagManager.initialize(tagManagerArgs)
}

const apolloClient = makeApolloClient()

const unleashConfig = {
  url: URL,
  refreshInterval: 180,
  clientKey: PROXY_KEY,
  appName: 'web-front',
  environment: process.env.NODE_ENV,
}
const client = new UnleashClient(unleashConfig)

const DashApp = () => {
  useEffect(() => {
    isHROSenv && apolloClient.resetStore()
  }, [])

  client.on('error', (error) => {
    Sentry.withScope((scope) => {
      scope.setTag('unleashScope', 'error')
      Sentry.captureException(error)
    })
  })

  client.on('recovered', () => {
    Sentry.withScope((scope) => {
      scope.setTag('unleashScope', 'recovered')
      Sentry.captureMessage('unleash recovered')
    })
  })

  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <FlagProvider unleashClient={client}>
          <PublicRoutes history={history} />
        </FlagProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default DashApp

import React, { useEffect } from 'react'
import { useBanner } from '../../contexts/Banner'
import { Banner, BannerLink, CustomMainText } from './styles'
import { segmentTracking } from '../../../services/utils/segmentTrack'
import { getFromLS } from '../../../services/security'
import _get from 'lodash/get'

const BannerTemplate = () => {
  const {
    config,
    componentKey
  } = useBanner()

  const {
    tracking,
    height,
    width,
    background,
    mainTextColor,
    customMainText,
    link,
    linkTextColor,
    linkText,
    haveLink = true,
    haveImg = false,
    linkTracking,
    environment,
    pathToggleRule,
  } = config.content[componentKey]

  const clientData = getFromLS('clientData')

  useEffect(() => {
    if (tracking) {
      segmentTracking(tracking)
    }
  }, [tracking])

  const renderImg = () => {
    if (haveImg) {
      const {
        imgDescription,
        imgStyle = {},
        imgURL,
      } = config.content[componentKey]
      return (
        <img
          src={imgURL}
          alt={imgDescription}
          style={imgStyle}
        />
      )
    }
  }

  const renderContent = () => (
    <>
      <Banner
        height={height}
        background={background}
        width={width}
      >
        {renderImg()}
        <CustomMainText
          mainTextColor={mainTextColor}
        >
          {customMainText || 'Você sabia que a Flash tem uma FAQ que pode sanar suas duvidas?'}
        </CustomMainText>
        {haveLink &&
          <BannerLink
            onClick={() => linkTracking && segmentTracking(linkTracking)}
            href={link || 'https://beneficios.flashapp.com.br/faq'}
            target='_blank'
            rel=''
            linkTextColor={linkTextColor}
          >
            {linkText || 'CLIQUE AQUI'}
          </BannerLink>
        }
      </Banner>
    </>
  )

  const switchRender = () => {
    const isHros = !!Number(process.env.HROS)
    if (!_get(clientData, pathToggleRule, false)) return null
    if (environment) {
      if (environment === 'legacy' && !isHros) {
        return renderContent()
      }
      if (environment === 'hros' && isHros) {
        return renderContent()
      }
      return null
    }
    return renderContent()
  }

  return (switchRender())
}

export default BannerTemplate

import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  GET_S3_SIGNED_URL_REQUESTING,
  GET_S3_SIGNED_URL_SUCCESS,
  GET_S3_SIGNED_URL_ERROR,
} from './constants'


function* getS3SignedUrlFlow(action) {
  try {
    const payload = yield call(getS3SignedUrlApi, action.payload)
    yield put({ type: GET_S3_SIGNED_URL_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_S3_SIGNED_URL_ERROR,
      error: handleApiError(error),
    })
  }
}
async function getS3SignedUrlApi({ bucket, path, expiration = 30 }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const GET_S3_SIGNED_URL_QUERY = gql`
    query($companyId: ID!, $bucket: String!, $path: String!, $expiration: Int) {
      getS3SignedUrl(
        companyId: $companyId,
        bucket: $bucket,
        path: $path,
        expiration: $expiration,
      )
    }
  `
  const query = print(GET_S3_SIGNED_URL_QUERY)
  const variables = {
    companyId,
    bucket,
    path,
    expiration,
  }
  const response = await request.graphql(url, { query, variables })
  return response.data.getS3SignedUrl
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_S3_SIGNED_URL_REQUESTING, getS3SignedUrlFlow),
  ])
}
import {
  GET_BENEFIT_DISCOVERY_LIST_SUCCESS,
  CREATE_BENEFIT_DISCOVERY_SUCCESS,
  UPDATE_BENEFIT_DISCOVERY,
  GET_BENEFIT_DISCOVERY_SUCCESS,
  ADD_BENEFIT_CATEGORY_SUCCESS,
  UPDATE_BENEFIT_DISCOVERY_SUCCESS,
  ACTIVE_MODAL_BENEFIT_DISCOVERY
} from './constants'

const initialState = {
  displayBenefitDiscovery: false,
  list: [],
  answered: {
    companyId: '',
    companyAdminId: '',
    groupId: '',
    benefitsCategoryId: [],
    quantityCollaborators: '',
    comment: '',
    displayUntil: '12',
  },
  displayModal: false,
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_BENEFIT_DISCOVERY_LIST_SUCCESS:
      return {
        ...state,
        list: payload
      }
    case CREATE_BENEFIT_DISCOVERY_SUCCESS:
      return {
        ...state,
        answered: payload,
        displayBenefitDiscovery: true,
        displayModal: true,
      }
    case UPDATE_BENEFIT_DISCOVERY:
      return {
        ...state,
        answered: {
          ...state.answered
        }
      }    
    case GET_BENEFIT_DISCOVERY_SUCCESS:
      return {
        ...state,
        displayModal: payload.displayModalBenefitDiscovery,
        displayBenefitDiscovery: payload.displayBenefitDiscovery,
        answered: payload.answered
      }
    case ADD_BENEFIT_CATEGORY_SUCCESS:
      return {
        ...state,
        answered: {
          ...state.answered,
          benefitsCategoryId: payload          
        }
      }
    case UPDATE_BENEFIT_DISCOVERY_SUCCESS:
      return {
        ...state,
        answered: {
          ...state.answered,
          isAnswered: payload.updateBenefitDiscovery.isAnswered          
        }
      }
    case ACTIVE_MODAL_BENEFIT_DISCOVERY:
      return {
        ...state,
        displayBenefitDiscovery: payload.displayBenefitDiscovery,
        displayModal: payload.displayModal,
      }
    default:
      return state
  }
}

import _get from 'lodash/get'
import _set from 'lodash/set'
import { getFromLS, setInLS } from '../../services/security'
import { deepClone } from '../../services/utils'
import {
  ADD_USER_ERROR,
  ADD_USER_REQUESTING,
  ADD_USER_SUCCESS,
  CHECK_CPF_ERROR,
  CHECK_CPF_REQUESTING,
  CHECK_CPF_SUCCESS,
  DELETE_USERS_ERROR,
  DELETE_USERS_REQUESTING,
  DELETE_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_REQUESTING,
  GET_USERS_SUCCESS,
  GET_USER_PERMISSIONS_ERROR,
  GET_USER_PERMISSIONS_REQUESTING,
  GET_USER_PERMISSIONS_SUCCESS,
  SEND_ADMIN_EMAIL_TOKEN_ERROR,
  SEND_ADMIN_EMAIL_TOKEN_REQUESTING,
  SEND_ADMIN_EMAIL_TOKEN_SUCCESS,
  UPDATE_USERS_PERMISSION_ERROR,
  UPDATE_USERS_PERMISSION_REQUESTING,
  UPDATE_USERS_PERMISSION_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_PERMISSIONS_ERROR,
  UPDATE_USER_PERMISSIONS_REQUESTING,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_REQUESTING,
  UPDATE_USER_SUCCESS,
  VALIDATE_ADMIN_TOKEN_ERROR,
  VALIDATE_ADMIN_TOKEN_REQUESTING,
  VALIDATE_ADMIN_TOKEN_SUCCESS,
} from './constants'
import { viewOnlyPermissions } from './permissionsHelper'

const initialState = {
  addUserRequesting: false,
  checkCPFRequesting: false,

  getUserPermissionsRequesting: false,
  getUserPermissionsError: '',

  requesting: false,
  removing: false,
  successful: false,
  successfulA: false,

  updatedUserDoc: {},
  updateUserError: '',
  updateUserRequesting: false,
  updateUserSuccess: false,

  updateUserPermissionsError: '',
  updateUserPermissionsRequesting: false,
  updateUserPermissionsSuccess: false,

  updateUsersPermissionError: '',
  updateUsersPermissionRequesting: false,
  updateUsersPermissionSuccess: false,

  data: [],
  stores: [],
  error: '',
  adminDoc: {},
  userPermissions: {},
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ADD_USER_REQUESTING:
      return {
        ...state,
        addUserRequesting: true,
        successful: false,
        error: '',
      }
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserRequesting: false,
        successful: true,
        error: '',
      }
    case ADD_USER_ERROR:
      return {
        ...state,
        addUserRequesting: false,
        successful: false,
        error: payload.error,
      }

    case CHECK_CPF_REQUESTING:
      return {
        ...state,
        checkCPFRequesting: true,
        successful: false,
        adminDoc: {},
        error: '',
      }
    case CHECK_CPF_SUCCESS:
      const [ companyAdmin ] = payload

      return {
        ...state,
        checkCPFRequesting: false,
        successful: true,
        adminDoc: companyAdmin,
        error: '',
      }
    case CHECK_CPF_ERROR:
      return {
        ...state,
        checkCPFRequesting: false,
        successful: false,
        adminDoc: {},
        error: payload.error,
      }

    case GET_USER_PERMISSIONS_REQUESTING:
      return {
        ...state,
        userPermissions: {},
        getUserPermissionsRequesting: true,
        successful: false,
      }
    case GET_USER_PERMISSIONS_SUCCESS:
      const userPermissions = deepClone(_get(payload, ['permissions', 'permissions'], {}))
      viewOnlyPermissions.forEach(key => _set(userPermissions, key, true))

      return {
        ...state,
        userPermissions,
        getUserPermissionsError: '',
        getUserPermissionsRequesting: false,
        successful: true,
      }
    case GET_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        userPermissions: {},
        getUserPermissionsError: payload.error,
        getUserPermissionsRequesting: false,
        successful: false,
      }

    case GET_USERS_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        data: [...payload.data],
        stores: payload.stores,
        error: '',
      }
    
    case SEND_ADMIN_EMAIL_TOKEN_ERROR:
      return {
        ...state,
        sendAdminTokenRequesting: false,
        error: payload.error,
        successful: false,     
      }

    case SEND_ADMIN_EMAIL_TOKEN_REQUESTING:
      return {
        ...state,
        sendAdminTokenRequesting: true,
        successful: false,
        error: ''
      }
    case SEND_ADMIN_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        sendAdminTokenRequesting: false,
        error: '',
        successful: true,
      }
    
    case UPDATE_USER_REQUESTING:
      return {
        ...state,
        updateUserRequesting: true,
        updateUserSuccess: false,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatedUserDoc: payload,
        updateUserError: '',
        updateUserRequesting: false,
        updateUserSuccess: true,
      }
    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserError: payload.error,
        updateUserRequesting: false,
        updateUserSuccess: false,
      }

    case UPDATE_USER_PERMISSIONS_REQUESTING:
      return {
        ...state,
        updateUserPermissionsRequesting: true,
        updateUserPermissionsSuccess: false,
      }
    case UPDATE_USER_PERMISSIONS_SUCCESS:
      const lsData = getFromLS('clientData')

      _set(lsData, ['permissions'], _get(payload, ['0', 'permissions'], {}))
      setInLS('clientData', lsData)

      return {
        ...state,
        updateUserPermissionsError: '',
        updateUserPermissionsRequesting: false,
        updateUserPermissionsSuccess: true,
      }
    case UPDATE_USER_PERMISSIONS_ERROR:
      return {
        ...state,
        updateUserPermissionsError: payload.error,
        updateUserPermissionsRequesting: false,
        updateUserPermissionsSuccess: false,
      }

    case UPDATE_USERS_PERMISSION_REQUESTING:
      return {
        ...state,
        updateUsersPermissionRequesting: true,
        updateUsersPermissionSuccess: false,
      }
    case UPDATE_USERS_PERMISSION_SUCCESS:
      return {
        ...state,
        updateUsersPermissionError: '',
        updateUsersPermissionRequesting: false,
        updateUsersPermissionSuccess: true,
      }
    case UPDATE_USERS_PERMISSION_ERROR:
      return {
        ...state,
        updateUsersPermissionError: payload.error,
        updateUsersPermissionRequesting: false,
        updateUsersPermissionSuccess: false,
      }

    case DELETE_USERS_REQUESTING:
      return {
        ...state,
        removing: true,
        successful: false,
      }
    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        removing: false,
        successful: true,
        error: '',
      }
    case DELETE_USERS_ERROR:
    case GET_USERS_ERROR:
      return {
        ...state,
        requesting: false,
        removing: false,
        successful: false,
        error: payload.error,
      }

      case VALIDATE_ADMIN_TOKEN_ERROR:
        return {
          ...state,
          userPermissions: {},
          validateAdminTokenError: payload.error,
          validateAdminTokenRequesting: false,
          successful: false,
        }
  
      case VALIDATE_ADMIN_TOKEN_REQUESTING:
        return {
          ...state,
          successful: false,
          validateAdminTokenRequesting: true,
        }
      case VALIDATE_ADMIN_TOKEN_SUCCESS:
        return {
          ...state,
          requesting: false,
          successful: true,
          tokenValid: payload,
          stores: payload.stores,
          error: '',
          validateAdminTokenRequesting: false,
        }

    default:
      return state
  }
}

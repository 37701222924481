import { createBrowserHistory } from 'history'
import { getFromLS, setInLS } from '../security'
import { request, urlMap } from '../api'

const logout = () => {
  const HROS = process.env.HROS

  const browserHistory = createBrowserHistory({ forceRefresh: true, ...(Number(HROS) && { basename: '/benefits' }) })
  setInLS('clientData', {})
  setInLS('authData', {})
  browserHistory.push('/')
}

const refreshToken = async () => {
  const auth = getFromLS('authData')

  const { data } = await request.post(
    `${urlMap.company}/company/refreshToken`,
    { auth },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )

  const refreshedAuth = data

  setInLS('authData', refreshedAuth)

  return refreshedAuth
}

export {
  logout,
  refreshToken,
}
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { blackColor, fontFamily, greyColor } from '../assets/css/baseTextStyles.css'
import { getFromLS } from '../services/security'

const HROS = process.env.HROS
const FooterLink = styled.a`
  align-items: center;
  color: ${blackColor}65;
  cursor: pointer;
  display: flex;
  font-family: ${fontFamily};
  font-size: 14px;
  justify-content: center;
  padding: 0 8px 0 8px;

  &:hover {
    color: ${blackColor};
    font-weight: bold;
  }

  @media (max-width: 426px) {
    color: ${greyColor};
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
  }
`

const Footer = () => {
  const { isMobile } = useSelector(({ appReducer }) => (appReducer))
  const { type } = getFromLS('clientData') || {}
  const isPerson = type === 'user'
  return (
    <>
      <div style={localStyles.content(isPerson, isMobile)}>
        <div style={localStyles.detailsDiv(isMobile, isPerson)}>
          <h4 style={localStyles.flashName}>Flash Tecnologia e Pagamentos LTDA</h4>
          <p style={localStyles.flashDetails}>CNPJ 32.223.020/0001-18 {isMobile ? <br /> : '|'} Registrado no PAT nº 190679531</p>
        </div>
        <div style={localStyles.termsDiv(isMobile, isPerson)}>
          <FooterLink
            target='_blank'
            rel='noopener noreferrer'
            href='https://images.flashapp.com.br/Contracts/flash_terms.pdf'
          >
            Termos de Uso
          </FooterLink>
          <FooterLink
            target='_blank'
            rel='noopener noreferrer'
            href='https://images.flashapp.com.br/Contracts/flash_privacy.pdf'
          >
            Política de Privacidade
          </FooterLink>
          <FooterLink
            target='_blank'
            rel='noopener noreferrer'
            href='https://beneficios.flashapp.com.br/faq/'
          >
            Me ajuda, Flash!
          </FooterLink>
        </div>
      </div>
    </>
  )
}

const localStyles = {
  content: (isPerson, isMobile) => ({
    alignItems: 'center',
    backgroundColor: 'white',
    borderTop: `1px solid ${greyColor}32`,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    width: '100%',
    flexDirection: isMobile ? 'column-reverse' : 'row',
    padding: isMobile ? '10px 0' : '10px 80px 10px 16px',	
    zIndex: isMobile ? 'initial' : '96',
    ...((isPerson && !isMobile) && {
      paddingLeft: '17%',
    })
  }),
  detailsDiv: (isMobile, isPerson) => ({
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    lineHeight: '15px',
    marginRight: isPerson ? '0' : '93px',
    ...(isMobile && {
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 20,
      textAlign: 'center',
    }),
    flex: '1 1 0',
  }),
  flashDetails: {
    color: `${blackColor}67`
  },
  flashName: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  termsDiv: isMobile => ({
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
    ...(Number(HROS) && {
      paddingRight: '4rem'
    }),
    ...(isMobile && {
      borderBottom: `1px solid ${greyColor}1A`,
      flexDirection: 'column',
      margin: '0 15px',
      padding: '0 0 10px 0',
      width: '100%',
    }),
  }),
}

export default memo(Footer)

import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from '../redux/reducers'
import rootSaga from '../redux/sagas'

const HROS = process.env.HROS

export const history = createBrowserHistory({ forceRefresh: true, ...(Number(HROS) && { basename: '/benefits' }) })
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const composeEnhancers = 
  process.env.NODE_ENV === 'development' ?
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) :
    compose

    
const middlewares = [thunk, sagaMiddleware, routeMiddleware]

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  )
  sagaMiddleware.run(rootSaga)

  return store
}

import React, { createContext, useContext } from 'react'
import BannerTemplate from '../Atoms/banner/BannerTemplate'
import { useVariant } from '@unleash/proxy-client-react'
import PropTypes from 'prop-types'


const BannerTemplateContext = createContext()

export const useBanner = () => {
  const context = useContext(BannerTemplateContext)

  if (!context) {
    throw new Error('useBanner must be used within a BannerProvider')
  }

  return context
}

const BannerProvider = ({ componentKey }) => {
  const bannerVariant = useVariant('Banner')

  const { payload } = bannerVariant
  const config = payload ? JSON.parse(payload.value) : { content: {}}

  const value = {
    config,
    componentKey
  }

  return (
    <>
      {(bannerVariant.enabled && config.content[componentKey]) &&
        <BannerTemplateContext.Provider value={{ ...value }}>
          <BannerTemplate />
        </BannerTemplateContext.Provider>
      }
    </>
  )
}

BannerProvider.propTypes = {
  /* Unleash referrer key */
  componentKey: PropTypes.string
}

export { BannerProvider }

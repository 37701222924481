export const GET_COMPANY_BENEFITS_REQUESTING = 'GET_COMPANY_BENEFITS_REQUESTING'
export const GET_COMPANY_BENEFITS_SUCCESS = 'GET_COMPANY_BENEFITS_SUCCESS'
export const GET_COMPANY_BENEFITS_ERROR = 'GET_COMPANY_BENEFITS_ERROR'
export const GET_COMPANY_DEPOSITS_REQUESTING = 'GET_COMPANY_DEPOSITS_REQUESTING'
export const GET_COMPANY_DEPOSITS_SUCCESS = 'GET_COMPANY_DEPOSITS_SUCCESS'
export const GET_COMPANY_DEPOSITS_ERROR = 'GET_COMPANY_DEPOSITS_ERROR'
export const SEND_DEPOSITS_REPORT_REQUESTING = 'SEND_DEPOSITS_REPORT_REQUESTING'
export const SEND_DEPOSITS_REPORT_SUCCESS = 'SEND_DEPOSITS_REPORT_SUCCESS'
export const SEND_DEPOSITS_REPORT_ERROR = 'SEND_DEPOSITS_REPORT_ERROR'
export const UPDATE_DEPOSIT_REQUESTING = 'UPDATE_DEPOSIT_REQUESTING'
export const UPDATE_DEPOSIT_SUCCESS = 'UPDATE_DEPOSIT_SUCCESS'
export const UPDATE_DEPOSIT_ERROR = 'UPDATE_DEPOSIT_ERROR'

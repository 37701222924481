import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  CREATE_FILE_SUPPORT_REQUESTING,
  CREATE_FILE_SUPPORT_SUCCESS,
  CREATE_FILE_SUPPORT_ERROR,
} from './constants'
import { urlMap, request, handleApiError } from '../../services/api'
import gql from 'graphql-tag'
import { print } from 'graphql'
import { getFromLS } from '../../services/security'


function* createFileSupportFlow({ payload }) {
  try {
    yield call(createFileSupport, payload)
    yield put({ type: CREATE_FILE_SUPPORT_SUCCESS })
  } catch (error) {
    yield put({
      type: CREATE_FILE_SUPPORT_ERROR,
      payload: { error: handleApiError(error) }
    })
  }
}

async function createFileSupport({
  fileDescription,
  fileType,
  fileName,
  fileUri,
}) {

  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const CREATE_FILE_SUPPORT_QUERY = gql`
    mutation(
      $companyId: ID!
      $createdByCompanyAdminId: ID!
      $fileDescription: String!
      $file: FileInput!
      $fileType: String!
    ) {
      createFileSupport(
        companyId: $companyId
        createdByCompanyAdminId: $createdByCompanyAdminId
        fileDescription: $fileDescription 
        file: $file 
        fileType: $fileType 
      ) {
        _id
        message
      }
    }
  `
  const query = print(CREATE_FILE_SUPPORT_QUERY)
  const variables = {
    companyId,
    createdByCompanyAdminId: adminId,
    fileDescription,
    fileType,
    file: {
      name: fileName,
      uri: fileUri,
    }
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.createFileSupport
}

export default function* rootSaga() {
  yield all([
    takeLatest(CREATE_FILE_SUPPORT_REQUESTING, createFileSupportFlow),
  ])
}
export const listQuantityCollaborators = [
  {
    label: '1 a 5% dos colaboradores', 
    value: '1 a 5%',
  },
  {
    label: '5 a 10% dos colaboradores', 
    value: '5 a 10%',
  },
  {
    label: '10 a 25% dos colaboradores', 
    value: '10 a 25%',
  },
  {
    label: '25 a 50% dos colaboradores', 
    value: '25 a 50%',
  },
  {
    label: 'mais de 50% dos colaboradores. Quantos?', 
    value: 'mais de 50%',
  },
]

import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { cloneDeep } from 'lodash'
import { toCurrencyString } from '@flash-tecnologia/flamingo-ui/src/utils'
import {
  GET_BENEFIT_DISCOVERY_LIST,
  GET_BENEFIT_DISCOVERY_LIST_SUCCESS,
  GET_BENEFIT_DISCOVERY_LIST_ERROR,
  GET_BENEFIT_DISCOVERY,
  GET_BENEFIT_DISCOVERY_SUCCESS,
  GET_BENEFIT_DISCOVERY_ERROR,
  CREATE_BENEFIT_DISCOVERY,
  CREATE_BENEFIT_DISCOVERY_SUCCESS,
  CREATE_BENEFIT_DISCOVERY_ERROR,
  UPDATE_BENEFIT_DISCOVERY_SUCCESS,
  UPDATE_BENEFIT_DISCOVERY_ERROR,
  UPDATE_BENEFIT_DISCOVERY,
  ADD_BENEFIT_CATEGORY,
  ADD_BENEFIT_CATEGORY_SUCCESS,
  ADD_BENEFIT_CATEGORY_ERROR,
  ACTIVE_MODAL_BENEFIT_DISCOVERY,
  ACTIVE_MODAL_BENEFIT_DISCOVERY_REQUESTING,
  ACTIVE_MODAL_BENEFIT_DISCOVERY_ERROR,
  ADD_QUANTITY_COLLABORATORS,  
} from './constants'
import { urlMap, request, handleApiError } from '../../services/api'
import gql from 'graphql-tag'
import { print } from 'graphql'
import { getFromLS } from '../../services/security'
import moment from 'moment-timezone'
import { segmentTracking } from '../../services/utils/segmentTrack'

const data = getFromLS('clientData')
const companyId = data?.companyId
const adminId = data?.adminId
const groupId = data?.groupId
const benefitDiscoveryEnabled = data?.featureConfig?.benefitDiscoveryEnabled

const url = `${urlMap.company}/company/graphql`
const displayUntil = '12'

const shouldDisplayBenefitDiscovery = (dateToDisplay, isAnswered, hasDepositCount) => {
  let response = { displayBenefitDiscovery: false, displayModalBenefitDiscovery: false }
  if (!benefitDiscoveryEnabled) return response
  const today = moment(new Date()).format('YYYY-MM-DD')
  const isDateSameOrBeforeToday = moment(dateToDisplay).isSameOrBefore(today)
  const hasDisplayedModal = localStorage.getItem('displayModal')  
  
  if (hasDepositCount === 0 || !isDateSameOrBeforeToday) return response
  if (isDateSameOrBeforeToday && isAnswered) return false
  if (isDateSameOrBeforeToday) {
    if (!isAnswered) response.displayBenefitDiscovery = true
    if (hasDisplayedModal === 'true') {
      response.displayModalBenefitDiscovery = false 
    } else {
      response.displayModalBenefitDiscovery = true
    }
  }
  return response
}

function* getBenefitDiscoveryListFlow() {
  try {
    const data = yield call(getBenefitDiscoveryList)
    yield put({ type: GET_BENEFIT_DISCOVERY_LIST_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_BENEFIT_DISCOVERY_LIST_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getBenefitDiscoveryList() {
  const GET_BENEFIT_DISCOVERY_LIST_QUERY = gql`
    query {
      benefitDiscoveryList {
        _id
        label
        value
        description
        display
        displayQuantityCollaborators
      }
    }
  `
  const query = print(GET_BENEFIT_DISCOVERY_LIST_QUERY)
  const body = { query }
  const response = await request.graphql(url, body)
  const { data: { benefitDiscoveryList }} = response
  const filteredList = benefitDiscoveryList.filter(i => i.value !== 'outros')
  const getOutros = benefitDiscoveryList.find(i => i.value === 'outros')  
  if (getOutros) filteredList.push(getOutros)

  return filteredList
}

function* createBenefitDiscoveryFlow() {
  if (!benefitDiscoveryEnabled) return 
  try {
    const data = yield call(createBenefitDiscovery)
    yield put({ type: CREATE_BENEFIT_DISCOVERY_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CREATE_BENEFIT_DISCOVERY_ERROR, payload: { error: handleApiError(error) }})
  }
}
async function createBenefitDiscovery() {
  const CREATE_BENEFIT_DISCOVERY_QUERY = gql`
    mutation (
      $companyId: ID!, 
      $companyAdminId: ID!, 
      $groupId: String!,
      $benefitsCategoryId: [BenefitDiscoveryListInput],
      $comment: String,
      $displayUntil: String!
    ) {
      addBenefitDiscovery (
        companyId: $companyId,
        companyAdminId: $companyAdminId,
        groupId: $groupId,
        benefitsCategoryId: $benefitsCategoryId,
        comment: $comment,
        displayUntil: $displayUntil
      ){
        _id,
        company {
          _id
        },
        companyAdmin {
          _id
        },
        benefitsCategoryId {
          label
        }
        displayAt
        displayUntil
      }
    }
  `
  const query = print(CREATE_BENEFIT_DISCOVERY_QUERY)
  const variables = {
    companyId,
    companyAdminId: adminId,
    groupId,
    displayUntil
  }
  const { data } = await request.graphql(url, { query, variables })
  data.addBenefitDiscovery.displayBenefitDiscovery = true
  data.addBenefitDiscovery.displayModal = true

  return data.addBenefitDiscovery
}
function* updateBenefitDiscoveryFlow({ payload }) {
  const { benefitDiscovery } = yield select()
  const { answered: { benefitsCategoryId }, answered } = benefitDiscovery
  const { othersBenefits, quantityCollaborators } = payload

  const answeredListFiltered = benefitsCategoryId.reduce((prev, item) => { 
    return `${item.label}; ${prev}`
  },'')
  segmentTracking('Portal_BenefitsDiscovery_SurveyModal_Next', { answer: answeredListFiltered })  
  if (othersBenefits) {
    const answeredWithOutrosLabel = benefitsCategoryId.map(i => {
      if (i.value === 'outros') return { ...i, label: othersBenefits }      
      return i
    })
    answered.benefitsCategoryId = answeredWithOutrosLabel
  }
  if (quantityCollaborators) {
    answered.benefitsCategoryId.map(i => {
      if (i.quantityCollaborators) i.quantityCollaborators = `${i.quantityCollaborators} - ${toCurrencyString(Number(quantityCollaborators) / 100, true)}%`
    })
  }
  try {
    const data = yield call(updateBenefitDiscovery, answered)
    yield put({ type: UPDATE_BENEFIT_DISCOVERY_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: UPDATE_BENEFIT_DISCOVERY_ERROR, payload: { error: handleApiError(error) }})
  }
}
async function updateBenefitDiscovery(payload) {
  const UPDATE_BENEFIT_DISCOVERY_QUERY = gql`  
    mutation (
      $_id: ID!,
      $benefitsCategoryId: [BenefitDiscoveryListInput]!,
      $displayUntil: String!,
      $isAnswered: Boolean!
    ) {
      updateBenefitDiscovery (
        _id: $_id,
        benefitsCategoryId: $benefitsCategoryId,
        displayUntil: $displayUntil,
        isAnswered: $isAnswered
      ){
        company {
          _id
        },
        companyAdmin {
          _id
        },
        isAnswered,
        displayAt,
        benefitsCategoryId {
          label
        }
      }
    }    
  `
  const query = print(UPDATE_BENEFIT_DISCOVERY_QUERY)
  const organized = payload.benefitsCategoryId.map((i) => {
    delete i.display
    return i
  })

  const variables = {
    _id: payload._id,
    benefitsCategoryId: organized,
    displayUntil: payload.displayUntil,
    isAnswered: true
  }

  const response = await request.graphql(url, { query, variables })
  return response.data
}

function* getBenefitDiscoveryFlow() {
  const { getCompanyDeposits } = yield select()
  const { depositsData: { totalDepositsCount }} = getCompanyDeposits
  
  try {
    const data = yield call(getBenefitDiscovery, totalDepositsCount)
    if (!data) return yield call(createBenefitDiscoveryFlow)
    yield put({ type: GET_BENEFIT_DISCOVERY_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_BENEFIT_DISCOVERY_ERROR, payload: { error: handleApiError(error) }})
  }
}
async function getBenefitDiscovery(hasDepositCount) {
  const GET_BENEFIT_DISCOVERY_QUERY = gql`
    query ($groupId: String) {
      benefitDiscovery(groupId: $groupId) {
        benefitsCategoryId {
          benefitListId
          label
          value
          description
        }
        _id
        comment
        displayAt
        displayUntil
        isAnswered
      }
    }
  `
  const query = print(GET_BENEFIT_DISCOVERY_QUERY)
  const variables = { groupId }
  const response = await request.graphql(url, { query, variables })

  if (!response.data.benefitDiscovery) return false

  const { isAnswered, displayAt } = response.data.benefitDiscovery
  const displayAtFormated = moment(displayAt).format('YYYY-MM-DD')
  const displayComponents  = shouldDisplayBenefitDiscovery(
    displayAtFormated,
    isAnswered,
    hasDepositCount
  )

  if (!displayComponents) return false

  const { displayBenefitDiscovery, displayModalBenefitDiscovery } = displayComponents
  return {
    displayBenefitDiscovery,
    displayModalBenefitDiscovery,
    answered: response.data.benefitDiscovery
  }
}

function* addBenefitCategoryFlow(payload) {
  const { benefitDiscovery } = yield select()
  const { answered: { benefitsCategoryId }} = benefitDiscovery
  const newPayload = {
    ...payload.payload,
    benefitListId: payload.payload._id
  }
  delete newPayload.displayQuantityCollaborators
  delete newPayload._id

  const filteredBenefitCategoryList = benefitsCategoryId.reduce(
    (prev, element) => {
      const isEqual = prev.find((i) => i.benefitListId === element.benefitListId)
      if (isEqual) return prev.filter((i) => i.benefitListId !== element.benefitListId)
      return [...prev, element]
    },
    [newPayload]
  )

  try {
    yield put({ type: ADD_BENEFIT_CATEGORY_SUCCESS, payload: filteredBenefitCategoryList })
  } catch (error) {
    yield put({ type: ADD_BENEFIT_CATEGORY_ERROR, payload: { error: handleApiError(error) }})
  }
}
function* addListQuantityCollaborators({ payload }) {
  const { benefitDiscovery } = yield select()
  const { answered: { benefitsCategoryId }} = benefitDiscovery  
  const newArray = cloneDeep(benefitsCategoryId)
  newArray.filter((item) => delete item.displayQuantityCollaborators)

  const findValeTransporte = newArray.find((benefit) => benefit.label === payload.benefitDiscovery.label)
  findValeTransporte.quantityCollaborators = payload.valueCheckbox

  const filteredBenefitCategoryList = benefitsCategoryId.reduce(
    (prev, element) => {        
      const isEqual = prev.find((i) => i.benefitListId === element.benefitListId)
      if (isEqual) {
        const isSameCheckbox = prev.find((i) => i.quantityCollaborators === element.quantityCollaborators)
        if (isSameCheckbox) return prev.filter((i) => delete i.quantityCollaborators )
        return prev
      }
      return [...prev, element]
    },
    [findValeTransporte]
  )
  try {
    yield put({ type: ADD_BENEFIT_CATEGORY_SUCCESS, payload: filteredBenefitCategoryList })
  } catch (error) {
    yield put({ type: ADD_BENEFIT_CATEGORY_ERROR, payload: { error: handleApiError(error) }})
  }
}

function* setActiveModalFlow(payload) {
  const { benefitDiscovery } = yield select()
  const { answered } = benefitDiscovery
  const response = { displayModal: payload.payload }
  localStorage.setItem('displayModal', !payload.payload)
  
  response.displayBenefitDiscovery = true

  if (answered.isAnswered) {
    localStorage.removeItem('displayModal')
    response.displayBenefitDiscovery = false
  }
  try {
    yield put({ type: ACTIVE_MODAL_BENEFIT_DISCOVERY, payload: response })
  } catch (error) {
    yield put({
      type: ACTIVE_MODAL_BENEFIT_DISCOVERY_ERROR,
      payload: { error: handleApiError(error) }
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_BENEFIT_DISCOVERY_LIST, getBenefitDiscoveryListFlow),
    takeLatest(CREATE_BENEFIT_DISCOVERY, createBenefitDiscoveryFlow),
    takeLatest(UPDATE_BENEFIT_DISCOVERY, updateBenefitDiscoveryFlow),
    takeLatest(GET_BENEFIT_DISCOVERY, getBenefitDiscoveryFlow),
    takeLatest(ADD_BENEFIT_CATEGORY, addBenefitCategoryFlow),
    takeLatest(ADD_QUANTITY_COLLABORATORS, addListQuantityCollaborators),
    takeLatest(ACTIVE_MODAL_BENEFIT_DISCOVERY_REQUESTING, setActiveModalFlow),
  ])
}

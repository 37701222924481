import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { getAccessToken } from '@flash-tecnologia/hros-web-utility'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { urlMap } from '../api'
import { getFromLS, setInLS } from '../security'
import { refreshToken } from '../security/session'

const HROS = process.env.HROS

const browserHistory = createBrowserHistory({ forceRefresh: true })

const makeApolloClient = () => {
  const httpLink = new HttpLink({
    uri: `${urlMap.company}/company/graphql`,
  })

  const contextLink = setContext(async (_, { headers }) => {
    let authToken
    if (Number(HROS)) {
      authToken = await getAccessToken()
    } else {
      authToken = getFromLS('authData')?.idToken
    }
    const xRequestId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    const context = {
      headers: {
        ...headers,
        ...(authToken && { Authorization: authToken }),
        'X-Request-Id': xRequestId,
      },
    }
    return context
  })

  const authLink = onError(({ graphQLErrors, operation }) => {
    let isUnauthenticated = false
    const context = operation.getContext()
    const { headers } = context
    const requestId = headers['X-Request-Id']
    if (graphQLErrors) {
      graphQLErrors.forEach((GraphQLError) => {
        if (GraphQLError?.errorCode === 'UNAUTHENTICATED') isUnauthenticated = true
        else {
          if (process.env.NODE_ENV === 'production') {
            Sentry.withScope((scope) => {
              scope.setTags({ requestId })
              scope.setLevel('error')
              Sentry.captureException(new Error(GraphQLError.message))
            })
          }
        }
      })
    }

    if (isUnauthenticated) {
      const isRetry = getFromLS('_isRetry') || 0
      // console.log({ isRetry })
      if (isRetry > 5) {
        Sentry.captureMessage('Logout retry limit reached', { level: 'warning' })
        // console.log('logout retry clear')
        return client.clearStore()
      }

      return refreshToken()
        .then(() => {
          // console.log('then retry persist')
          setInLS('_isRetry', isRetry + 1)
        })
        .catch(() => {
          Sentry.captureMessage('Logout retry catched', { level: 'error' })
          // console.log('catch retry clear')
          return client.clearStore()
        })
    }
  })

  const cache = new InMemoryCache({
    addTypename: false
  })

  const client = new ApolloClient({
    link: ApolloLink.from([
      contextLink,
      authLink,
      httpLink,
    ]),
    cache,
    resolvers: {},
  })

  client.onClearStore(() => {
    setInLS('clientData', {})
    browserHistory.push('/')
  })

  return client
}


export default makeApolloClient

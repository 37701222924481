import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  GET_ADMIN_PROFILE_REQUESTING,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_ERROR,
  UPDATE_ADMIN_PROFILE_REQUESTING,
  UPDATE_ADMIN_PROFILE_SUCCESS,
  UPDATE_ADMIN_PSSWRD_SUCCESS,
  UPDATE_ADMIN_PROFILE_ERROR
} from './constants'
import { getFromLS } from '../../services/security'
import { urlMap, request, handleApiError } from '../../services/api'

function* getProfileFlow() {
  try {
    const data = yield call(getProfileApi)
    yield put({ type: GET_ADMIN_PROFILE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ADMIN_PROFILE_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getProfileApi() {
  const { adminId, companyId } = getFromLS('clientData')

  const GET_ADMIN_QUERY = gql`
    query($companyId: ID!, $query: CompanyAdminQuery) {
      companyAdmins(companyId: $companyId query: $query) {
        _id
        cpf
        email
        name
        phone
      }
    }
  `
  const query = print(GET_ADMIN_QUERY)
  const variables = {
    companyId,
    query: {
      adminId,
      companyId,
    }
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.companyAdmins
}

function* updateProfileFlow({ payload }) {
  try {
    const data = yield call(updateProfileApi, payload)
    if (payload.updatePsswrd) {
      yield put({ type: UPDATE_ADMIN_PSSWRD_SUCCESS, payload: data })
    } else {
      yield put({ type: UPDATE_ADMIN_PROFILE_SUCCESS, payload: data })
    }
  } catch (error) {
    yield put({ type: UPDATE_ADMIN_PROFILE_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateProfileApi({
  nameAssociated,
  phone,
  oldpsswrd,
  newpsswrd,
}) {
  const { adminId, companyId } = getFromLS('clientData')
  const UPDATE_ADMIN_QUERY = gql`
    mutation($adminId: ID! $companyId: ID! $updateAdminInput: UpdateAdminInput!) {
      updateCompanyAdmin(adminId: $adminId, companyId: $companyId, updateAdminInput: $updateAdminInput) {
        _id
        cpf
        email
        name
        phone
      }
    }
  `

  const query = print(UPDATE_ADMIN_QUERY)
  const variables = {
    adminId,
    companyId,
    updateAdminInput: {
      name: nameAssociated,
      phone,
      newPsswrd: newpsswrd,
      oldPsswrd: oldpsswrd,
    }
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.companyAdmin
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_ADMIN_PROFILE_REQUESTING, getProfileFlow),
    takeLatest(UPDATE_ADMIN_PROFILE_REQUESTING, updateProfileFlow),
  ])
}

import styled from 'styled-components';
import { palette } from 'styled-theme';
import AppStyle, {fontFamily, fontWeight, pinkColor, orangeColor} from '../../../assets/css/baseTextStyles.css'

const DropdownWrapper = ComponentName => styled(ComponentName)`
  display: inline-block;

  &.ant-dropdown-button {
    &.ant-btn-group > .ant-btn:first-child:not(:last-child) {
      border-radius: ${props =>
        props['data-rtl'] === 'rtl' ? '0 10px 10px 0' : '10px 0  0 10px'};
    }

    &.ant-btn-group > .ant-btn:last-child:not(:first-child) {
      border-radius: ${props =>
        props['data-rtl'] === 'rtl' ? '10px 0 0 10px' : '0 10px 10px 0'};
    }

    &.ant-btn-group .ant-btn + .ant-btn {
      margin: ${props => (props['data-rtl'] === 'rtl' ? '0 -1px 0 0' : '0 0 0 -1px')};
    }
  }

  .ant-dropdown-link {
    font-size: 13px;
    color: ${palette('primary', 0)};

    &:hover {
      color: ${palette('primary', 2)};
    }
  }
`;

const DropdownMenus = ComponentName => styled(ComponentName)`
  &.ant-dropdown-menu {
    background: linear-gradient(${pinkColor}, ${orangeColor});
    box-shadow: 0 2px 5px ${orangeColor}32;
    border-radius: 10px;
  }

  .ant-dropdown-menu-item {
    height: 44px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    color: #fff;
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    font-size: ${AppStyle.bodyStyle.fontSize};
    background-color: transparent !important;
    
    a {
      font-size: 13px;
      color: ${palette('text', 1)};
    }
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    a {
      font-size: 13px;
      color: ${palette('text', 1)};
    }

    &:hover {
      background-color: #FFFFFF14 !important;
    }
  }
  .ant-dropdown-menu-item {
    &:first-child:not(:last-child) {
      border-radius: 10px 10px 0 0;
    }
    &.last-child:not(:first-child) {
      border-radius: 0 0 10px 10px;
    }
  }
`;

export default DropdownWrapper;
export { DropdownMenus };

import { benefitsCategories, benefitsMap } from '@flash-tecnologia/flamingo-ui/src/utils'
import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS, setInLS } from '../../../services/security'
import {
  ADD_EMPLOYEES_ERROR,
  ADD_EMPLOYEES_REQUESTING,
  ADD_EMPLOYEES_SUCCESS,
  CHECK_ADD_SHEET_DATA_ERROR,
  CHECK_ADD_SHEET_DATA_REQUESTING,
  CHECK_ADD_SHEET_DATA_SUCCESS,
  CHECK_DELETE_SHEET_DATA_ERROR,
  CHECK_DELETE_SHEET_DATA_REQUESTING,
  CHECK_DELETE_SHEET_DATA_SUCCESS,
  CHECK_SHEET_DATA_ERROR,
  CHECK_SHEET_DATA_REQUESTING,
  CHECK_SHEET_DATA_SUCCESS,
  CLEAN_EMPLOYEE_POINTS_CONFIGURATION_ERROR,
  CLEAN_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING,
  CLEAN_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS,
  CREATE_DEPOSITS_ERROR,
  CREATE_DEPOSITS_REQUESTING,
  CREATE_DEPOSITS_SUCCESS,
  DELETE_EMPLOYEES_SHEET_ERROR,
  DELETE_EMPLOYEES_SHEET_REQUESTING,
  DELETE_EMPLOYEES_SHEET_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_REQUESTING,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_MULTIPLE_EMPLOYEES_ERROR,
  DELETE_MULTIPLE_EMPLOYEES_REQUESTING,
  DELETE_MULTIPLE_EMPLOYEES_SUCCESS,
  GENERATE_BILLET_ERROR,
  GENERATE_BILLET_REQUESTING,
  GENERATE_BILLET_SUCCESS,
  GET_CHECKOUT_DATA_ERROR,
  GET_CHECKOUT_DATA_REQUESTING,
  GET_CHECKOUT_DATA_SUCCESS,
  GET_COMPANY_ADMIN_ERROR,
  GET_COMPANY_ADMIN_REQUESTING,
  GET_COMPANY_ADMIN_SUCCESS,
  GET_COMPANY_ERROR,
  GET_COMPANY_GROUPS_ERROR,
  GET_COMPANY_GROUPS_REQUESTING,
  GET_COMPANY_GROUPS_SUCCESS,
  GET_COMPANY_REQUESTING,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_TAGS_ERROR,
  GET_COMPANY_TAGS_REQUESTING,
  GET_COMPANY_TAGS_SUCCESS,
  GET_COMPANY_VIRTUAL_BALANCE_ERROR,
  GET_COMPANY_VIRTUAL_BALANCE_REQUESTING,
  GET_COMPANY_VIRTUAL_BALANCE_SUCCESS,
  GET_COMPANY_SHEETS_REQUESTING,
  GET_COMPANY_SHEETS_SUCCESS,
  GET_COMPANY_SHEETS_ERROR,
  GET_EMPLOYEES_ERROR,
  GET_EMPLOYEES_REQUESTING,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_POINTS_CONFIGURATION_ERROR,
  GET_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING,
  GET_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS,
  GET_SHOULD_RENDER_PAT_ERROR,
  GET_SHOULD_RENDER_PAT_REQUESTING,
  GET_SHOULD_RENDER_PAT_SUCCESS,
  PAY_WITH_BALANCE_ERROR,
  PAY_WITH_BALANCE_REQUESTING,
  PAY_WITH_BALANCE_SUCCESS,
  RESEND_POINTS_NOTIFICATION_ERROR,
  RESEND_POINTS_NOTIFICATION_REQUESTING,
  RESEND_POINTS_NOTIFICATION_SUCCESS,
  RESEND_WELCOME_NOTIFICATION_ERROR,
  RESEND_WELCOME_NOTIFICATION_REQUESTING,
  RESEND_WELCOME_NOTIFICATION_SUCCESS,
  SEND_EMPLOYEES_DOCUMENTS_ERROR,
  SEND_EMPLOYEES_DOCUMENTS_REQUESTING,
  SEND_EMPLOYEES_DOCUMENTS_SUCCESS,
  SEND_EMPLOYEES_REPORT_ERROR,
  SEND_EMPLOYEES_REPORT_REQUESTING,
  SEND_EMPLOYEES_REPORT_SUCCESS,
  SEND_SINGLE_EMPLOYEE_DOCUMENTS_ERROR,
  SEND_SINGLE_EMPLOYEE_DOCUMENTS_REQUESTING,
  SEND_SINGLE_EMPLOYEE_DOCUMENTS_SUCCESS,
  UPDATE_ACCESS_DATE_ERROR,
  UPDATE_ACCESS_DATE_REQUESTING,
  UPDATE_ACCESS_DATE_SUCCESS,
  UPDATE_ALL_EMPLOYEES_BENEFITS_SUCCESS,
  UPDATE_COMPANY_ADMIN_ERROR,
  UPDATE_COMPANY_ADMIN_REQUESTING,
  UPDATE_COMPANY_ADMIN_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_REQUESTING,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_TAGS_ERROR,
  UPDATE_COMPANY_TAGS_REQUESTING,
  UPDATE_COMPANY_TAGS_SUCCESS,
  UPDATE_EMPLOYEE_BENEFIT_ERROR,
  UPDATE_EMPLOYEE_BENEFIT_REQUESTING,
  UPDATE_EMPLOYEE_BENEFIT_SUCCESS,
  UPDATE_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_POLICY_ERROR,
  UPDATE_EMPLOYEE_POLICY_REQUESTING,
  UPDATE_EMPLOYEE_POLICY_SUCCESS,
  UPDATE_EMPLOYEE_REQUESTING,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_TAGS_ERROR,
  UPDATE_EMPLOYEE_TAGS_REQUESTING,
  UPDATE_EMPLOYEE_TAGS_SUCCESS,
  UPDATE_GROUP_NAME_ERROR,
  UPDATE_GROUP_NAME_REQUESTING,
  UPDATE_GROUP_NAME_RESET,
  UPDATE_GROUP_NAME_SUCCESS,
  UPDATE_MULTIPLE_EMPLOYEES_ERROR,
  UPDATE_MULTIPLE_EMPLOYEES_REQUESTING,
  UPDATE_MULTIPLE_EMPLOYEES_SUCCESS,
  UPDATE_PAT_TERMS_REQUESTING,
  UPDATE_PAT_TERMS_ERROR,
  UPDATE_PAT_TERMS_SUCCESS,
  UPSERT_DEDUCTIONS_ERROR,
  UPSERT_DEDUCTIONS_REQUESTING,
  UPSERT_DEDUCTIONS_SUCCESS,
} from './constants'
import { CORE_EMPLOYEE_POINTS_CONFIGURATION } from './utils/fragments'

function* addEmployeesFlow(action) {
  try {
    const payload = yield call(addEmployeesApi, action.payload)
    yield put({ type: ADD_EMPLOYEES_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: ADD_EMPLOYEES_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function addEmployeesApi({ input }) {
  const { companyId, adminId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const ADD_EMPLOYEES_QUERY = gql`
    mutation($adminId: ID! $companyId: ID!, $input: [AddEmployeeInput]) {
      addEmployees(adminId: $adminId, companyId: $companyId, input: $input) {
        cpf
        employeeId
      }
    }
  `
  const query = print(ADD_EMPLOYEES_QUERY)
  const variables = { adminId, companyId, input }

  const response = await request.graphql(url, { query, variables })

  return response.data.addEmployees
}

function* checkDeleteSheetDataFlow(action) {
  try {
    const payload = yield call(checkDeleteSheetDataApi, action.payload)
    yield put({ type: CHECK_DELETE_SHEET_DATA_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: CHECK_DELETE_SHEET_DATA_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function checkDeleteSheetDataApi({ sheetData, sheetFile, type }) {
  const { companies, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const CHECK_DELETE_SHEET_DATA_QUERY = gql`
    query($companyId: ID!, $companies: [ID]!, $sheetData: [AddEmployeeInput]!, $sheetFile: sheetFile!, $type: String ) {
      checkDeleteSheetData(companyId: $companyId, companies: $companies, sheetData: $sheetData, sheetFile: $sheetFile, type: $type )  {
        employees
        error {
          line
          message
        }
        tempUrl
      }
    }
  `
  const query = print(CHECK_DELETE_SHEET_DATA_QUERY)

  const companyIds = companies.map(company => company._id)

  const variables = {
    companyId,
    companies: companyIds,
    sheetData,
    sheetFile,
    type,
  }
  const response = await request.graphql(url, { query, variables })


  return response.data.checkDeleteSheetData

}

function* createDepositsFlow(action) {
  try {
    const payload = yield call(createDepositsApi, action.payload)
    yield put({ type: CREATE_DEPOSITS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: CREATE_DEPOSITS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function createDepositsApi(payload) {
  const { companyId, adminId } = getFromLS('clientData')
  const input = {
    adminId,
    companyId,
    ...payload
  }
  const url = `${urlMap.company}/company/graphql`

  const CREATE_DEPOSITS_QUERY = gql`
    mutation($input: CreateDepositsInput!) {
      createDeposits(input: $input) {
        awards {
          accountAmountCharged
          amountCharged
          companyDepositId
          employeesQty
          pdfCreated
          pdfUrl
          reportKey
          totalCharged
        }
        benefits {
          accountAmountCharged
          amountCharged
          companyDepositId
          employeesQty
          pdfCreated
          pdfUrl
          reportKey
          totalCharged
          totalRefundedCompanyRate
        }
        checkoutTypes
        cnpjsQty
        companyDepositIds
        homeOffice {
          accountAmountCharged
          amountCharged
          companyDepositId
          employeesQty
          pdfCreated
          pdfUrl
          reportKey
          totalCharged
        }
      }
    }
  `

  const query = print(CREATE_DEPOSITS_QUERY)
  const variables = {
    input,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.createDeposits
}

function* checkAddSheetDataFlow(action) {
  try {
    const payload = yield call(checkAddSheetDataApi, action.payload)
    yield put({ type: CHECK_ADD_SHEET_DATA_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: CHECK_ADD_SHEET_DATA_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function checkAddSheetDataApi({ sheetData, sheetFile, type }) {
  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const CHECK_ADD_SHEET_DATA_QUERY = gql`
    query($adminId: ID!, $companyId: ID!, $sheetData: [AddEmployeeInput]!, $sheetFile: sheetFile!, $type: String ) {
      checkSheetData(adminId: $adminId, companyId: $companyId, sheetData: $sheetData, sheetFile: $sheetFile, type: $type )  {
        employees {
          cnpj
          companyId
          cpf
          defaultCardProduct
          depositAmountsConfig
          email
          employeeId
          errors {
            line
            message
          }
          externalId
          group
          name
          notificationDate
          phone
          policy
          pointsNotificationDate
          swapAccount
          tags
          tagsWithPrefix {
            prefixId
            value
          }
        }
        tempUrl
      }
    }
  `

  const query = print(CHECK_ADD_SHEET_DATA_QUERY)
  const variables = {
    adminId,
    companyId,
    sheetData,
    sheetFile,
    type,
  }
  const response = await request.graphql(url, { query, variables })

  return response.data.checkSheetData
}

function* checkSheetDataFlow(action) {
  try {
    const payload = yield call(checkSheetDataApi, action.payload)
    yield put({ type: CHECK_SHEET_DATA_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: CHECK_SHEET_DATA_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function checkSheetDataApi({ sheetData, sheetFile, type }) {
  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const CHECK_SHEET_DATA_QUERY = gql`
    query($adminId: ID!, $companyId: ID!, $sheetData: [AddEmployeeInput]!, $sheetFile: sheetFile!, $type: String ) {
      checkSheetData(adminId: $adminId, companyId: $companyId, sheetData: $sheetData, sheetFile: $sheetFile, type: $type )  {
        addEmployees
        checkoutTypes
        employees {
          cpf
          companyId
          cnpj
          defaultCardProduct
          depositAmountsConfig {
            active
            amount
            benefitId
            productName
            category
            key
            }
          email
          employeeId
          errors {
            line
            message
          }
          externalId
          group
          name
          notificationDate
          phone
          policy
          pointsNotificationDate
          swapAccount
          tags
          tagsWithPrefix {
            prefixId
            value
          }
          virtualAwards
          virtualHomeOffice
        }
        numberOfCompanies
        tempUrl
      }
    }
  `

  const query = print(CHECK_SHEET_DATA_QUERY)
  const variables = {
    adminId,
    companyId,
    sheetData,
    sheetFile,
    type,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.checkSheetData
}

function* deleteEmployeesBySheet(action) {
  try {
    const payload = yield call(deleteEmployeesBySheetApi, action.payload)
    yield put({ type: DELETE_EMPLOYEES_SHEET_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: DELETE_EMPLOYEES_SHEET_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function deleteEmployeesBySheetApi(payload) {
  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const DELETE_EMPLOYEE_SHEET_MUTATION = gql`
    mutation($adminId: ID!, $companyId: ID!, $employeesToBeDeleted: [DeleteBySheetInput!]!) {
      deleteEmployeesBySheet(adminId: $adminId, companyId: $companyId, employeesToBeDeleted: $employeesToBeDeleted) {
        deleted
      }
    }
  `
  const query = print(DELETE_EMPLOYEE_SHEET_MUTATION)
  const variables = {
    adminId,
    companyId,
    employeesToBeDeleted: payload
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.deleteEmployeesBySheet
}

function* deleteEmployeeFlow(action) {
  try {
    const payload = yield call(deleteEmployeeApi, action.payload)
    yield put({ type: DELETE_EMPLOYEE_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: DELETE_EMPLOYEE_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function deleteEmployeeApi({ employeeId }) {
  const url = `${urlMap.company}/company/graphql`
  const { adminId, companyId } = getFromLS('clientData')

  const DELETE_EMPLOYEE_QUERY = gql`
    mutation($adminId: ID!, $companyId: ID!, $employeeId: ID!) {
      deleteEmployee(adminId: $adminId, companyId: $companyId employeeId: $employeeId) {
        deleted
      }
    }
  `

  const query = print(DELETE_EMPLOYEE_QUERY)
  const variables = {
    adminId,
    companyId,
    employeeId,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.deleteEmployee
}

function* cleanEmployeePointsConfigurationFlow(action) {
  try {
    const payload = yield call(cleanEmployeePointsConfigurationApi, action.payload)
    yield put({ type: CLEAN_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: CLEAN_EMPLOYEE_POINTS_CONFIGURATION_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function cleanEmployeePointsConfigurationApi( payload ) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId, adminId } = getFromLS('clientData')
  const employeeId = payload._id

  const CLEAN_EMPLOYEE_POINTS_CONFIGURATION_QUERY = gql`
    mutation(
      $adminId: ID!
      $companyId: ID!
      $employeeId: ID!
    ) {
      cleanEmployeePointsConfiguration(
          adminId: $adminId
          companyId: $companyId
          employeeId: $employeeId
      )
    }
  `
  const query = print(CLEAN_EMPLOYEE_POINTS_CONFIGURATION_QUERY)
  const variables = {
    adminId,
    companyId,
    employeeId,
  }

  const response = await request.graphql(url, { query, variables })
  return response
}

function* updateEmployeePolicyFlow(action) {
  try {
    const payload = yield call(updateEmployeePolicyApi, action.payload)
    yield put({ type: UPDATE_EMPLOYEE_POLICY_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_EMPLOYEE_POLICY_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function updateEmployeePolicyApi( payload ) {
  const { companyPoliciesSelected, employeeId } = payload

  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')

  const UPDATE_EMPLOYEE_POLICY_QUERY = gql`
    mutation(
      $companyId: ID!
      $companyPoliciesSelected: String
      $employeeId: ID!
    ) {
      updateEmployeePolicy(
        companyId: $companyId
        companyPoliciesSelected: $companyPoliciesSelected
        employeeId: $employeeId
      )
    }
  `
  const query = print(UPDATE_EMPLOYEE_POLICY_QUERY)
  const variables = { companyId,
    companyPoliciesSelected,
    employeeId }

  const response = await request.graphql(url, { query, variables })

  return response
}

function* deleteMultipleEmployeesFlow(action) {
  try {
    const payload = yield call(deleteMultipleEmployeesApi, action.payload)
    yield put({ type: DELETE_MULTIPLE_EMPLOYEES_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: DELETE_MULTIPLE_EMPLOYEES_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function deleteMultipleEmployeesApi({
  selectedEmployeesInput,
  benefitTypes,
}) {
  const url = `${urlMap.company}/company/graphql`
  const { adminId, companyId } = getFromLS('clientData')
  const DELETE_MULT_EMPLOYEE_QUERY = gql`
    mutation(
      $adminId: ID!
      $selectedEmployeesInput: SelectedEmployeesInput
      $companyId: ID!
      $benefitTypes: [String]
    ) {
      deleteEmployee(
        adminId: $adminId
        selectedEmployeesInput: $selectedEmployeesInput
        companyId: $companyId
        benefitTypes: $benefitTypes
      ) {
        deleted
      }
    }
  `

  const query = print(DELETE_MULT_EMPLOYEE_QUERY)
  const variables = { adminId, selectedEmployeesInput, companyId, benefitTypes }

  const response = await request.graphql(url, { query, variables })

  return response.data.deleteEmployee
}

function* generateBilletFlow(action) {
  try {
    const payload = yield call(generateBilletApi, action.payload)
    yield put({ type: GENERATE_BILLET_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GENERATE_BILLET_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function generateBilletApi(payload) {
  const { companyId } = getFromLS('clientData')
  const input = { companyId, ...payload }

  const url = `${urlMap.company}/company/graphql`

  const GENERATE_BILLET_QUERY = gql`
    mutation($input: CreateBilletInput!) {
      createBillet(input: $input)
    }
  `

  const query = print(GENERATE_BILLET_QUERY)
  const variables = { input }

  const response = await request.graphql(url, { query, variables })

  return response.data.createBillet
}

function* getCheckoutDataFlow(action) {
  try {
    const payload = yield call(getCheckoutDataApi, action.payload)
    yield put({ type: GET_CHECKOUT_DATA_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_CHECKOUT_DATA_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function getCheckoutDataApi({ checkoutTypes, employeesAmounts, notificationDate, selectedEmployees, sheetData = [] }) {
  const { adminId, companyId } = getFromLS('clientData')
  const benefitsCategoriesSchema = Object.keys(benefitsCategories).map(type => (
    `${type} {
      active
      amount
      exclusiveAmount
  },`
  )).join(' ')
  const deductionsSchema = Object.keys(benefitsCategories).join(' ')
  const url = `${urlMap.company}/company/graphql`
  const GET_CHECKOUT_DATA_QUERY = gql`
    query Company ($adminId: ID!, $checkoutTypes: [String], $companyId: ID!, $employeesAmounts: [AmountsInput], $notificationDate: DateTime, $selectedEmployees: SelectedEmployeesInput! $sheetData: [AddEmployeeInput]) {
      company(_id: $companyId) {
        checkoutData(adminId: $adminId checkoutTypes: $checkoutTypes employeesAmounts: $employeesAmounts, notificationDate: $notificationDate, selectedEmployees: $selectedEmployees, sheetData: $sheetData) {
          awards {
            additionalInfo
            awardsCustomDescription
            canUseBalance
            hasVirtualErrors
            totalBalance
            totalCharged
          }
          benefits {
            additionalInfo
            awardsCustomDescription
            canUseBalance
            cardsInStock
            checkoutBenefitsArray
            employeesIdsWoBenefits
            employeesWithoutCards
            firstPayment
            swapCashAccountBalance
            swapGetBalanceError
            totalBalance
            totalCharged
          }
          checkoutTypes
          companiesDepositData {
            companyId
            employees {
              benefitsCategories {
                ${benefitsCategoriesSchema}
              }
              cpf
              deductionCategoriesAmounts {
                ${deductionsSchema}
              }
              defaultCardProduct
              employeeId
              group
              name
              notificationDate
              numberOfDays
              swapAccount
              virtualAwards
              virtualHomeOffice
            }
          }
          homeOffice {
            additionalInfo
            canUseBalance
            hasVirtualErrors
            totalBalance
            totalCharged
          }
          virtual {
            additionalInfo
            hasVirtualErrors
            key
            totalVirtualCharged
        }
      }
    }
  `
  const query = print(GET_CHECKOUT_DATA_QUERY)
  const variables = { adminId, checkoutTypes, companyId, employeesAmounts, notificationDate, selectedEmployees, sheetData }

  const response = await request.graphql(url, { query, variables })

  return { ...response.data.company.checkoutData, selectedEmployees }
}

function* getCompanyFlow() {
  try {
    const payload = yield call(getCompanyApi)
    yield put({ type: GET_COMPANY_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_COMPANY_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function getCompanyApi() {
  const clientData = getFromLS('clientData')
  const { companyId } = clientData
  const url = `${urlMap.company}/company/graphql`
  const benefits = Object.keys(benefitsMap)
    .map(type =>
      `${type} {
        active
        timeUnit
        depositNames
      }`
    )
    .join(' ')
  const benefitsCategoriesSchema = Object.keys(benefitsCategories)
    .map(type =>
      `${type} {
        active
        exclusiveEnabled
        timeUnit
      },`
    )
    .join(' ')

  const companyBenefitSchema = `
    description
    image
    key
    name
  `

  const categorySchema = `
    description
    image
    type
    name
  `
  const GET_COMPANY_QUERY = gql`
  query Company ($companyId: ID!) {
    company(_id: $companyId) {
        activeCustomBenefits
        activePolicies
        cnpj
        companyName
        benefits {
          ${benefits}
        }
        benefitsCategories {
          ${benefitsCategoriesSchema}
        }
        companyBenefits {
          plastic {
            ${companyBenefitSchema}
          }
          plasticExclusiveBalance {
            ${companyBenefitSchema}
          }
          virtual {
            ${companyBenefitSchema}
          }
        }
        companyMaps {
          categoriesMap {
            ${categorySchema}
          }
          depositCategoriesMap {
            ${categorySchema}
          }
        }
        companySteps {
          companyBenefitsConfig
          companyConfig
          depositAmountConfig
          onboarding
        }
        companyTemplates {
          plastic {
            _id
            config {
              featureConfigKey
            }
            depositCategories
            description
            key
            name
            style {
              primaryColor
            }
            usagePolicy {
              categories
            }
            visible
          }
          plasticExclusiveBalance {
            key
          }
          virtual {
            key
          }
        }
        employeesQty
        featureConfig {
          apiEnabled
          awardsCustomDescriptionEnabled
          awardsEnabled
          benefitsEnabled
          benefitDiscoveryEnabled
          collectiveConventionEnabled
          customNotificationMessageEnabled
          depositAdditionalInfoEnabled
          expenseManagementEnabled
          employeeCardOrderEnabled
          flashCashEnabled
          flashPointsEnabled
          groceryExclusiveBalanceEnabled
          homeOfficeEnabled
          incomeBaseEnabled
          marketplaceEnabled
          mealExclusiveBalanceEnabled
          mealGroceryEnabled
          mealGroceryExclusiveBalanceEnabled
          mobilityExclusiveBalanceEnabled
          onboardingWithPointsDistributionEnabled
          overwriteShippingTypeEnabled
          pismoBenefitsEnabled
          pismoGroceryExclusiveBalanceEnabled
          pismoMealExclusiveBalanceEnabled
          pismoMealGroceryExclusiveBalanceEnabled
          pismoMobilityExclusiveBalanceEnabled
          preSelectionBenefitsEnabled
          restDayCreditEnabled
          subOrdersEnabled
          topUpEnabled
          depositRefundEnabled
        }
        flashPoints {
          customBenefits {
            _id
            acceptDependents
            deleted
            dependents {
              description
              key
              paymentMethods
            }
            description
            duration {
              dueDate
              period {
                timeUnit
                value
              }
            }
            edition
            key
            name
            paymentMethods
            type
            requiredDocuments {
              adoptedChildren
              children
              holder
              others
              specialNeedsChildren
              spouse
              stableUnion
              stepchildren
            }
            requiredDocumentsDescription {
              adoptedChildren
              children
              holder
              others
              specialNeedsChildren
              spouse
              stableUnion
              stepchildren
            }
            subGroups {
              cost
              key
              name
            }
          }
          cutOffDate {
            timeUnit
            value
          }
          policies {
            _id
            deleted
            description
            name
            optionalPointsBenefits
            requiredPointsBenefits {
              customBenefitKey
              maxPoints
              points
            }
            totalPoints
          }
        }
        misc {
          postPaid {
            enabled
            paymentTerm
          }
          uploadConfig {
            txt {
              pay {
                decimalSeparator
                headerInfo {
                  hasHeader
                  header
                }
                separator
              }
            }
            csv {
              pay {
                decimalSeparator
                headerInfo {
                  hasHeader
                  header
                }
                separator
              }
            }
          }
        }
        prefixTags {
          _id
          value
        }
        pricing {
          awards {
            type
            valuePerAccount
            employeeTransferFee
          }
          benefits {
            type
            valuePerAccount
          }
          homeOffice {
            type
            valuePerAccount
          }
        }
        uploadedFiles {
          pay { name, version }
        }
      }
    }
  `
  const query = print(GET_COMPANY_QUERY)
  const variables = { companyId }

  const response = await request.graphql(url, { query, variables })
  const storageData = {
    ...clientData,
    featureConfig: response.data.company.featureConfig,
  }
  setInLS('clientData', storageData)
  return response.data.company
}

function* getCompanyGroupsFlow(action) {
  try {
    const payload = yield call(getCompanyGroupsApi, action.payload)
    yield put({ type: GET_COMPANY_GROUPS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_COMPANY_GROUPS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function getCompanyGroupsApi(payload) {
  const filter = payload
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const GET_COMPANY_GROUPS_QUERY = gql`
    query Company($companyId: ID!, $filter: FilterObject) {
      company(_id: $companyId) {
        groups(filter: $filter) {
          count
          days
          employeesIds
          name
        }
      }
    }
  `

  const query = print(GET_COMPANY_GROUPS_QUERY)
  const variables = { companyId, filter }
  const response = await request.graphql(url, { query, variables })

  return response.data
}

function* getCompanyAdminFlow() {
  try {
    const payload = yield call(getCompanyAdminApi)
    yield put({ type: GET_COMPANY_ADMIN_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_COMPANY_ADMIN_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function getCompanyAdminApi() {
  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const GET_COMPANY_ADMIN_QUERY = gql`
    query($companyId: ID!, $query: CompanyAdminQuery) {
      companyAdmins(companyId: $companyId query: $query) {
        _id
        steps {
          awardsGuidedOnboarding
          homeOfficeGuidedOnboarding
          skipEmployeesWoBenefits
          visitedCards
          depositsTour
        }
      }
    }
  `
  const query = print(GET_COMPANY_ADMIN_QUERY)
  const variables = {
    companyId,
    query: {
      adminId,
      companyId,
    },
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.companyAdmins
}

function* getShouldRenderPatFlow() {
  try {
    const payload = yield call(getShouldRenderPatApi)
    yield put({ type: GET_SHOULD_RENDER_PAT_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_SHOULD_RENDER_PAT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getShouldRenderPatApi() {
  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const GET_SHOULD_RENDER_PAT_QUERY = gql`
    query($companyId: ID!, $adminId: ID!) {
      companyAdminMustAcceptPAT(companyId: $companyId adminId: $adminId)
    }
  `
  const query = print(GET_SHOULD_RENDER_PAT_QUERY)
  const variables = {
    companyId,
    adminId,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data
}

function* getEmployeesFlow(action) {
  try {
    const payload = yield call(getEmployeesApi, action.payload)
    yield put({ type: GET_EMPLOYEES_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_EMPLOYEES_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function getEmployeesApi({
  limit,
  skip,
  // sort,
  // sortDirection,
  filter,
  // isSearch = false,
  // benefitTypes,
}) {
  const { companyId } = getFromLS('clientData')
  const benefitsCategoriesSchema = Object.keys(benefitsCategories)
    .map(
      type =>
        `${type} {
    active
    amount
    exclusiveAmount
  },`,
    )
    .join(' ')

  const categoriesAmountsSchema = Object.keys(benefitsCategories)
    .map(category => `${category},`)
    .join(' ')

  const GET_EMPLOYEES_QUERY = gql`
    query Company ($companyId: ID!, $filter: FilterObject, $skip: Int, $limit: Int) {
      company(_id: $companyId) {
        companyName
        cnpj
        groups (filter: $filter) {
          name
          employees (skip: $skip, limit: $limit) {
            _id
            cpf
            deductions {
              month
              year
              categoriesAmounts {
                ${categoriesAmountsSchema}
              }
            }
            documentsDates
            email
            externalId
            flashPointsEnabled
            name
            notificationDate
            pointsNotificationDate
            phone
            policy {
              name
              selectedCustomBenefits {
                key
              }
              status
            }
            processStatus {
              currentStatus
              statusSteps {
                hasLogged
                hasCreatedProfile
                hasCreatedAccessPassword
                hasCreatedTransactionPassword
                hasCompletedSignUp
                hasSentNotificationEmail
                hasStarted
                hasSyncedCard
                hasPlasticCard
                status
              }
            }
            product {
              _id
              benefitsCategories {
                ${benefitsCategoriesSchema}
              }
              flashProduct
            }
            tags
            tagsWithPrefix {
              prefixId
              value
            }
          }
        }
      }
    }
  `
  const query = print(GET_EMPLOYEES_QUERY)
  const variables = { companyId, filter, skip, limit }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return { data: response.data }
}

function* getEmployeePointsConfigurationFlow(action) {
  try {
    const payload = yield call(getEmployeePointsConfigurationApi, action.payload)
    yield put({ type: GET_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_EMPLOYEE_POINTS_CONFIGURATION_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function getEmployeePointsConfigurationApi({ employeeId }) {
  const EMPLOYEE_POINTS_CONFIGURATION_QUERY = gql`
    ${CORE_EMPLOYEE_POINTS_CONFIGURATION}
    query($employeeId: ID!) {
      employeePointsConfiguration(_id: $employeeId) {
        ...CoreEmployeePointsConfiguration
        errors {
          title
          message
          field
        }
      }
    }`

  const query = print(EMPLOYEE_POINTS_CONFIGURATION_QUERY)
  const variables = { employeeId }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return { data: response.data }
}

function* getCompanyTagsFlow(action) {
  try {
    const payload = yield call(getCompanyTagsApi, action.payload)
    yield put({ type: GET_COMPANY_TAGS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_COMPANY_TAGS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getCompanyTagsApi() {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const GET_COMPANY_TAGS_QUERY = gql`
    query Company($companyId: ID!) {
      company(_id: $companyId) {
        employeeTags {
          tags
          tagsWithPrefix {
            prefixId
            value
          }
          tagsPerPrefix {
            _id
            value
            tags
          }
        }
      }
    }
  `

  const query = print(GET_COMPANY_TAGS_QUERY)
  const variables = { companyId }
  const options = {
    headers: { 'Content-Type': 'application/json' },
  }

  const response = await request.graphql(url, { query, variables }, options)
  return response.data.company.employeeTags
}

function* getCompanyVirtualBalanceFlow(action) {
  try {
    const payload = yield call(getCompanyVirtualBalanceApi, action.payload)
    yield put({ type: GET_COMPANY_VIRTUAL_BALANCE_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_COMPANY_VIRTUAL_BALANCE_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getCompanyVirtualBalanceApi() {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const GET_COMPANY_VIRTUAL_BALANCE_QUERY = gql`
    query($companyId: ID!) {
      getCompanyVirtualBalance(companyId: $companyId)
    }
  `

  const query = print(GET_COMPANY_VIRTUAL_BALANCE_QUERY)
  const variables = { companyId }
  const options = {
    headers: { 'Content-Type': 'application/json' },
  }

  const response = await request.graphql(url, { query, variables }, options)
  return response.data.getCompanyVirtualBalance
}

function* getCompanySheet(action) {
  try {
    const payload = yield call(getCompanySheetApi, action.payload.type)
    yield put({ type: GET_COMPANY_SHEETS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: GET_COMPANY_SHEETS_ERROR ,
      payload: { error: handleApiError(error) }
    })
  }
}

async function getCompanySheetApi(type) {
  const { companyId, adminId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const GET_COMPANY_PAY_SHEET = gql`
query($companyId: ID!, $adminId: ID!, $type: String){
  getCompanySheet(companyId:$companyId, adminId:$adminId, type: $type ){
        header {
          benefitId
          category
          cnpj
          header
          productKey
          productName
          timeUnit
               }
         path
        }
  }
  `

  const query = print(GET_COMPANY_PAY_SHEET)
  const variables = { companyId, adminId, type }
  const options = {
    headers: { 'Content-Type': 'application/json' },
  }

  const response = await request.graphql(url, { query, variables }, options)
  return response.data.getCompanySheet

}

function* sendEmployeesReportFlow(action) {
  try {
    const payload = yield call(sendEmployeesReportApi, action.payload)
    yield put({ type: SEND_EMPLOYEES_REPORT_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: SEND_EMPLOYEES_REPORT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function sendEmployeesReportApi() {
  const { companyId, adminId } = getFromLS('clientData')
  const employeesType = 'employees'

  const SEND_EMPLOYEES_REPORT_QUERY = gql`
    query($companyId: ID! $adminId: ID! $employeesType: String!) {
      sendReport(companyId: $companyId, adminId: $adminId, type: $employeesType)
    }`
  const query = print(SEND_EMPLOYEES_REPORT_QUERY)
  const variables = { companyId, adminId, employeesType }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response
}

function* sendSingleEmployeeDocumentsFlow(action) {
  try {
    const payload = yield call(sendSingleEmployeeDocumentsApi, action.payload)
    yield put({ type: SEND_SINGLE_EMPLOYEE_DOCUMENTS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: SEND_SINGLE_EMPLOYEE_DOCUMENTS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function sendSingleEmployeeDocumentsApi({ input }) {
  const { employeeId, adminId, companyId, date } = input
  const url = `${urlMap.company}/company/graphql`
  const SEND_SINGLE_EMPLOYEE_DOCUMENTS_QUERY = gql`
    query($employeeId: ID!, $adminId: ID!, $companyId: ID!, $date: String) {
      sendSingleEmployeeDocuments(
        employeeId: $employeeId
        adminId: $adminId
        companyId: $companyId
        date: $date
      )
    }
  `

  const query = print(SEND_SINGLE_EMPLOYEE_DOCUMENTS_QUERY)
  const variables = { employeeId, adminId, companyId, date }
  const options = {
    headers: { 'Content-Type': 'application/json' },
  }

  const response = await request.graphql(url, { query, variables }, options)
  return response.data['sendSingleEmployeeDocuments']
}

function* sendEmployeesDocumentsFlow(action) {
  try {
    const payload = yield call(sendEmployeesDocumentsApi, action.payload)
    yield put({ type: SEND_EMPLOYEES_DOCUMENTS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: SEND_EMPLOYEES_DOCUMENTS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function sendEmployeesDocumentsApi({
  deselectedGroupsEmployees,
  selectedGroups,
  selectedGroupsEmployees,
}) {
  const { adminId, companyId } = getFromLS('clientData')
  const input = {
    deselectedGroupsEmployees,
    selectedGroups,
    selectedGroupsEmployees,
  }

  const SEND_EMPLOYEES_DOCUMENTS_QUERY = gql`
    query($companyId: ID! $adminId: ID! $reportName: String!  $input: SendReportInput) {
      sendReportV2(
        companyId: $companyId
        adminId: $adminId
        reportName: $reportName
        input: $input
      )
    }
  `
  const query = print(SEND_EMPLOYEES_DOCUMENTS_QUERY)
  const reportName = 'sendEmployeesDocuments'
  const variables = {
    adminId,
    companyId,
    input,
    reportName,
  }

  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables }, options)

  return response.data.sendReportV2
}

function* resendWelcomeNotificationFlow(action) {
  try {
    const payload = yield call(resendWelcomeNotificationApi, action.payload)
    yield put({ type: RESEND_WELCOME_NOTIFICATION_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: RESEND_WELCOME_NOTIFICATION_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function resendWelcomeNotificationApi({ employeeId }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')

  const RESEND_WELCOME_NOTIFICATION_QUERY = gql`
    query($companyId: ID!, $employeeId: ID!) {
      resendWelcomeEmail(companyId: $companyId employeeId: $employeeId)
    }
  `

  const query = print(RESEND_WELCOME_NOTIFICATION_QUERY)
  const variables = {
    companyId,
    employeeId,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.resendWelcomeNotification
}

function* resendPointsNotificationFlow(action) {
  try {
    const payload = yield call(resendPointsNotificationApi, action.payload)
    yield put({ type: RESEND_POINTS_NOTIFICATION_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: RESEND_POINTS_NOTIFICATION_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function resendPointsNotificationApi({ employeeId }) {
  const url = `${urlMap.company}/company/graphql`

  const RESEND_POINTS_NOTIFICATION_QUERY = gql`
    query($employeeId: ID!) {
      resendPointsEmail(employeeId: $employeeId)
    }
  `

  const query = print(RESEND_POINTS_NOTIFICATION_QUERY)
  const variables = { employeeId }

  const response = await request.graphql(url, { query, variables })

  return response.data['resendPointsNotification']
}

function* payWithBalanceFlow(action) {
  try {
    const payload = yield call(payWithBalanceApi, action.payload)
    yield put({ type: PAY_WITH_BALANCE_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: PAY_WITH_BALANCE_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function payWithBalanceApi({ companyDepositIds }) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const PAY_WITH_BALANCE_QUERY = gql`
    mutation($companyDepositIds:[ID!] $companyId:ID!) {
      payWithBalance(companyDepositIds: $companyDepositIds companyId: $companyId)
    }
  `

  const query = print(PAY_WITH_BALANCE_QUERY)
  const variables = { companyDepositIds, companyId }

  const response = await request.graphql(url, { query, variables })

  return response.data.payWithBalance
}

function* updateAccessDateFlow({ payload }) {
  try {
    const response = yield call(updateAccessDateApi, payload)
    yield put({ type: UPDATE_ACCESS_DATE_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: UPDATE_ACCESS_DATE_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function updateAccessDateApi() {

  const { companyId, adminId } = getFromLS('clientData')

  const UPDATE_ACCESS_DATE_QUERY = gql`
    mutation($companyId: ID! $adminId: ID! ) {
      updateAccessDate(
        companyId: $companyId
        adminId: $adminId
      )
    }`
  const query = print(UPDATE_ACCESS_DATE_QUERY)
  const variables = {
    adminId,
    companyId,
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)
  return response
}

function* updatePatTermsFlow() {
  try {
    const response = yield call(updatePatTermsApi)
    yield put({ type: UPDATE_PAT_TERMS_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: UPDATE_PAT_TERMS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updatePatTermsApi() {
  const { companyId, adminId } = getFromLS('clientData')

  const UPDATE_PAT_TERMS_QUERY = gql`
    mutation($companyId: ID! $adminId: ID! ) {
      acceptPatTerms(
        companyId: $companyId
        adminId: $adminId
      )
    }`
  const query = print(UPDATE_PAT_TERMS_QUERY)
  const variables = {
    adminId,
    companyId,
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)
  return response
}

function* updateEmployeeFlow(action) {
  try {
    const payload = yield call(updateEmployeeApi, action.payload)
    yield put({ type: UPDATE_EMPLOYEE_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_EMPLOYEE_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateEmployeeApi({ employeeId, input }) {
  const { adminId, companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const benefitsCategoriesSchema = Object.keys(benefitsCategories)
    .map(type => (
      `${type} {
        amount
        active
        exclusiveAmount
        benefitType
        timeUnit
      }`
    ))
    .join(' ')
  const benefitsSchema = Object.keys(benefitsMap)
    .map(type => (
      `${type} {
        _id
        active
        amount
        benefitType
        benefitsCategories {
          ${benefitsCategoriesSchema}
        }
      }`
    ))
    .join(' ')
  const categoriesAmountsSchema = Object.keys(benefitsCategories)
    .map(category => `${category},`)
    .join(' ')
  const UPDATE_EMPLOYEE_QUERY = gql`
    mutation($employeeId: ID! $adminId: ID $input: EmployeeInputOnUpdate $companyId: ID!) {
      updateEmployee(employeeId: $employeeId, adminId: $adminId, input: $input, companyId: $companyId) {
        edges {
          node {
            _id
            nameAssociated
            emailAssociated
            externalId
            phoneDDDAssociated
            phoneNumberAssociated
            group
            notificationDate
            pointsNotificationDate
            benefits {
              ${benefitsSchema}
            }
            processStatus {
              actualStatus
              signingUpDate
              enablingDate
              pairingDate
              activeDate
            }
            deductions {
              amount
              month
              year
              categoriesAmounts {
                ${categoriesAmountsSchema}
              }
            }
          }
        }
      }
    }
  `

  const query = print(UPDATE_EMPLOYEE_QUERY)
  const variables = {
    employeeId,
    adminId,
    input,
    companyId
  }
  const response = await request.graphql(url, { query, variables })

  return response.data.updateEmployee
}

function* updateEmployeeTagsFlow(action) {
  try {
    const payload = yield call(updateEmployeeTagsApi, action.payload)
    yield put({ type: UPDATE_EMPLOYEE_TAGS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_EMPLOYEE_TAGS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateEmployeeTagsApi({ employeeId, update }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')

  const UPDATE_EMPLOYEE_TAGS_QUERY = gql`
    mutation(
      $companyId: ID!
      $employeeId: ID!
      $update: UpdateEmployeesTagsInput
    ) {
      updateEmployeeTags(
        companyId: $companyId
        employeeId: $employeeId
        update: $update
      )
    }
  `

  const query = print(UPDATE_EMPLOYEE_TAGS_QUERY)
  const variables = { companyId, employeeId, update }
  const options = {
    headers: { 'Content-Type': 'application/json' },
  }
  const response = await request.graphql(url, { query, variables }, options)
  return response.data['updateEmployeeTags']
}

function* updateMultipleEmployeesFlow(action) {
  try {
    const payload = yield call(updateMultipleEmployeesApi, action.payload)
    yield put({ type: UPDATE_MULTIPLE_EMPLOYEES_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_MULTIPLE_EMPLOYEES_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateMultipleEmployeesApi({ selectedEmployeesInput, input }) {
  const benefitsCategoriesSchema = Object.keys(benefitsCategories)
    .map(
      type =>
        `${type} {
      amount
      active
      exclusiveAmount
      exclusiveEnabled
      benefitType
      timeUnit
    },`,
    )
    .join(' ')
  const benefitsSchema = Object.keys(benefitsMap)
    .map(
      type =>
        `${type} {
      _id
      active
      amount
      benefitType
      benefitsCategories {
        ${benefitsCategoriesSchema}
      }
    }`,
    )
    .join(' ')
  const categoriesAmountsSchema = Object.keys(benefitsCategories)
    .map(category => `${category},`)
    .join(' ')

  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const UPDATE_MULT_EMPLOYEE_QUERY = gql`
    mutation($companyId: ID! $selectedEmployeesInput: SelectedEmployeesInput $input: EmployeeInputOnUpdate) {
      updateEmployee(companyId: $companyId, selectedEmployeesInput: $selectedEmployeesInput, input: $input) {
        edges {
          node {
            _id
            nameAssociated
            emailAssociated
            phoneDDDAssociated
            phoneNumberAssociated
            group
            notificationDate
            benefits {
              ${benefitsSchema}
            }
            processStatus {
              actualStatus
              signingUpDate
              enablingDate
              pairingDate
              activeDate
            }
            deductions {
              amount
              month
              year
              categoriesAmounts {
                ${categoriesAmountsSchema}
              }
            }
          }
        }
      }
    }
  `

  const query = print(UPDATE_MULT_EMPLOYEE_QUERY)
  const variables = {
    companyId,
    input,
    selectedEmployeesInput,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.updateEmployee
}

function* updateEmployeeBenefitFlow(action) {
  try {
    const {
      selectedEmployeesInput: { employeeId },
    } = action.payload
    const payload = yield call(updateEmployeeBenefitApi, action.payload)
    yield put({
      type: employeeId
        ? UPDATE_EMPLOYEE_BENEFIT_SUCCESS
        : UPDATE_ALL_EMPLOYEES_BENEFITS_SUCCESS,
      payload,
    })
  } catch (error) {
    yield put({
      type: UPDATE_EMPLOYEE_BENEFIT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateEmployeeBenefitApi({
  selectedEmployeesInput,
  benefitTypes,
  input,
}) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const benefitsCategoriesSchema = Object.keys(benefitsCategories)
    .map(
      type =>
        `${type} {
      amount
      active
      benefitType
      exclusiveAmount
      exclusiveEnabled
      timeUnit
    },`,
    )
    .join(' ')
  const UPDATE_EMPLOYEE_BENEFIT_QUERY = gql`
    mutation(
      $companyId: ID!
      $selectedEmployeesInput: SelectedEmployeesInput!
      $benefitTypes: [String]
      $input: EmployeeBenefitsCategoriesInput
    ) {
      updateEmployeeBenefit(
        companyId: $companyId
        selectedEmployeesInput: $selectedEmployeesInput
        benefitTypes: $benefitTypes
        input: $input
      ) {
        _id
        active
        amount
        benefitType
        employeeId
        benefitsCategories {
          ${benefitsCategoriesSchema}
        }
      }
    }
  `
  const query = print(UPDATE_EMPLOYEE_BENEFIT_QUERY)
  const variables = {
    companyId,
    selectedEmployeesInput,
    benefitTypes,
    input,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.updateEmployeeBenefit
}

function* updateGroupNameFlow(action) {
  try {
    const payload = yield call(updateGroupNameApi, action.payload)
    yield put({ type: UPDATE_GROUP_NAME_SUCCESS, payload })
    yield put({ type: UPDATE_GROUP_NAME_RESET })
  } catch (error) {
    yield put({
      type: UPDATE_GROUP_NAME_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateGroupNameApi({ group, input }) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const UPDATE_GROUP_NAME_QUERY = gql`
    mutation($companyId: ID!, $group: String!, $input: GroupNameInput) {
      updateGroupName(companyId: $companyId, group: $group, input: $input)
    }
  `

  const query = print(UPDATE_GROUP_NAME_QUERY)
  const variables = { companyId, group, input }

  const response = await request.graphql(url, { query, variables })

  return { group, name: response.data.updateGroupName }
}

function* upsertDeductionsFlow(action) {
  try {
    const payload = yield call(upsertDeductionsApi, action.payload)
    yield put({ type: UPSERT_DEDUCTIONS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPSERT_DEDUCTIONS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function upsertDeductionsApi({ deductionJson }) {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const categoriesAmountsSchema = Object.keys(benefitsCategories)
    .map(category => `${category},`)
    .join(' ')
  const UPSERT_DEDUCTIONS_QUERY = gql`
    mutation($companyId: ID!, $deductionJson: DeductionInput!) {
      upsertDeductions(companyId: $companyId deductionJson: $deductionJson) {
        month
        categoriesAmounts {
          ${categoriesAmountsSchema}
        }
        year
        employeeId
      }
    }
  `
  const query = print(UPSERT_DEDUCTIONS_QUERY)
  const variables = {
    companyId,
    deductionJson,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.upsertDeductions
}

function* updateCompanyFlow(action) {
  try {
    yield call(updateCompanyApi, action.payload)
    yield put({ type: UPDATE_COMPANY_SUCCESS })
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateCompanyApi({ companyId, input }) {
  const url = `${urlMap.company}/company/graphql`

  const UPDATE_COMPANY_QUERY = gql`
    mutation($companyId: ID!, $input: CompanyUpdate!) {
      updateCompany(companyId: $companyId, input: $input) {
        address {
          address
          addressNumber
          city
          complement
          neighborhood
          state
          zipCode
        }
        companyName
        cnpj
        financialEmails
        tradeName
      }
    }
  `
  const query = print(UPDATE_COMPANY_QUERY)
  const variables = { companyId, input }

  await request.graphql(url, { query, variables })

  return
}

function* updateCompanyAdminFlow(action) {
  try {
    const payload = yield call(updateCompanyAdminApi, action.payload)
    yield put({ type: UPDATE_COMPANY_ADMIN_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_ADMIN_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateCompanyAdminApi(steps) {
  const { adminId, companyId } = getFromLS('clientData')

  const url = `${urlMap.company}/company/graphql`

  const UPDATE_COMPANY_ADMIN_MUTATION = gql`
    mutation($adminId: ID!, $companyId: ID!, $updateAdminInput: UpdateAdminInput!){
      updateCompanyAdmin(adminId: $adminId companyId: $companyId updateAdminInput: $updateAdminInput) {
        _id
        steps {
          awardsGuidedOnboarding
          homeOfficeGuidedOnboarding
          skipEmployeesWoBenefits
          visitedCards
        }
      }
    }
  `

  const mutation = print(UPDATE_COMPANY_ADMIN_MUTATION)
  const variables = {
    adminId,
    companyId,
    updateAdminInput: {
      steps,
    },
  }

  const response = await request.graphql(url, { query: mutation, variables })

  return response.data.updateCompanyAdmin
}

function* updateCompanyTagsFlow(action) {
  try {
    const payload = yield call(updateCompanyTagsApi, action.payload)
    yield put({ type: UPDATE_COMPANY_TAGS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: UPDATE_COMPANY_TAGS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}
async function updateCompanyTagsApi({ companyTags, update }) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`
  const UPDATE_COMPANY_TAGS_MUTATION = gql`
    mutation(
      $companyId: ID!
      $companyTags: UpdateCompanyTagsObj
      $update: UpdateCompanyTagsObj
    ) {
      updateCompanyTags(
        companyId: $companyId
        companyTags: $companyTags
        update: $update
      )
    }
  `
  const mutation = print(UPDATE_COMPANY_TAGS_MUTATION)
  const variables = { companyId, companyTags, update }
  const options = { headers: { 'Content-Type': 'application/json' }}

  await request.graphql(url, { query: mutation, variables }, options)
  return 'ok'
}

export default function* rootSaga() {
  yield all([
    takeLatest(ADD_EMPLOYEES_REQUESTING, addEmployeesFlow),
    takeLatest(DELETE_EMPLOYEES_SHEET_REQUESTING, deleteEmployeesBySheet),
    takeLatest(CREATE_DEPOSITS_REQUESTING, createDepositsFlow),
    takeLatest(CHECK_ADD_SHEET_DATA_REQUESTING, checkAddSheetDataFlow),
    takeLatest(CHECK_DELETE_SHEET_DATA_REQUESTING ,checkDeleteSheetDataFlow),
    takeLatest(CHECK_SHEET_DATA_REQUESTING, checkSheetDataFlow),
    takeLatest(CLEAN_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING, cleanEmployeePointsConfigurationFlow),
    takeLatest(DELETE_EMPLOYEE_REQUESTING, deleteEmployeeFlow),
    takeLatest(
      DELETE_MULTIPLE_EMPLOYEES_REQUESTING,
      deleteMultipleEmployeesFlow,
    ),
    takeLatest(GENERATE_BILLET_REQUESTING, generateBilletFlow),
    takeLatest(GET_CHECKOUT_DATA_REQUESTING, getCheckoutDataFlow),
    takeLatest(GET_COMPANY_REQUESTING, getCompanyFlow),
    takeLatest(GET_COMPANY_ADMIN_REQUESTING, getCompanyAdminFlow),
    takeLatest(GET_SHOULD_RENDER_PAT_REQUESTING, getShouldRenderPatFlow),
    takeLatest(GET_COMPANY_GROUPS_REQUESTING, getCompanyGroupsFlow),
    takeLatest(GET_COMPANY_TAGS_REQUESTING, getCompanyTagsFlow),
    takeLatest(GET_COMPANY_VIRTUAL_BALANCE_REQUESTING, getCompanyVirtualBalanceFlow),
    takeLatest(GET_COMPANY_SHEETS_REQUESTING, getCompanySheet),
    takeLatest(GET_EMPLOYEES_REQUESTING, getEmployeesFlow),
    takeLatest(GET_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING, getEmployeePointsConfigurationFlow),
    takeLatest(UPDATE_EMPLOYEE_POLICY_REQUESTING, updateEmployeePolicyFlow),
    takeLatest(PAY_WITH_BALANCE_REQUESTING, payWithBalanceFlow),
    takeLatest(
      RESEND_WELCOME_NOTIFICATION_REQUESTING,
      resendWelcomeNotificationFlow,
    ),
    takeLatest(
      RESEND_POINTS_NOTIFICATION_REQUESTING,
      resendPointsNotificationFlow,
    ),
    takeLatest(SEND_EMPLOYEES_REPORT_REQUESTING, sendEmployeesReportFlow),
    takeLatest(
      SEND_SINGLE_EMPLOYEE_DOCUMENTS_REQUESTING,
      sendSingleEmployeeDocumentsFlow,
    ),
    takeLatest(SEND_EMPLOYEES_DOCUMENTS_REQUESTING, sendEmployeesDocumentsFlow),
    takeLatest(UPDATE_ACCESS_DATE_REQUESTING, updateAccessDateFlow),
    takeLatest(UPDATE_PAT_TERMS_REQUESTING, updatePatTermsFlow),
    takeLatest(UPDATE_GROUP_NAME_REQUESTING, updateGroupNameFlow),
    takeLatest(UPDATE_COMPANY_REQUESTING, updateCompanyFlow),
    takeLatest(UPDATE_COMPANY_ADMIN_REQUESTING, updateCompanyAdminFlow),
    takeLatest(UPDATE_COMPANY_TAGS_REQUESTING, updateCompanyTagsFlow),
    takeLatest(UPDATE_EMPLOYEE_REQUESTING, updateEmployeeFlow),
    takeLatest(UPDATE_EMPLOYEE_TAGS_REQUESTING, updateEmployeeTagsFlow),
    takeLatest(
      UPDATE_MULTIPLE_EMPLOYEES_REQUESTING,
      updateMultipleEmployeesFlow,
    ),
    takeLatest(UPDATE_EMPLOYEE_BENEFIT_REQUESTING, updateEmployeeBenefitFlow),
    takeLatest(UPSERT_DEDUCTIONS_REQUESTING, upsertDeductionsFlow),
  ])
}

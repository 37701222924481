export const CHECK_SSO_REQUESTING = 'CHECK_SSO_REQUESTING'
export const CHECK_SSO_SUCCESSFUL = 'CHECK_SSO_SUCCESSFUL'
export const CHECK_SSO_ERROR = 'CHECK_SSO_ERROR'

export const LOGIN_REQUESTING = 'LOGIN_REQUESTING'
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT_REQUESTING = 'LOGOUT_REQUESTING'
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const RECAPTCHA_SAVE_TOKEN = 'RECAPTCHA_SAVE_TOKEN'

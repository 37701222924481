export const ADD_EMPLOYEES_REQUESTING = 'ADD_EMPLOYEES2_REQUESTING'
export const ADD_EMPLOYEES_SUCCESS = 'ADD_EMPLOYEES2_SUCCESS'
export const ADD_EMPLOYEES_ERROR = 'ADD_EMPLOYEES2_ERROR'

export const CREATE_DEPOSITS_REQUESTING = 'CREATE_DEPOSITS_REQUESTING'
export const CREATE_DEPOSITS_SUCCESS = 'CREATE_DEPOSITS_SUCCESS'
export const CREATE_DEPOSITS_ERROR = 'CREATE_DEPOSITS_ERROR'

export const CHECK_ADD_SHEET_DATA_REQUESTING = 'CHECK_ADD_SHEET_DATA_REQUESTING'
export const CHECK_ADD_SHEET_DATA_SUCCESS = 'CHECK_ADD_SHEET_DATA_SUCCESS'
export const CHECK_ADD_SHEET_DATA_ERROR = 'CHECK_ADD_SHEET_DATA_ERROR'

export const CHECK_DELETE_SHEET_DATA_REQUESTING = 'CHECK_DELETE_SHEET_DATA_REQUESTING'
export const CHECK_DELETE_SHEET_DATA_SUCCESS = 'CHECK_DELETE_SHEET_DATA_SUCCESS'
export const CHECK_DELETE_SHEET_DATA_ERROR = 'CHECK_DELETE_SHEET_DATA_ERROR'

export const CHECK_SHEET_DATA_REQUESTING = 'CHECK_SHEET_DATA_REQUESTING'
export const CHECK_SHEET_DATA_SUCCESS = 'CHECK_SHEET_DATA_SUCCESS'
export const CHECK_SHEET_DATA_ERROR = 'CHECK_SHEET_DATA_ERROR'

export const CLEAN_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING = 'CLEAN_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING'
export const CLEAN_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS = 'CLEAN_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS'
export const CLEAN_EMPLOYEE_POINTS_CONFIGURATION_ERROR = 'CLEAN_EMPLOYEE_POINTS_CONFIGURATION_ERROR'

export const DELETE_EMPLOYEE_REQUESTING = 'DELETE_EMPLOYEE_REQUESTING'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_ERROR = 'DELETE_EMPLOYEE_ERROR'

export const DELETE_MULTIPLE_EMPLOYEES_REQUESTING = 'DELETE_MULTIPLE_EMPLOYEES_REQUESTING'
export const DELETE_MULTIPLE_EMPLOYEES_SUCCESS = 'DELETE_MULTIPLE_EMPLOYEES_SUCCESS'
export const DELETE_MULTIPLE_EMPLOYEES_ERROR = 'DELETE_MULTIPLE_EMPLOYEES_ERROR'

export const DELETE_EMPLOYEES_SHEET_REQUESTING = 'DELETE_EMPLOYEES_SHEET_REQUESTING'
export const DELETE_EMPLOYEES_SHEET_SUCCESS = 'DELETE_EMPLOYEES_SHEET_SUCCESS'
export const DELETE_EMPLOYEES_SHEET_ERROR = 'DELETE_EMPLOYEES_SHEET_ERROR'

export const GENERATE_BILLET_REQUESTING = 'GENERATE_BILLET_R EQUESTING'
export const GENERATE_BILLET_SUCCESS = 'GENERATE_BILLET_SUCCESS'
export const GENERATE_BILLET_ERROR = 'GENERATE_BILLET_ERROR'

export const GET_CHECKOUT_DATA_REQUESTING = 'GET_CHECKOUT_DATA_REQUESTING'
export const GET_CHECKOUT_DATA_SUCCESS = 'GET_CHECKOUT_DATA_SUCCESS'
export const GET_CHECKOUT_DATA_ERROR = 'GET_CHECKOUT_DATA_ERROR'

export const GET_COMPANY_REQUESTING = 'GET_COMPANY_REQUESTING'
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR'

export const GET_COMPANY_SHEETS_REQUESTING = 'GET_COMPANY_PAY_SHEETS_REQUESTING'
export const GET_COMPANY_SHEETS_SUCCESS = 'GET_COMPANY_PAY_SHEETS_SUCCESS'
export const GET_COMPANY_SHEETS_ERROR = 'GET_COMPANY_PAY_SHEETS_ERROR'

export const GET_COMPANY_ADMIN_REQUESTING = 'GET_COMPANY_ADMIN_REQUESTING'
export const GET_COMPANY_ADMIN_SUCCESS = 'GET_COMPANY_ADMIN_SUCCESS'
export const GET_COMPANY_ADMIN_ERROR = 'GET_COMPANY_ADMIN_ERROR'

export const GET_SHOULD_RENDER_PAT_REQUESTING = 'GET_SHOULD_RENDER_PAT_REQUESTING'
export const GET_SHOULD_RENDER_PAT_SUCCESS = 'GET_SHOULD_RENDER_PAT_SUCCESS'
export const GET_SHOULD_RENDER_PAT_ERROR = 'GET_SHOULD_RENDER_PAT_ERROR'

export const GET_COMPANY_GROUPS_REQUESTING = 'GET_COMPANY_GROUPS_REQUESTING'
export const GET_COMPANY_GROUPS_SUCCESS = 'GET_COMPANY_GROUPS_SUCCESS'
export const GET_COMPANY_GROUPS_ERROR = 'GET_COMPANY_GROUPS_ERROR'

export const GET_COMPANY_TAGS_REQUESTING = 'GET_COMPANY_TAGS_REQUESTING'
export const GET_COMPANY_TAGS_SUCCESS = 'GET_COMPANY_TAGS_SUCCESS'
export const GET_COMPANY_TAGS_ERROR = 'GET_COMPANY_TAGS_ERROR'

export const GET_COMPANY_VIRTUAL_BALANCE_REQUESTING = 'GET_COMPANY_VIRTUAL_BALANCE_REQUESTING'
export const GET_COMPANY_VIRTUAL_BALANCE_SUCCESS = 'GET_COMPANY_VIRTUAL_BALANCE_SUCCESS'
export const GET_COMPANY_VIRTUAL_BALANCE_ERROR = 'GET_COMPANY_VIRTUAL_BALANCE_ERROR'

export const GET_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING = 'GET_EMPLOYEE_POINTS_CONFIGURATION_REQUESTING'
export const GET_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS = 'GET_EMPLOYEE_POINTS_CONFIGURATION_SUCCESS'
export const GET_EMPLOYEE_POINTS_CONFIGURATION_ERROR = 'GET_EMPLOYEE_POINTS_CONFIGURATION_ERROR'
export const RESET_EMPLOYEE_POINTS_CONFIGURATION = 'RESET_EMPLOYEE_POINTS_CONFIGURATION'

export const GET_EMPLOYEES_REQUESTING = 'GET_EMPLOYEES_REQUESTING'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR'

export const UPDATE_EMPLOYEE_POLICY_REQUESTING = 'UPDATE_EMPLOYEE_POLICY_REQUESTING'
export const UPDATE_EMPLOYEE_POLICY_SUCCESS = 'UPDATE_EMPLOYEE_POLICY_SUCCESS'
export const UPDATE_EMPLOYEE_POLICY_ERROR = 'UPDATE_EMPLOYEE_POLICY_ERROR'

export const RESEND_WELCOME_NOTIFICATION_REQUESTING = 'RESEND_WELCOME_NOTIFICATION_REQUESTING'
export const RESEND_WELCOME_NOTIFICATION_SUCCESS = 'RESEND_WELCOME_NOTIFICATION_SUCCESS'
export const RESEND_WELCOME_NOTIFICATION_ERROR = 'RESEND_WELCOME_NOTIFICATION_ERROR'


export const RESEND_POINTS_NOTIFICATION_REQUESTING = 'RESEND_POINTS_NOTIFICATION_REQUESTING'
export const RESEND_POINTS_NOTIFICATION_SUCCESS = 'RESEND_POINTS_NOTIFICATION_SUCCESS'
export const RESEND_POINTS_NOTIFICATION_ERROR = 'RESEND_POINTS_NOTIFICATION_ERROR'

export const SEND_EMPLOYEES_REPORT_REQUESTING = 'SEND_EMPLOYEES_REPORT_REQUESTING'
export const SEND_EMPLOYEES_REPORT_SUCCESS = 'SEND_EMPLOYEES_REPORT_SUCCESS'
export const SEND_EMPLOYEES_REPORT_ERROR = 'SEND_EMPLOYEES_REPORT_ERROR'

export const SEND_SINGLE_EMPLOYEE_DOCUMENTS_REQUESTING = 'SEND_SINGLE_EMPLOYEE_DOCUMENTS_REQUESTING'
export const SEND_SINGLE_EMPLOYEE_DOCUMENTS_SUCCESS = 'SEND_SINGLE_EMPLOYEE_DOCUMENTS_SUCCESS'
export const SEND_SINGLE_EMPLOYEE_DOCUMENTS_ERROR = 'SEND_SINGLE_EMPLOYEE_DOCUMENTS_ERROR'

export const SEND_EMPLOYEES_DOCUMENTS_REQUESTING = 'SEND_EMPLOYEES_DOCUMENTS_REQUESTING'
export const SEND_EMPLOYEES_DOCUMENTS_SUCCESS = 'SEND_EMPLOYEES_DOCUMENTS_SUCCESS'
export const SEND_EMPLOYEES_DOCUMENTS_ERROR = 'SEND_EMPLOYEES_DOCUMENTS_ERROR'

export const PAY_WITH_BALANCE_REQUESTING = 'PAY_WITH_BALANCE_REQUESTING'
export const PAY_WITH_BALANCE_SUCCESS = 'PAY_WITH_BALANCE_SUCCESS'
export const PAY_WITH_BALANCE_ERROR = 'PAY_WITH_BALANCE_ERROR'

export const UPDATE_ACCESS_DATE_REQUESTING = 'UPDATE_ACCESS_DATE_REQUESTING'
export const UPDATE_ACCESS_DATE_SUCCESS = 'UPDATE_ACCESS_DATE_SUCCESS'
export const UPDATE_ACCESS_DATE_ERROR = 'UPDATE_ACCESS_DATE_ERROR'

export const UPDATE_PAT_TERMS_REQUESTING = 'UPDATE_PAT_TERMS_REQUESTING'
export const UPDATE_PAT_TERMS_SUCCESS = 'UPDATE_PAT_TERMS_SUCCESS'
export const UPDATE_PAT_TERMS_ERROR = 'UPDATE_PAT_TERMS_ERROR'

export const UPDATE_EMPLOYEE_REQUESTING = 'UPDATE_EMPLOYEE_REQUESTING'
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS'
export const UPDATE_EMPLOYEE_ERROR = 'UPDATE_EMPLOYEE_ERROR'

export const UPDATE_EMPLOYEE_TAGS_REQUESTING = 'UPDATE_EMPLOYEE_TAGS_REQUESTING'
export const UPDATE_EMPLOYEE_TAGS_SUCCESS = 'UPDATE_EMPLOYEE_TAGS_SUCCESS'
export const UPDATE_EMPLOYEE_TAGS_ERROR = 'UPDATE_EMPLOYEE_TAGS_ERROR'

export const UPDATE_MULTIPLE_EMPLOYEES_REQUESTING = 'UPDATE_MULTIPLE_EMPLOYEES_REQUESTING'
export const UPDATE_MULTIPLE_EMPLOYEES_SUCCESS = 'UPDATE_MULTIPLE_EMPLOYEES_SUCCESS'
export const UPDATE_MULTIPLE_EMPLOYEES_ERROR = 'UPDATE_MULTIPLE_EMPLOYEES_ERROR'

export const UPDATE_EMPLOYEE_BENEFIT_REQUESTING = 'UPDATE_EMPLOYEE_BENEFIT_REQUESTING'
export const UPDATE_EMPLOYEE_BENEFIT_SUCCESS = 'UPDATE_EMPLOYEE_BENEFIT_SUCCESS'
export const UPDATE_EMPLOYEE_BENEFIT_ERROR = 'UPDATE_EMPLOYEE_BENEFIT_ERROR'
export const UPDATE_ALL_EMPLOYEES_BENEFITS_SUCCESS = 'UPDATE_ALL_EMPLOYEES_BENEFITS_SUCCESS'

export const UPDATE_GROUP_NAME_REQUESTING = 'UPDATE_GROUP_NAME_REQUESTING'
export const UPDATE_GROUP_NAME_SUCCESS = 'UPDATE_GROUP_NAME_SUCCESS'
export const UPDATE_GROUP_NAME_ERROR = 'UPDATE_GROUP_NAME_ERROR'
export const UPDATE_GROUP_NAME_RESET = 'UPDATE_GROUP_NAME_RESET'

export const UPSERT_DEDUCTIONS_REQUESTING = 'UPSERT_DEDUCTIONS_REQUESTING'
export const UPSERT_DEDUCTIONS_SUCCESS = 'UPSERT_DEDUCTIONS_SUCCESS'
export const UPSERT_DEDUCTIONS_ERROR = 'UPSERT_DEDUCTIONS_ERROR'

export const UPDATE_COMPANY_REQUESTING = 'UPDATE_COMPANY_REQUESTING'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR'

export const UPDATE_COMPANY_ADMIN_REQUESTING = 'UPDATE_COMPANY_ADMIN_REQUESTING'
export const UPDATE_COMPANY_ADMIN_SUCCESS = 'UPDATE_COMPANY_ADMIN_SUCCESS'
export const UPDATE_COMPANY_ADMIN_ERROR = 'UPDATE_COMPANY_ADMIN_ERROR'

export const UPDATE_COMPANY_TAGS_REQUESTING = 'UPDATE_COMPANY_TAGS_REQUESTING'
export const UPDATE_COMPANY_TAGS_SUCCESS = 'UPDATE_COMPANY_TAGS_SUCCESS'
export const UPDATE_COMPANY_TAGS_ERROR = 'UPDATE_COMPANY_TAGS_ERROR'

import {
  GET_COMPANY_API_KEY_ERROR,
  GET_COMPANY_API_KEY_REQUESTING,
  GET_COMPANY_API_KEY_SUCCESS,
  UPDATE_COMPANY_API_KEY_ERROR,
  UPDATE_COMPANY_API_KEY_REQUESTING,
  UPDATE_COMPANY_API_KEY_SUCCESS,
} from './constants'

const initialState = {
  getCompanyApiKeyRequesting: false,
  getCompanyApiKeySuccess: false,
  getCompanyApiKeyError: '',
  getCompanyApiKeyData: '',
  updateCompanyApiKeyRequesting: false,
  updateCompanyApiKeySuccess: false,
  updateCompanyApiKeyError: '',
  updateCompanyApiKeyData: '',
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_COMPANY_API_KEY_REQUESTING:
      return {
        ...state,
        getCompanyApiKeyRequesting: true,
        getCompanyApiKeySuccess: false,
        getCompanyApiKeyError: '',
      }
    case GET_COMPANY_API_KEY_SUCCESS:
      return {
        ...state,
        getCompanyApiKeyData: payload?.misc?.api,
        getCompanyApiKeyRequesting: false,
        getCompanyApiKeySuccess: true,
        getCompanyApiKeyError: '',
      }
    case GET_COMPANY_API_KEY_ERROR:
      return {
        ...state,
        getCompanyApiKeyRequesting: false,
        getCompanyApiKeySuccess: false,
        getCompanyApiKeyError: payload.error,
      }

    case UPDATE_COMPANY_API_KEY_REQUESTING:
      return {
        ...state,
        updateCompanyApiKeyRequesting: true,
        updateCompanyApiKeySuccess: false,
        updateCompanyApiKeyError: '',
      }
    case UPDATE_COMPANY_API_KEY_SUCCESS:
      return {
        ...state,
        updateCompanyApiKeyData: payload,
        updateCompanyApiKeyRequesting: false,
        updateCompanyApiKeySuccess: true,
        updateCompanyApiKeyError: '',
      }
    case UPDATE_COMPANY_API_KEY_ERROR:
      return {
        ...state,
        updateCompanyApiKeyRequesting: false,
        updateCompanyApiKeySuccess: false,
        updateCompanyApiKeyError: payload.error,
      }
    default:
      return state
  }
}

import gql from 'graphql-tag'

export const CompanyFragments = {
  benefitsCategoriesData: gql`
    fragment BenefitsCategoriesData on Company {
      awards {
        active
        timeUnit
        depositNames
      }
      meal {
        active
        timeUnit
        depositNames
      }
      grocery {
        active
        timeUnit
        depositNames
      }
      mealGrocery {
        active
        timeUnit
        depositNames
      }
      transport {
        active
        timeUnit
        depositNames
      }
      mobility {
        active
        timeUnit
        depositNames
      }
      health {
        active
        timeUnit
        depositNames
      }
      wellbeing {
        active
        timeUnit
        depositNames
      }
      education {
        active
        timeUnit
        depositNames
      }
      culture {
        active
        timeUnit
        depositNames
      }
    }
  `,
  benefitsData: gql`
    fragment BenefitsData on Company {
      flex {
        active
        timeUnit
        depositNames
      }
      life {
        active
        timeUnit
        depositNames
      }
      essentials {
        active
        timeUnit
        depositNames
      }
      go {
        active
        timeUnit
        depositNames
      }
      food {
        active
        timeUnit
        depositNames
      }
    }
  `
}

const benefitTemplateSchema = `
  benefitsCategories {
    name
    type
    image
  }
  depositCategories
  depositDescription
  description
  gradient
  image
  key
  name
  style {
    primaryColor
  }
  usagePolicy {
    categories
  }
  visible
  _id
`
const companyBenefitSchema = `
  depositCategories {
    description
    image
    key
    name
    timeUnit
  }
  description
  employeeDescription
  image
  key
  name
  gradient
  _id
  active
  invoiceDescription
  usagePolicy {
    cashOutMethods,
    categories,
  }
  usageInfo {
    title
    description
    type
    image
    allowedCategories {
      title
      description
      image
    }
    allowedPartners
  }
  timeUnit
  benefitTemplateId
`

const categorySchema = `
  description
  image
  type
  name
`

export const COMPANY_BENEFITS_QUERY = gql`
  query(
    $companyId: ID!
  ) {
    company(_id: $companyId) {
      companyBenefits {
        plasticExclusiveBalance {
          ${companyBenefitSchema}
        }
        plastic {
          ${companyBenefitSchema}
        }
        virtual {
          ${companyBenefitSchema}
        }
        expenseManagement {
          ${companyBenefitSchema}
        }
      }
    }
  }
`


export const BENEFITS_MOUNT_QUERY = gql`
query benefitsMount(
    $companyId: ID!
  ) {
    company(_id: $companyId) {
      _id
      activeCustomBenefits
      isFirstOrder
      employeesQty
      benefitsCategories {
        awards {
          active
          exclusiveEnabled
          timeUnit
        }
        meal {
          active
          exclusiveEnabled
          timeUnit
        }
        grocery {
          active
          exclusiveEnabled
          timeUnit
        }
        mealGrocery {
          active
          exclusiveEnabled
          timeUnit
        }
        transport {
          active
          exclusiveEnabled
          timeUnit
        }
        mobility {
          active
          exclusiveEnabled
          timeUnit
        }
        health {
          active
          exclusiveEnabled
          timeUnit
        }
        wellbeing {
          active
          exclusiveEnabled
          timeUnit
        }
        education {
          active
          exclusiveEnabled
          timeUnit
        }
        culture {
          active
          exclusiveEnabled
          timeUnit
        }
      }
      companySteps {
        companyConfig
        onboarding
      }
      featureConfig {
        awardsEnabled
        benefitsEnabled
        collectiveConventionEnabled
        customNotificationMessageEnabled
        flashPointsEnabled
        groceryExclusiveBalanceEnabled
        homeOfficeEnabled
        mealExclusiveBalanceEnabled
        mealGroceryEnabled
        mealGroceryExclusiveBalanceEnabled
        mobilityExclusiveBalanceEnabled
      }
      companyBenefits {
        plasticExclusiveBalance {
          ${companyBenefitSchema}
        }
        plastic {
          ${companyBenefitSchema}
        }
        virtual {
          ${companyBenefitSchema}
        }
        expenseManagement {
          ${companyBenefitSchema}
        }
      }
      companyMaps {
        categoriesMap {
          ${categorySchema}
        }
        depositCategoriesMap {
          ${categorySchema}
        }
      }
      companyTemplates(onlyDefaults: true) {
        plasticExclusiveBalance {
          ${benefitTemplateSchema}
        }
        plastic {
          ${benefitTemplateSchema}
        }
        virtual {
          ${benefitTemplateSchema}
        }
        expenseManagement {
          ${benefitTemplateSchema}
        }
      }
      flashPoints {
        customBenefits {
          _id
          complementaryDocuments {
            fileName
            size
            title
            url
          }
          acceptDependents
          deleted
          dependents {
            description
            key
            paymentMethods
          }
          description
          discounts {
            target 
            payroll {
              type
              percentage
            }
          }
          duration {
            dueDate
            period {
              timeUnit
              value
            }
          }
          edition
          key
          name
          paymentMethods
          requiredDocuments {
            adoptedChildren
            children
            holder
            others
            specialNeedsChildren
            spouse
            stableUnion
            stepchildren
          }
          requiredDocumentsDescription {
            adoptedChildren
            children
            holder
            others
            specialNeedsChildren
            spouse
            stableUnion
            stepchildren
          }
          subGroups {
            _id
            costType
            cost
            dependentCost
            externalKey
            key
            name
            targets
            discounts {
              target 
              payroll {
                type
                percentage
              }
              subsidy {
                percentage
              }
            }
          }
          type
        }
      }
    }
  }
`

export const EMPLOYEES_MOUNT_QUERY = gql`
  query(
    $query: CompanyAdminQuery
    $companyId: ID!
  ) {
    company(_id: $companyId) {
      _id
      activePolicies
      maxDepositAmount
      benefitsCategories {
        awards {
          active
          exclusiveEnabled
          timeUnit
        }
        meal {
          active
          exclusiveEnabled
          timeUnit
        }
        grocery {
          active
          exclusiveEnabled
          timeUnit
        }
        mealGrocery {
          active
          exclusiveEnabled
          timeUnit
        }
        transport {
          active
          exclusiveEnabled
          timeUnit
        }
        mobility {
          active
          exclusiveEnabled
          timeUnit
        }
        health {
          active
          exclusiveEnabled
          timeUnit
        }
        wellbeing {
          active
          exclusiveEnabled
          timeUnit
        }
        education {
          active
          exclusiveEnabled
          timeUnit
        }
        culture {
          active
          exclusiveEnabled
          timeUnit
        }
      }
      cnpj
      companyAdmins(query: $query, companyId: $companyId) {
        _id
        steps {
          awardsGuidedOnboarding
          homeOfficeGuidedOnboarding
          skipEmployeesWoBenefits
          depositsTour
        }
      }
      companyBenefits {
        expenseManagement {
          ${companyBenefitSchema}
        }
        plasticExclusiveBalance {
          ${companyBenefitSchema}
        }
        plastic {
          ${companyBenefitSchema}
        }
        virtual {
          ${companyBenefitSchema}
        }
      }
      companyMaps {
        categoriesMap {
          ${categorySchema}
        }
        depositCategoriesMap {
          ${categorySchema}
        }
      }
      companyName
      companySteps {
        companyConfig
        onboarding
      }
      companyTemplates(onlyDefaults: true) {
        expenseManagement {
          ${benefitTemplateSchema}
        }
        plasticExclusiveBalance {
          ${benefitTemplateSchema}
        }
        plastic {
          ${benefitTemplateSchema}
        }
        virtual {
          ${benefitTemplateSchema}
        }
      }
      employeesQty
      employeeTags {
        tags
        tagsWithPrefix {
          prefixId
          value
        }
      }
      featureConfig {
        awardsCustomDescriptionEnabled
        awardsEnabled
        benefitsEnabled
        collectiveConventionEnabled
        customNotificationMessageEnabled
        depositAdditionalInfoEnabled
        flashCashEnabled
        flashPointsEnabled
        groceryExclusiveBalanceEnabled
        homeOfficeEnabled
        mealExclusiveBalanceEnabled
        mealGroceryEnabled
        mealGroceryExclusiveBalanceEnabled
        mobilityExclusiveBalanceEnabled
        subOrdersEnabled
        topUpEnabled
        depositRefundEnabled
      }
      groups {
        count
        days
        employeesIds
        name
      }
      isFirstOrder
      isPAT
      prefixTags {
        _id
        value
      }
    }
    
  }
`

const benefitsCategoriesSchema = `
  awards {
    active
    amount
    exclusiveAmount
  }
  meal {
    active
    amount
    exclusiveAmount
  }
  grocery {
    active
    amount
    exclusiveAmount
  }
  mealGrocery {
    active
    amount
    exclusiveAmount
  }
  transport {
    active
    amount
    exclusiveAmount
  }
  mobility {
    active
    amount
    exclusiveAmount
  }
  health {
    active
    amount
    exclusiveAmount
  }
  wellbeing {
    active
    amount
    exclusiveAmount
  }
  education {
    active
    amount
    exclusiveAmount
  }
  culture {
    active
    amount
    exclusiveAmount
  }
`

const categoriesAmountsSchema = `
  awards
  meal
  grocery
  mealGrocery
  transport
  mobility
  health
  wellbeing
  education
  culture
`

export const GET_EMPLOYEES_QUERY = gql`
  query Company ($companyId: ID!, $filter: FilterObject, $skip: Int, $limit: Int) {
    company(_id: $companyId) {
      cnpj
      companyMaps {
        categoriesMap {
          description
          image
          type
          name
        }
        depositCategoriesMap {
          description
          image
          type
          name
        }
      }
      companyName
      employeeTags {
        tags
      }
      groups (filter: $filter) {
        name
        employees (skip: $skip, limit: $limit) {
          _id
          cpf
          deductions {
            month
            year
            categoriesAmounts {
              ${categoriesAmountsSchema}
            }
          }
          documentsDates
          email
          externalId
          flashPointsEnabled
          name
          notificationDate
          phone
          policy {
            name
            selectedCustomBenefits {
              key
            }
            status
          }
          processStatus {
            actualStatus
            signingUpDate
            enablingDate
            pairingDate
            activeDate
          }
          product {
            _id
            benefitsCategories {
              ${benefitsCategoriesSchema}
            }
            flashProduct
          }
          tags
          tagsWithPrefix {
            prefixId
            value
          }
        }
      }
      prefixTags {
        _id
        value
        tags
      }
    }
  }
`

export const GET_COMPANY_GROUPS_QUERY = gql`
  query Company ($companyId: ID!, $filter: FilterObject ) {
    company(_id: $companyId) {
      groups (filter: $filter){
        count
        days
        employeesIds
        name
      }
      totalEmployees
    }
  }
`

export const GET_BALANCES_QUERY = gql`
  query($companyId: ID!, $isExpenseManagement: Boolean) {
    getBalances(
      companyId: $companyId,
      isExpenseManagement: $isExpenseManagement
    ) {
      balances {
        plastic {
          balance
          balanceUpdatedAt
          incomingBalance
        }
        expenseManagement {
          balance
          balanceUpdatedAt
          incomingBalance
        }
        virtual {
          balance
          incomingBalance
        }
      }
      companyBalance
      incomingBalance
    }
  }
`

export const GET_BALANCE_QUERY = gql`
  query($companyId: ID!, $isExpenseManagement: Boolean, $isPlastic: Boolean) {
    getBalance(
      companyId: $companyId,
      isExpenseManagement: $isExpenseManagement
      isPlastic: $isPlastic
    ) {
      balance
    }
  }
`

export const GET_STATEMENT_QUERY = gql`
  query($companyId: ID!, $isExpenseManagement: Boolean, $limit: Int $skip: Int, $filter: StatementFilter!) {
    getStatement(
      companyId: $companyId,
      isExpenseManagement: $isExpenseManagement,
      limit: $limit
      skip: $skip
      filter: $filter
    ) {
      misc {
        postPaid {
          enabled
          paymentTerm
        }
      }
      statement {
        debitInvoiceLink
        invoiceLink
        reportKey
        reportLink
        type
        reportKey
        sheetS3Link
        status
        value
        dateTime
      }
      statementQty
    }
  }
`

export const SEND_EMPLOYEES_REPORT = gql`
  query Company ($companyId: ID! $adminId: ID! $employeesType: String!) {
    sendReport ( companyId: $companyId adminId: $adminId type: $employeesType)
  }
`
export const DELETE_SINGLE_EMPLOYEE_MUTATION = gql`
  mutation deleteEmployee (
      $adminId: ID!
      $companyId: ID!
      $employeeId: ID
    ) {
      deleteEmployee(
        adminId: $adminId
        companyId: $companyId
        employeeId: $employeeId
      ) {
        _id
      }
    }
`
export const CHECK_DATA_PROCESSING_QUERY = gql`
  query dataProcessing(
    $companyId: ID!
  ) {
    company(_id: $companyId) {
      dataProcessing {
        _id
        cnpj
        massRegister
        massPayment
        requestDate
        status
        step
      }
    }
  }
`

export const CHECK_ADMIN = gql`
  query($companyId: ID!, $query: CompanyAdminQuery) {
    companyAdmins(companyId: $companyId query: $query) {
      _id
      companyId
      cpf
      email
      name
      phone
    }
  }
`

import gql from 'graphql-tag'

export const ACKNOWLEDGE_ASYNC_ERROR_MUTATION = gql`
  mutation acknowledgeAsyncError(
    $companyId: ID!
  ) {
    acknowledgeAsyncError(
      companyId: $companyId
    )
  }
`
export const ADD_BALANCE_MUTATION = gql`
  mutation($input: AddBalanceInput!) {
    addBalance(input: $input)
  }
`

export const CANCEL_ASYNC_PROCESS = gql`
  mutation cancelAsyncProcess(
    $adminId: ID!
    $companyId: ID!
  ) {
    cancelAsyncProcess(
      adminId: $adminId
      companyId: $companyId
    )
  }
`

export const EDIT_COMPANY_BENEFIT = gql`
  mutation updateCompanyBenefits(
    $companyId: ID!
    $input: UpdateCompanyBenefitsInput!
  ) {
    updateCompanyBenefits(
      companyId: $companyId
      input: $input
    )
  }
`

export const UPDATE_COMPANY_BENEFIT_TIME_UNIT = gql`
  mutation updateCompanyBenefitTimeUnit(
    $companyId: ID!
    $input: UpdateCompanyBenefitTimeUnitInput!
  ) {
    updateCompanyBenefitTimeUnit(
      companyId: $companyId
      input: $input
    )
  }
`
export const SWITCH_COMPANY_BENEFIT = gql`
  mutation switchCompanyBenefits(
    $companyId: ID!
    $input: SwitchCompanyBenefitsInput!
  ) {
    switchCompanyBenefits(
      companyId: $companyId
      input: $input
    )
  }
`

export const UPDATE_FLEXIBLE_BENEFIT_CATEGORY = gql`
  mutation updateFlexibleBenefitCategory(
    $companyId: ID!
    $input: UpdateFlexibleBenefitCategoryInput!
  ) {
    updateFlexibleBenefitCategory(
      companyId: $companyId
      input: $input
    )
  }
`

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee (
    $companyId: ID!
    $employeeId: ID
    $input: EmployeeInputOnUpdate
    $selectedEmployeesInput: SelectedEmployeesInput
  ) {
    updateEmployee(
      companyId: $companyId
      employeeId: $employeeId
      input: $input
      selectedEmployeesInput: $selectedEmployeesInput
    ) {
      pageInfo {hasPreviousPage, hasNextPage}
      totalCount
      edges {node {_id}}
    }
  }
`

export const UPDATE_EMPLOYEE_BENEFIT = gql`
  mutation updateEmployeeBenefit (
    $companyId: ID! 
    $selectedEmployeesInput: SelectedEmployeesInput! 
    $benefitType: String! 
    $input: EmployeeBenefitsCategoriesInput
  ) {
    updateEmployeeBenefit(
      companyId: $companyId
      selectedEmployeesInput: $selectedEmployeesInput
      benefitType: $benefitType
      input: $input
    ) {
      _id
    }
  }
`

export const CREATE_COMPANY_BENEFITS_BY_COMPANY = gql`
  mutation createCompanyBenefits ($companyId: ID!) {
    createCompanyBenefits(companyId: $companyId) {
      plastic {
        name
      }
      virtual {
        name
      }
      expenseManagement {
        name
      }
      havePlastic
      haveVirtual
      haveExpenseManagement
    }
  }
`

import {
  GET_COMPANY_BENEFITS_REQUESTING,
  GET_COMPANY_BENEFITS_SUCCESS,
  GET_COMPANY_BENEFITS_ERROR,

  GET_POINTS_CUSTOM_BENEFITS_ERROR,
  GET_POINTS_CUSTOM_BENEFITS_REQUESTING,
  GET_POINTS_CUSTOM_BENEFITS_SUCCESS,

  GET_GRAPH_INFO_ERROR,
  GET_GRAPH_INFO_REQUESTING,
  GET_GRAPH_INFO_SUCCESS,

  GET_MY_REPORTS_ERROR,
  GET_MY_REPORTS_REQUESTING,
  GET_MY_REPORTS_SUCCESS,

  SEND_REPORT_ERROR,
  SEND_REPORT_REQUESTING,
  SEND_REPORT_SUCCESS,
} from './constants'

const initState = {
  benefitsRequesting: false,
  benefitsSuccessful: false,
  benefitsData: {},
  graphInfo: '',
  myReports: [],
  reportResponse: ''
}

export default (state = initState, { type, payload }) => {
  switch (type) {
    case GET_COMPANY_BENEFITS_REQUESTING:
      return {
        ...state,
        benefitsRequesting: true,
        error: false,
      }

    case GET_COMPANY_BENEFITS_SUCCESS:
      return {
        ...state,
        benefitsRequesting: false,
        benefitsSuccessful: true,
        benefitsData: payload,
        error: false,
      }

    case GET_COMPANY_BENEFITS_ERROR:
      return {
        ...state,
        benefitsRequesting: false,
        benefitsSuccessful: false,
        error: payload.error,
      }

    case GET_POINTS_CUSTOM_BENEFITS_REQUESTING:
      return {
        ...state,
        pointsCustomBenefitsRequesting: true,
        pointsCustomBenefitsError: false,
      }

    case GET_POINTS_CUSTOM_BENEFITS_SUCCESS:
      return {
        ...state,
        pointsCustomBenefitsRequesting: false,
        pointsCustomBenefitsSuccessful: true,
        pointsCustomBenefitsData: payload,
        pointsCustomBenefitsError: false,
      }

    case GET_POINTS_CUSTOM_BENEFITS_ERROR:
      return {
        ...state,
        pointsCustomBenefitsRequesting: false,
        pointsCustomBenefitsSuccessful: false,
        pointsCustomBenefitsError: payload.error,
      }

    case GET_GRAPH_INFO_REQUESTING:
      return {
        ...state,
        graphInfoRequesting: true,
        graphInfoSuccess: false,
        graphInfoError: false,
      }
    case GET_GRAPH_INFO_SUCCESS:
      return {
        ...state,
        graphInfo: payload.data.getGraphInfo,
        graphInfoRequesting: false,
        graphInfoSuccess: true,
        graphInfoError: false,
      }
    case GET_GRAPH_INFO_ERROR:
      return {
        ...state,
        graphInfoRequesting: false,
        graphInfoSuccess: false,
        graphInfoError: payload.error,
      }

    case GET_MY_REPORTS_REQUESTING:
      return {
        ...state,
        myReportsRequesting: true,
        myReportsSuccess: false,
        myReportsError: false,
      }
    case GET_MY_REPORTS_SUCCESS:
      return {
        ...state,
        myReports: payload.data.getMyReports,
        myReportsRequesting: false,
        myReportsSuccess: true,
        myReportsError: false,
      }
    case GET_MY_REPORTS_ERROR:
      return {
        ...state,
        myReportsRequesting: false,
        myReportsSuccess: false,
        myReportsError: payload.error,
      }

    case SEND_REPORT_REQUESTING:
      return {
        ...state,
        reportRequesting: true,
        reportSuccess: false,
        reportError: false,
      }
    case SEND_REPORT_SUCCESS:
      return {
        ...state,
        reportRequesting: false,
        reportSuccess: true,
        reportError: false,
        reportResponse: payload.data.sendReportV2
      }
    case SEND_REPORT_ERROR:
      return {
        ...state,
        reportRequesting: false,
        reportSuccess: false,
        reportError: payload.error,
      }
    default:
      return state
  }
}

import { print } from 'graphql'
import gql from 'graphql-tag'
import _unset from 'lodash/unset'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../services/api'
import { getFromLS } from '../../services/security'
import { deepClone } from '../../services/utils'
import {
  ADD_USER_REQUESTING,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  CHECK_CPF_ERROR,
  CHECK_CPF_REQUESTING,
  CHECK_CPF_SUCCESS,
  DELETE_USERS_ERROR,
  DELETE_USERS_REQUESTING,
  DELETE_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_REQUESTING,
  GET_USERS_SUCCESS,
  GET_USER_PERMISSIONS_ERROR,
  GET_USER_PERMISSIONS_REQUESTING,
  GET_USER_PERMISSIONS_SUCCESS,
  SEND_ADMIN_EMAIL_TOKEN_ERROR,
  SEND_ADMIN_EMAIL_TOKEN_REQUESTING,
  SEND_ADMIN_EMAIL_TOKEN_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_PERMISSIONS_ERROR,
  UPDATE_USER_PERMISSIONS_REQUESTING,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  UPDATE_USER_REQUESTING,
  UPDATE_USER_STEEPS_ERROR,
  UPDATE_USER_STEEPS_REQUESTING,
  UPDATE_USER_STEEPS_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USERS_PERMISSION_REQUESTING,
  UPDATE_USERS_PERMISSION_SUCCESS,
  UPDATE_USERS_PERMISSION_ERROR,
  VALIDATE_ADMIN_TOKEN_ERROR,
  VALIDATE_ADMIN_TOKEN_REQUESTING,
  VALIDATE_ADMIN_TOKEN_SUCCESS,
} from './constants'
import { viewOnlyPermissions } from './permissionsHelper'

function* addUserFlow({ payload }) {
  try {
    const res = yield call(addUserApi, payload)
    yield put({ type: ADD_USER_SUCCESS, payload: { data: res }})
  } catch (error) {
    console.error({ error })
    yield put({ type: ADD_USER_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function addUserApi({ companyId, cpf, email, name, permissions: rawPermissions, phone }) {
  const permissions = deepClone(rawPermissions)
  viewOnlyPermissions.forEach((key) => _unset(permissions, key))

  const CREATE_ADMIN_QUERY = gql`
    mutation ($companyId: ID!, $createAdminInput: CreateAdminInput!) {
      createCompanyAdmin(companyId: $companyId, createAdminInput: $createAdminInput) {
        _id
      }
    }
  `
  const query = print(CREATE_ADMIN_QUERY)
  const variables = {
    companyId,
    createAdminInput: {
      cpf,
      email,
      name,
      permissions,
      phone,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)


  return response.data.createCompanyAdmin
}

function* checkCpfFlow({ payload }) {
  try {
    const response = yield call(checkCpfApi, payload)
    yield put({ type: CHECK_CPF_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: CHECK_CPF_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function checkCpfApi({ cpf }) {
  const { companyId } = getFromLS('clientData')

  const GET_COMPANY_ADMIN = gql`
    query ($companyId: ID!, $query: CompanyAdminQuery) {
      companyAdmins(companyId: $companyId, query: $query) {
        _id
        companyId
        cpf
        email
        name
        phone
      }
    }
  `

  const query = print(GET_COMPANY_ADMIN)
  const variables = {
    companyId,
    query: {
      cpf,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.companyAdmins
}

function* getUserPermissionsFlow({ payload }) {
  try {
    const response = yield call(getUserPermissionsApi, payload)
    yield put({ type: GET_USER_PERMISSIONS_SUCCESS, payload: { permissions: response }})
  } catch (error) {
    yield put({ type: GET_USER_PERMISSIONS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getUserPermissionsApi({ adminId, companyId }) {
  const GET_ADMIN_PERMISSIONS = gql`
    query ($adminId: ID, $companyId: ID) {
      companyAdminPermissions(adminId: $adminId, companyId: $companyId) {
        permissions {
          admins {
            adminPermissionsEdit
            adminRemove
            adminUpsert
          }
          benefits {
            benefitsEdit
          }
          billets {
            pageView
            reportView
            billetCancel
          }
          deposits {
            pageView
            reportView
            depositCancel
          }
          cardOrders {
            cardOrderCancel
            cardOrderCreate
            cardOrderDetailsView
            subOrdersReportView
          }
          company {
            companyRegister
            infoEdit
          }
          employees {
            awardsOrderView
            benefitsEdit
            cardsOrderView
            discountEdit
            employeesAdd
            employeesEdit
            employeesRemove
            homeOfficeOrderView
            notificationResend
            orderBalanceCreate
            orderCreate
          }
          flashCash {
            balanceAdd
            pageView
          }
          reports {
            pageView
          }
        }
      }
    }
  `

  const query = print(GET_ADMIN_PERMISSIONS)
  const variables = {
    adminId,
    companyId,
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.companyAdminPermissions
}

function* getUsersFlow() {
  try {
    const users = yield call(getUsersApi)
    yield put({ type: GET_USERS_SUCCESS, payload: { data: users }})
  } catch (error) {
    yield put({ type: GET_USERS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getUsersApi() {
  const { companyId } = getFromLS('clientData')

  const GET_COMPANY_ADMIN = gql`
    query ($companyId: ID!, $query: CompanyAdminQuery) {
      companyAdmins(companyId: $companyId, query: $query) {
        _id
        cpf
        email
        name
        phone
      }
    }
  `

  const query = print(GET_COMPANY_ADMIN)
  const variables = {
    companyId,
    query: {
      companyId,
      deleted: false,
      superUser: false,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.companyAdmins
}

function* sendAdminEmailTokenFlow({ payload }) {
  try {
    const res = yield call(sendAdminEmailTokenApi, payload)
    yield put({ type: SEND_ADMIN_EMAIL_TOKEN_SUCCESS, payload: { data: res }})
  } catch (error) {
    yield put({ type: SEND_ADMIN_EMAIL_TOKEN_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function sendAdminEmailTokenApi({ adminId, companyId }) {
  const SEND_ADMIN_EMAIL_TOKEN = gql`
    mutation ($companyId: ID!) {
      sendAddAdminEmailToken(companyId: $companyId)
    }
  `
  const query = print(SEND_ADMIN_EMAIL_TOKEN)
  const variables = {
    adminId,
    companyId,
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)
  return response.data.sendAddAdminEmailToken
}

function* updateUserFlow({ payload }) {
  try {
    const res = yield call(updateUserApi, payload)
    yield put({ type: UPDATE_USER_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: UPDATE_USER_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateUserApi({ adminId, companyId, name, phone }) {
  const UPDATE_ADMIN_QUERY = gql`
    mutation ($adminId: ID!, $companyId: ID!, $updateAdminInput: UpdateAdminInput!) {
      updateCompanyAdmin(
        adminId: $adminId
        companyId: $companyId
        updateAdminInput: $updateAdminInput
      ) {
        _id
        cpf
        email
        name
        phone
      }
    }
  `
  const query = print(UPDATE_ADMIN_QUERY)
  const variables = {
    adminId,
    companyId,
    updateAdminInput: {
      name,
      phone,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.updateCompanyAdmin
}

function* updateUserPermissionsFlow({ payload }) {
  try {
    const res = yield call(updateUserPermissionsApi, payload)
    yield put({ type: UPDATE_USER_PERMISSIONS_SUCCESS, payload: { data: res }})
  } catch (error) {
    yield put({ type: UPDATE_USER_PERMISSIONS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateUserPermissionsApi({
  adminId,
  adminIds,
  companyId,
  permissions: rawPermissions,
}) {
  const permissions = deepClone(rawPermissions)
  viewOnlyPermissions.forEach((permission) => _unset(permissions, permission))

  const UPDATE_ADMIN_PERMISSIONS_QUERY = gql`
    mutation ($adminIds: [ID]!, $companyId: ID!, $input: CompanyAdminPermissionsInput!) {
      updateCompanyAdminPermissions(adminIds: $adminIds, companyId: $companyId, input: $input) {
        adminId
        permissions {
          admins {
            adminPermissionsEdit
            adminRemove
            adminUpsert
          }
          benefits {
            benefitsEdit
          }
          billets {
            pageView
            reportView
          }
          deposits {
            pageView
            reportView
          }
          cardOrders {
            cardOrderCancel
            cardOrderCreate
            cardOrderDetailsView
            subOrdersReportView
          }
          company {
            companyRegister
            infoEdit
          }
          employees {
            awardsOrderView
            benefitsEdit
            cardsOrderView
            discountEdit
            employeesAdd
            employeesEdit
            employeesRemove
            homeOfficeOrderView
            notificationResend
            orderBalanceCreate
            orderCreate
          }
          flashCash {
            balanceAdd
            pageView
          }
          reports {
            pageView
          }
        }
      }
    }
  `
  const query = print(UPDATE_ADMIN_PERMISSIONS_QUERY)
  const variables = {
    adminIds: adminId ? [adminId] : adminIds,
    companyId,
    input: {
      ...permissions,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.updateCompanyAdminPermissions
}

function* updateUsersPermissionFlow({ payload }) {
  try {
    const res = yield call(updateUsersPermissionApi, payload)
    yield put({ type: UPDATE_USERS_PERMISSION_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: UPDATE_USERS_PERMISSION_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateUsersPermissionApi({ adminIds, companyId, permissions: rawPermissions }) {
  const permissions = deepClone(rawPermissions)
  viewOnlyPermissions.forEach((permission) => _unset(permissions, permission))

  const UPDATE_ADMINS_PERMISSION_QUERY = gql`
    mutation ($adminIds: [ID]!, $companyId: ID!, $input: CompanyAdminPermissionsInput!) {
      updateCompanyAdminPermissions(adminIds: $adminIds, companyId: $companyId, input: $input) {
        companyId
      }
    }
  `
  const query = print(UPDATE_ADMINS_PERMISSION_QUERY)
  const variables = {
    adminIds,
    companyId,
    input: {
      ...permissions,
    },
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.updateCompanyAdminPermissions
}

function* updateUserStepsFlow({ payload }) {
  try {
    const res = yield call(updateUserStepsApi, payload)
    yield put({ type: UPDATE_USER_STEEPS_SUCCESS, payload: res })
  } catch (error) {
    yield put({
      type: UPDATE_USER_STEEPS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function updateUserStepsApi({ userSteps }) {
  const url = `${urlMap.company}/company/graphql`
  const { adminId, companyId } = getFromLS('clientData')

  const UPDATE_USER_STEPS_QUERY = gql`
    mutation ($adminId: ID!, $companyId: ID!, $userSteps: CompanyAdminSteps!) {
      updateAdminSteps(adminId: $adminId, companyId: $companyId, userSteps: $userSteps)
    }
  `
  const query = print(UPDATE_USER_STEPS_QUERY)
  const variables = {
    adminId,
    companyId,
    userSteps,
  }

  const response = await request.graphql(url, { query, variables })
  return response.data['updateAdminSteps']
}

function* validateAdminTokenFlow({ payload }) {
  try {
    const res = yield call(validateAdminTokenApi, payload)
    yield put({ type: VALIDATE_ADMIN_TOKEN_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: VALIDATE_ADMIN_TOKEN_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function validateAdminTokenApi({ adminId, companyId, token }) {
  const VALIDATE_ADMIN_TOKEN = gql`
    query ($adminId: ID!, $companyId: ID!, $token: String!) {
      validateAddAminToken(adminId: $adminId, companyId: $companyId, token: $token)
    }
  `
  const query = print(VALIDATE_ADMIN_TOKEN)
  const variables = {
    adminId,
    companyId,
    token,
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)
  return response.data.validateAddAminToken
}

function* deleteUsersFlow({ payload }) {
  try {
    const res = yield call(deleteUserApi, payload)
    yield put({ type: DELETE_USERS_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: DELETE_USERS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function deleteUserApi({ adminId, adminIds }) {
  const { companyId } = getFromLS('clientData')
  const REMOVE_ADMINS_QUERY = gql`
    mutation ($adminId: ID!, $companyId: ID!, $adminIds: [ID]!) {
      deleteCompanyAdmins(adminId: $adminId, companyId: $companyId, adminIds: $adminIds) {
        _id
      }
    }
  `
  const query = print(REMOVE_ADMINS_QUERY)
  const variables = {
    adminId,
    companyId,
    adminIds,
  }
  const body = { query, variables }
  const response = await request.graphql(`${urlMap.company}/company/graphql`, body)

  return response.data.deleteCompanyAdmins
}

export default function* rootSaga() {
  yield all([
    takeLatest(ADD_USER_REQUESTING, addUserFlow),
    takeLatest(CHECK_CPF_REQUESTING, checkCpfFlow),
    takeLatest(GET_USER_PERMISSIONS_REQUESTING, getUserPermissionsFlow),
    takeLatest(GET_USERS_REQUESTING, getUsersFlow),
    takeLatest(SEND_ADMIN_EMAIL_TOKEN_REQUESTING, sendAdminEmailTokenFlow),
    takeLatest(UPDATE_USER_REQUESTING, updateUserFlow),
    takeLatest(UPDATE_USER_PERMISSIONS_REQUESTING, updateUserPermissionsFlow),
    takeLatest(UPDATE_USERS_PERMISSION_REQUESTING, updateUsersPermissionFlow),
    takeLatest(UPDATE_USER_STEEPS_REQUESTING, updateUserStepsFlow),
    takeLatest(DELETE_USERS_REQUESTING, deleteUsersFlow),
    takeLatest(VALIDATE_ADMIN_TOKEN_REQUESTING, validateAdminTokenFlow),
  ])
}

import {
  GET_BILLET_REPORT_LINK_REQUESTING,
  GET_BILLET_REPORT_LINK_SUCCESS,
  GET_BILLET_REPORT_LINK_ERROR,
  GET_COMPANY_BILLETS_REQUESTING,
  GET_COMPANY_BILLETS_SUCCESS,
  GET_COMPANY_BILLETS_ERROR,
  UPDATE_BILLET_REQUESTING,
  UPDATE_BILLET_SUCCESS,
  UPDATE_BILLET_ERROR,
  UPDATE_DEPOSIT_DATE_REQUESTING,
  UPDATE_DEPOSIT_DATE_SUCCESS,
  UPDATE_DEPOSIT_DATE_ERROR,
} from './constants'

const initialState = {
  billetReportLink: '',
  billetReportError: '',
  billetReportRequesting: false,
  billetReportSuccessful: false,
  updateDepositDateRequesting: false,
  updateDepositDateSuccessful: false,
  billets: [],
  totalBilletsCount: null,
  billetsError: '',
  billetsRequesting: false,
  billetsSuccessful: false,
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case GET_BILLET_REPORT_LINK_REQUESTING:
      return {
        ...state,
        billetReportRequesting: true,
        billetReportSuccessful: false,
      }
    case GET_BILLET_REPORT_LINK_SUCCESS:
      return {
        ...state,
        billetReportLink: payload,
        billetReportError: '',
        billetReportRequesting: false,
        billetReportSuccessful: true,
      }
    case GET_BILLET_REPORT_LINK_ERROR:
      return {
        ...state,
        billetReportError: payload.error,
        billetReportRequesting: false,
        billetReportSuccessful: false,
      }

    case GET_COMPANY_BILLETS_REQUESTING:
      return {
        ...state,
        billetsRequesting: true,
        error: false,
      }
    case GET_COMPANY_BILLETS_SUCCESS:
      return {
        ...state,
        billetsRequesting: false,
        billetsSuccessful: true,
        billets: payload.billets,
        totalBilletsCount: payload.totalBilletsCount,
        error: false,
      }
    case GET_COMPANY_BILLETS_ERROR:
      return {
        ...state,
        billetsRequesting: false,
        billetsSuccessful: false,
        error: payload.error,
      }

    case UPDATE_BILLET_REQUESTING:
      return {
        ...state,
        updateBilletRequesting: true,
        updateBilletSuccessful: false,
        error: false,
      }
    case UPDATE_BILLET_SUCCESS:
      return {
        ...state,
        updateBilletRequesting: false,
        updateBilletSuccessful: true,
        error: false,
      }
    case UPDATE_BILLET_ERROR:
      return {
        ...state,
        updateBilletRequesting: false,
        updateBilletSuccessful: false,
        error: payload.error,
      }

    case UPDATE_DEPOSIT_DATE_REQUESTING:
      return {
        ...state,
        updateDepositDateRequesting: true,
        updateDepositDateSuccessful: false,
        error: false,
      }
    case UPDATE_DEPOSIT_DATE_SUCCESS:
      return {
        ...state,
        updateDepositDateRequesting: false,
        updateDepositDateSuccessful: true,
        error: false,
      }
    case UPDATE_DEPOSIT_DATE_ERROR:
      return {
        ...state,
        updateDepositDateRequesting: false,
        updateDepositDateSuccessful: false,
        error: payload.error,
      }

    default:
      return state
  }
}

import {
  GET_S3_SIGNED_URL_REQUESTING,
  GET_S3_SIGNED_URL_SUCCESS,
  GET_S3_SIGNED_URL_ERROR,
  RESIZE
} from './constants'

const initialState = {
  isMobile: window.innerWidth <= 425
}

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case RESIZE:
      return {
        ...state,
        isMobile: payload
      }
    default:
      return state
  }
}

const getUrlInitialState = {
  getUrlRequesting: false,
  getUrlSuccess: false,
  getUrlError: '',
  url: '',
}

export const getS3SignedUrlReducer = (state = getUrlInitialState, action) => {
  const { type, payload, error } = action
  switch (type) {
    case GET_S3_SIGNED_URL_REQUESTING:
      return {
        ...state,
        getUrlRequesting: true,
        getUrlSuccess: false,
      }
    case GET_S3_SIGNED_URL_SUCCESS:
      return {
        ...state,
        getUrlRequesting: false,
        getUrlSuccess: true,
        url: payload,
      }
    case GET_S3_SIGNED_URL_ERROR:
      return {
        ...state,
        getUrlRequesting: false,
        getUrlError: error,
        url: '',
      }
  
    default:
      return state
  }
}

import React, { memo } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import asyncComponent from '../../helpers/AsyncFunc'

function getAppRoutes(url) {
  return [
    ...companyRoutes(url),

    <Route
      key='admin-profile'
      path={`${url}/adminProfile`}
      component={asyncComponent(() => import('../AdminProfile'))}
    />,
    <Route
      key='company-profile'
      path={`${url}/corporateProfile`}
      component={asyncComponent(() => import('../CorporateProfile'))}
    />,
    <Route
      key='file-support'
      path={`${url}/fileSupport`}
      component={asyncComponent(() => import('../FileSupport'))}
    />,
    <Route
      key='manage-users'
      path={`${url}/users`}
      component={asyncComponent(() => import('../ManageUsers'))}
    />,

    // ALWAYS THE LAST ONE
    <Route
      key='company-payments'
      path='/*'
      component={() => <Redirect to='/' />}
    />,
  ]
}

function companyRoutes(url) {
  return routersMap.company.map(({ key, routerPath, component }) => (
    <Route
      key={key}
      path={`${url}/${routerPath}`}
      component={component}
    />
  ))
}

export const routersMap = {
  company: [
    {
      key: 'company-balance',
      to: 'balance',
      path: 'balance',
      text: 'Flash Cash',
      routerPath: 'company/balance',
      component: asyncComponent(() => import('../Company/Balance'))
    },
    {
      key: 'company-benefits',
      to: 'benefits',
      path: 'benefits',
      text: 'Benefícios',
      routerPath: 'company/benefits',
      component: asyncComponent(() => import('../Company/Benefits'))
    },
    {
      key: 'company-cards',
      to: 'cards',
      path: 'cards',
      text: 'Cartões',
      routerPath: 'company/cards',
      component: asyncComponent(() => import('../Company/Cards'))
    },
    {
      key: 'company-deposits',
      to: 'deposits',
      path: 'deposits',
      text: 'Depósitos',
      routerPath: 'company/deposits',
      component: asyncComponent(() => import('../Company/Deposits'))
    },
    {
      key: 'company-employees',
      to: 'employees',
      path: 'employees',
      text: 'Colaboradores',
      routerPath: 'company/employees',
      component: asyncComponent(() => import('../Company/Employees'))
    },
    {
      key: 'company-expenseManagement',
      to: 'expenseManagement',
      path: 'expenseManagement',
      text: 'Despesas Corporativas',
      routerPath: 'company/expenseManagement',
      component: asyncComponent(() => import('../Company/ExpenseManagement'))
    },
    {
      key: 'api',
      to: 'api',
      path: 'api',
      text: 'Acesso Programático (API)',
      routerPath: 'company/api',
      component: asyncComponent(() => import('../Company/ApiManagement'))
    },
    {
      key: 'company-payments',
      to: 'payments',
      path: 'payments',
      text: 'Boletos',
      routerPath: 'company/payments',
      component: asyncComponent(() => import('../Company/Payments'))
    },
    {
      key: 'company-points',
      to: 'points',
      path: 'points',
      text: 'Pontos',
      routerPath: 'company/points',
      component: asyncComponent(() => import('../Company/PointsPolicies'))
    },
    {
      key: 'company-register',
      to: 'register',
      path: 'register',
      text: 'Adicionar empresa',
      routerPath: 'company/register',
      component: asyncComponent(() => import('../Signup'))
    },
    {
      key: 'company-custom-benefits',
      to: 'custom-benefits',
      path: 'custom-benefits',
      text: 'Benefícios customizados',
      routerPath: 'company/custom-benefits',
      component: asyncComponent(() => import('../Points/CustomBenefits'))
    },
    {
      key: 'company-points-employees',
      to: 'employees',
      path: 'employees',
      text: 'Colaboradores',
      routerPath: 'points/employees',
      component: asyncComponent(() => import('../Points/Employees'))
    },
    {
      key: 'company-reports',
      to: 'reports',
      path: 'reports',
      text: 'Relatórios',
      routerPath: 'company/reports',
      component: asyncComponent(() => import('../Company/Reports'))
    },
    {
      key: 'company-central',
      to: 'central',
      path: 'central',
      text: '',
      routerPath: 'company/central',
      component: asyncComponent(() => import('../Company/Central'))
    },
    {
      key: 'marketplace',
      to: 'marketplace',
      path: 'marketplace',
      text: '',
      routerPath: 'company/marketplace',
      component: asyncComponent(() => import('../Company/Marketplace/container/MarketplaceHome'))
    },
    {
      key: 'fileSupport',
      to: 'fileSupport',
      path: 'fileSupport',
      text: '',
      routerPath: 'company/fileSupport',
      component: asyncComponent(() => import('../FileSupport'))
    },
  ]

}

const AppRouter = memo(({ url }) => <Switch>{getAppRoutes(url)}</Switch>)

AppRouter.displayName = 'AppRouter'

export default AppRouter

import { useState, useEffect } from 'react'
import { useBetween } from 'use-between'
import _uniqBy from 'lodash/uniqBy'
import { getBreakpoint } from '../../config/grid'
import { urls } from '@flash-tecnologia/flamingo-ui/src/config/env'
import { getFromLS, setInLS } from '../../services/security'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  const breakpoint = getBreakpoint(width)
  return {
    width,
    height,
    breakpoint
  }
}

const useDataProcessing = () => {
  const { dataProcessing } = getFromLS('clientData')
  const [dataProcessingState, setDataProcessingState] = useState(dataProcessing || [])

  function updateDataProcessingLS(data) {
    setInLS('clientData', {
      ...getFromLS('clientData'),
      dataProcessing: data
    })
    setDataProcessingState(data)
  }

  return [dataProcessingState, updateDataProcessingLS]
}

export const useSharedDataProcessing = () => useBetween(useDataProcessing)

export function parseCategoriesByBenefit(benefits, companyBenefitsCategories = {}) {
  const parseCategoriesByBenefits = (benefits) => {
    const categories = benefits?.reduce((array, benefit) => {
      if (benefit?.useDepositCategoryDescription) {
        const categories = benefit.depositCategories.map((depositCategory) => ({
          _id: benefit._id,
          key: depositCategory.key,
          name: depositCategory.name,
          cashOutMethods: benefit.usagePolicy.cashOutMethods,
          description: depositCategory.description,
          image: depositCategory.image,
          invoiceDescription: depositCategory?.invoiceDescription,
          timeUnit: depositCategory.timeUnit || companyBenefitsCategories[depositCategory.key]?.timeUnit || 'month',
        }))
        return [ ...array, ...categories]
      } else {
        return [
          ...array,
          {
            _id: benefit._id,
            key: benefit.key,
            name: benefit.name,
            cashOutMethods: benefit.usagePolicy?.cashOutMethods,
            description: benefit.description,
            image: benefit.image || `${urls.s3Emojis}/pencil.png`,
            invoiceDescription: benefit?.invoiceDescription,
            timeUnit: benefit.timeUnit,
            ...benefit,
          }
        ]
      }
    }, [])
    return _uniqBy(categories, 'key')
  }
  const [categories, setCategories] = useState(parseCategoriesByBenefits(benefits))
  useEffect(() => {
    setCategories(parseCategoriesByBenefits(benefits))
  }, [benefits])

  return categories
}

export function parseImagesByBenefits(benefits) {
  const parseImagesByBenefits = (benefits) => {
    const images = benefits?.reduce((array, benefit) => {
      if (benefit?.useDepositCategoryDescription) {
        const categories = benefit.depositCategories.map((depositCategory) => ({
          url: depositCategory.image,
          name: depositCategory.name,
        }))
        return [...array, ...categories]
      } else {
        return [
          ...array,
          {
            url: benefit.image || `${urls.s3Emojis}/pencil.png`,
            name: benefit.name,
          }
        ]
      }
    }, [])
    return _uniqBy(images, 'name')
  }
  const [images, setImages] = useState(parseImagesByBenefits(benefits))
  useEffect(() => {
    setImages(parseImagesByBenefits(benefits))
  }, [benefits])

  return images
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
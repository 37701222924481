import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  CANCEL_ORDER_ERROR,
  CANCEL_ORDER_REQUESTING,
  CANCEL_ORDER_SUCCESS,
  CONFIRM_DELIVERED_ORDER_ERROR,
  CONFIRM_DELIVERED_ORDER_REQUESTING,
  CONFIRM_DELIVERED_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_REQUESTING,
  CREATE_ORDER_SUCCESS,
  GET_CARDS_ERROR,
  GET_CARDS_REQUESTING,
  GET_CARDS_SUCCESS,
  GET_CARDS_BIG_NUMBERS_ERROR,
  GET_CARDS_BIG_NUMBERS_REQUESTING,
  GET_CARDS_BIG_NUMBERS_SUCCESS,
  GET_NEW_ORDERS_ERROR,
  GET_NEW_ORDERS_REQUESTING,
  GET_NEW_ORDERS_SUCCESS,
  GET_ORDER_DETAILS_ERROR,
  GET_ORDER_DETAILS_REQUESTING,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DELIVERIES_ERROR,
  GET_ORDER_DELIVERIES_REQUESTING,
  GET_ORDER_DELIVERIES_SUCCESS,
  NOTIFY_ORDER_NOT_RECEIVED_ERROR,
  NOTIFY_ORDER_NOT_RECEIVED_REQUESTING,
  NOTIFY_ORDER_NOT_RECEIVED_SUCCESS,
  POPULATE_ORDER_ERROR,
  POPULATE_ORDER_REQUESTING,
  POPULATE_ORDER_SUCCESS,
  GET_CARD_ORDER_CREATION_FORM_DATA_ERROR,
  GET_CARD_ORDER_CREATION_FORM_DATA_REQUESTING,
  GET_CARD_ORDER_CREATION_FORM_DATA_SUCCESS,
  SEND_REPORT_SUB_ORDERS_ERROR,
  SEND_REPORT_SUB_ORDERS_REQUESTING,
  SEND_REPORT_SUB_ORDERS_SUCCESS,
  SELECT_GROUP_CARD_MODEL_REQUESTING,
  SELECT_GROUP_CARD_MODEL_SUCCESS,
  SELECT_GROUP_CARD_MODEL_ERROR,
} from './constants'


function* cancelOrderFlow({ payload }) {
  try {
    yield call(cancelOrderApi, payload)
    yield put({ type: CANCEL_ORDER_SUCCESS })
  } catch (error) {
    yield put({ type: CANCEL_ORDER_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function cancelOrderApi({ orderId }) {
  const { companyId } = getFromLS('clientData')

  const CANCEL_ORDER_QUERY = gql`
    mutation($companyId: ID! $orderId: ID!) {
      cancelOrder(
        companyId: $companyId
        orderId: $orderId
      )
    }
  `
  const query = print(CANCEL_ORDER_QUERY)
  const variables = { companyId, orderId }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.cancelOrder
}

function* createOrderFlow({ payload }) {
  try {
    yield call(createOrderApi, payload)
    yield put({ type: CREATE_ORDER_SUCCESS })
  } catch (error) {
    yield put({ type: CREATE_ORDER_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function createOrderApi(input) {
  const resolver = 'createCompanyCardOrder'
  const { adminId, companyId } = getFromLS('clientData')
  const CREATE_ORDER = gql`
    mutation($adminId: ID!, $companyId: ID!, $input: CreateOrderInput!) {
      ${resolver}(
        adminId: $adminId
        companyId: $companyId
        input: $input
      )
    }
  `
  const url = `${urlMap.company}/company/graphql`
  const query = print(CREATE_ORDER)
  const variables = {
    adminId,
    companyId,
    input,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data[resolver]
}

function* getCardsFlow({ payload }) {
  try {
    const response = yield call(getCardsApi, payload)
    yield put({ type: GET_CARDS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_CARDS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCardsApi({ limit, skip, sortBy, filter, search }) {
  const { companyId } = getFromLS('clientData')
  const providers = getFromLS('cardProviders')
  const GET_CARDS_QUERY = gql`
    query($companyId: ID! $limit: Int $skip: Int $sortBy: SortInput $filter: FilterInput $search: SearchInput $providers: [String]) {
      getCardsV2(
        companyId: $companyId
        limit: $limit
        skip: $skip
        sortBy: $sortBy
        filter: $filter
        search: $search
        providers: $providers
      ) {
        cards {
          printedId
          status
          externalCardOrderId
          cpf
          name
        }
        totalCardsAmount
        filterCardsAmount
      }
    }
  `

  const query = print(GET_CARDS_QUERY)
  const variables = { companyId, limit, skip, sortBy, filter, search, providers }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.getCardsV2
}

function* getCardsBigNumbersFlow({ payload }) {
  try {
    const response = yield call(getCardsBigNumbersApi, payload)
    yield put({ type: GET_CARDS_BIG_NUMBERS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_CARDS_BIG_NUMBERS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCardsBigNumbersApi() {
  const { companyId } = getFromLS('clientData')
  const GET_CARDS_BIG_NUMBERS_QUERY = gql`
    query($companyId: ID!) {
      getCardsBigNumbers(
        companyId: $companyId
      ) {
        activeCardsAmount
        stockCardsAmount
        cardsInFlight
      }
    }
  `

  const query = print(GET_CARDS_BIG_NUMBERS_QUERY)
  const variables = { companyId }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.getCardsBigNumbers
}

function* getOrderDetailsFlow({ payload }) {
  try {
    const response = yield call(getOrderDetailsApi, payload)
    yield put({ type: GET_ORDER_DETAILS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ORDER_DETAILS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getOrderDetailsApi({ orderId }) {
  const { companyId } = getFromLS('clientData')

  const GET_ORDER_DETAILS_QUERY = gql`
    query($companyId: ID! $limit: Int $search: SearchInput) {
      getOrders(
        companyId: $companyId
        limit: $limit
        search: $search
      ) {
        orders {
          deliveryAddress {
            address
            addressNumber
            city
            complement
            neighborhood
            referencePoint
            state
            zipCode
          }
          recipientName
          recipientPhone
          subOrders {
            deliveryAddress {
              address
              addressNumber
              city
              complement
              neighborhood
              referencePoint
              state
              zipCode
            }
            recipientName
            status
            trackingCode
            trackingStatus
          }
        }
      }
    }
  `

  const query = print(GET_ORDER_DETAILS_QUERY)
  const variables = {
    companyId,
    limit: 1,
    search: {
      orderId,
    },
  }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.getOrders
}

function* getOrderDeliveriesFlow({ payload }) {
  try {
    const response = yield call(getOrderDeliveriesApi, payload)
    yield put({ type: GET_ORDER_DELIVERIES_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_ORDER_DELIVERIES_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getOrderDeliveriesApi({ externalCardOrderId }) {
  const { companyId } = getFromLS('clientData')

  const GET_ORDER_DELIVERIES_QUERY = gql`
    query($companyId: ID! $externalCardOrderId: String!) {
      getOrderDeliveries(
        companyId: $companyId
        externalCardOrderId: $externalCardOrderId
      ) {
        deliveries {
          recipientName
          status
          cardAmount
          deliveryAddress {
            address
            addressNumber
            city
            complement
            neighborhood
            referencePoint
            state
            zipCode
          }
          trackingCode
          externalCardDeliveryId
        }
      }
    }
  `

  const query = print(GET_ORDER_DELIVERIES_QUERY)
  const variables = {
    companyId,
    externalCardOrderId,
  }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.getOrderDeliveries.deliveries
}

function* getNewOrdersFlow({ payload }) {
  try {
    const response = yield call(getNewOrdersApi, payload)
    yield put({ type: GET_NEW_ORDERS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_NEW_ORDERS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getNewOrdersApi({ limit, skip, sortBy, filter, search }) {
  const { companyId } = getFromLS('clientData')
  const providers = getFromLS('cardProviders')

  const GET_ORDERS_QUERY = gql`
    query($companyId: ID! $limit: Int $skip: Int $sortBy: SortInput $filter: FilterInput $search: SearchInput $providers: [String]) {
      getOrdersV2(
        companyId: $companyId
        limit: $limit
        skip: $skip
        sortBy: $sortBy
        filter: $filter
        search: $search
        providers: $providers
      ) {
        orders {
          _id
          externalCardOrderId
          status
          totalCardsAmount
          createdAt
        }
        totalOrdersAmount
      }
    }
  `

  const query = print(GET_ORDERS_QUERY)
  const variables = { companyId, limit, sortBy, filter, skip, search, providers }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.getOrdersV2
}

function* notifyOrderNotReceivedFlow({ payload }) {
  try {
    yield call(notifyOrderNotReceivedApi, payload)
    yield put({ type: NOTIFY_ORDER_NOT_RECEIVED_SUCCESS })
  } catch (error) {
    yield put({ type: NOTIFY_ORDER_NOT_RECEIVED_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function notifyOrderNotReceivedApi({ orderId }) {
  const { companyId } = getFromLS('clientData')

  const NOTIFY_ORDER_NOT_RECEIVED_QUERY = gql`
    query($companyId: ID! $orderId: ID!) {
      notifyOrderNotReceived(
        companyId: $companyId
        orderId: $orderId
      )
    }
  `
  const query = print(NOTIFY_ORDER_NOT_RECEIVED_QUERY)
  const variables = { companyId, orderId }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.notifyOrderNotReceived
}

function* confirmDeliveredOrderFlow({ payload }) {
  try {
    yield call(confirmDeliveredOrderApi, payload)
    yield put({ type: CONFIRM_DELIVERED_ORDER_SUCCESS })
  } catch (error) {
    yield put({ type: CONFIRM_DELIVERED_ORDER_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function confirmDeliveredOrderApi({ orderId }) {
  const { companyId } = getFromLS('clientData')

  const CONFIRM_DELIVERED_ORDER_QUERY = gql`
    mutation($companyId: ID! $orderId: ID!) {
      confirmDeliveredOrder(
        companyId: $companyId
        orderId: $orderId
      )
    }
  `
  const query = print(CONFIRM_DELIVERED_ORDER_QUERY)
  const variables = { companyId, orderId }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response.data.confirmDeliveredOrder
}

function* sendReportSubOrdersFlow({ payload }) {
  try {
    yield call(sendReportSubOrdersApi, payload)
    yield put({ type: SEND_REPORT_SUB_ORDERS_SUCCESS })
  } catch (error) {
    yield put({ type: SEND_REPORT_SUB_ORDERS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function sendReportSubOrdersApi() {
  const { companyId, adminId } = getFromLS('clientData')

  const SEND_REPORT_SUB_ORDERS_QUERY = gql`
    query($companyId: ID! $adminId: ID!) {
      sendReportSubOrders(
        companyId: $companyId
        adminId: $adminId
      )
    }
  `
  const query = print(SEND_REPORT_SUB_ORDERS_QUERY)
  const variables = { companyId, adminId }
  const url = `${urlMap.company}/company/graphql`

  const response = await request.graphql(url, { query, variables })

  return response
}

function* populateOrderFlow() {
  try {
    const response = yield call(populateOrderApi)
    yield put({ type: POPULATE_ORDER_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: POPULATE_ORDER_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function populateOrderApi() {
  const { adminId, companyId } = getFromLS('clientData')
  const query = `{
    getRecipientData(companyId: "${companyId}", adminId: "${adminId}") {
      address
      addressNumber
      cardOrderShortage {
        on
        suggestionNumberCards
      }
      city
      cnpj
      companyName
      complement
      firstOrder
      maxCardsAmount
      name
      neighborhood
      phoneNumber
      referencePoint
      state
      stockCardsAmount
      suggestionNumberCards
      zipCode
      employeesWithDepositWOCard
    }
  }`

  const url = `${urlMap.company}/company/graphql`

  const { data } = await request.graphql(url, { query })

  return data.getRecipientData
}

function* getCardOrderCreationFormDataFlow() {
  try {
    const response = yield call(getCardOrderCreationFormDataApi)
    yield put({ type: GET_CARD_ORDER_CREATION_FORM_DATA_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_CARD_ORDER_CREATION_FORM_DATA_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCardOrderCreationFormDataApi() {
  const { companyId } = getFromLS('clientData')
  const query = `{
    getCardOrderCreationFormDataV2(companyId: "${companyId}") {
      companyName
      cnpj
      maxCardsAmount
      stockCardsAmount
      employeesWithDepositWOCard
      firstOrder
      additionalCardsLimit
    }
  }`

  const url = `${urlMap.company}/company/graphql`

  const { data } = await request.graphql(url, { query })

  return data.getCardOrderCreationFormDataV2
}

function* selectGroupCardModelFlow({ payload }) {
  try {
    const response = yield call(selectGroupCardModelApi, payload)
    yield put({ type: SELECT_GROUP_CARD_MODEL_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: SELECT_GROUP_CARD_MODEL_ERROR, payload: { error: handleApiError(error) }})
  }

}

async function selectGroupCardModelApi() {
  const { companyId } = getFromLS('clientData')

  const query = `{
    selectGroupCardModel(companyId: "${companyId}") {
      cardLayout
      cardProviders
    }
  }`

  const url = `${urlMap.company}/company/graphql`

  const { data } = await request.graphql(url, { query })

  return data.selectGroupCardModel
}

export default function* rootSaga() {
  yield all([
    takeLatest(CANCEL_ORDER_REQUESTING, cancelOrderFlow),
    takeLatest(CONFIRM_DELIVERED_ORDER_REQUESTING, confirmDeliveredOrderFlow),
    takeLatest(CREATE_ORDER_REQUESTING, createOrderFlow),
    takeLatest(GET_CARDS_REQUESTING, getCardsFlow),
    takeLatest(GET_CARDS_BIG_NUMBERS_REQUESTING, getCardsBigNumbersFlow),
    takeLatest(GET_ORDER_DETAILS_REQUESTING, getOrderDetailsFlow),
    takeLatest(GET_ORDER_DELIVERIES_REQUESTING, getOrderDeliveriesFlow),
    takeLatest(GET_NEW_ORDERS_REQUESTING, getNewOrdersFlow),
    takeLatest(NOTIFY_ORDER_NOT_RECEIVED_REQUESTING, notifyOrderNotReceivedFlow),
    takeLatest(POPULATE_ORDER_REQUESTING, populateOrderFlow),
    takeLatest(GET_CARD_ORDER_CREATION_FORM_DATA_REQUESTING, getCardOrderCreationFormDataFlow),
    takeLatest(SEND_REPORT_SUB_ORDERS_REQUESTING, sendReportSubOrdersFlow),
    takeLatest(SELECT_GROUP_CARD_MODEL_REQUESTING, selectGroupCardModelFlow),
  ])
}

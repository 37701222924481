const column = 72
const margin = 8
const gutter = 8
const escape = 0
const gridBreakpoint = {
  'mobile': {
    width: '100%',
    nColumn: 4,
  },
  'S': {
    width: `${escape + 4 * (margin + column + gutter) + escape}px`,
    nColumn: 4,
  },
  'S1': {
    width: `${escape + 6 * (margin + column + gutter) + escape}px`,
    nColumn: 6,
  },
  'M': {
    width: `${escape + 8 * (margin + column + gutter) + escape}px`,
    nColumn: 8,
  },
  'M1': {
    width: `${escape + 10 * (margin + column + gutter) + escape}px`,
    nColumn: 10,
  },
  'L': {
    width: `${escape + 12 * (margin + column + gutter) + escape}px`,
    nColumn: 12,
  },
  'L1': {
    width: `${escape + 14 * (margin + column + gutter) + escape}px`,
    nColumn: 14,
  },
  'XL': {
    width: `${escape + 16 * (margin + column + gutter) + escape}px`,
    nColumn: 16,
  }
}

const getBreakpoint = (width) => {
  if (width <= 360) return 'mobile'
  if (width <= 752) return 'S'
  if (width <= 900) return 'S1'
  if (width <= 1104) return 'M'
  if (width <= 1254) return 'M1'
  if (width <= 1456) return 'L'
  if (width <= 1596) return 'L1'
  return 'XL'
}

const columnStyle = ({ breakpoint, nColumn, fillColumn, fillMargin, fillGutter, startColumn }) => {
  const relativePosition = {
    position: 'relative',
    left: startColumn ? startColumn * (margin + column + gutter) : 0,
  }
  if (breakpoint && fillColumn) {
    return {
      width: gridBreakpoint[breakpoint].width,
      marginLeft: 0,
      marginRight: 0,
    }
  }

  const nColumnBreakpoint = breakpoint && typeof nColumn === 'object' && nColumn[breakpoint]
  const N = nColumnBreakpoint || nColumn
  const width = `${N * column + (N - (1 + fillMargin)) * margin + (N - (1 + fillGutter)) * gutter}px`
  const marginLeft = fillMargin ? '0px' : `${margin}px`
  const marginRight = fillGutter ? '0px' : `${gutter}px`

  return {
    width,
    marginLeft,
    marginRight,
    ...(startColumn && relativePosition)
  }
}

export { gridBreakpoint, column, margin, gutter, escape, getBreakpoint, columnStyle }

import {
  GET_BENEFIT_DISCOVERY_LIST,
  CREATE_BENEFIT_DISCOVERY,
  UPDATE_BENEFIT_DISCOVERY,
  GET_BENEFIT_DISCOVERY,
  ADD_BENEFIT_CATEGORY,
  ACTIVE_MODAL_BENEFIT_DISCOVERY_REQUESTING,
  ADD_QUANTITY_COLLABORATORS,
} from './constants'

export function getBenefitDiscoveryList() {
  return { type: GET_BENEFIT_DISCOVERY_LIST }
}

export function createBenefitDiscoveryAction() {
  return { type: CREATE_BENEFIT_DISCOVERY }
}

export function updateBenefitDiscovery(payload) {
  return { type: UPDATE_BENEFIT_DISCOVERY, payload }
}

export function getBenefitDiscovery() {
  return { type: GET_BENEFIT_DISCOVERY }
}

export function addBenefitCategory(payload) {
  return { type: ADD_BENEFIT_CATEGORY, payload }
}

export function setDisplayModal(payload) {
  return { type: ACTIVE_MODAL_BENEFIT_DISCOVERY_REQUESTING, payload }
}

export function addListQuantityCollaborators(payload) {
  return { type: ADD_QUANTITY_COLLABORATORS, payload }
}

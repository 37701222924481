import styled, { css } from 'styled-components'
import { greyColor, pinkColor } from '@flash-tecnologia/flamingo-ui/src/baseStyles'
import Icon from 'antd/lib/icon'

const Commom = css`
  font-size: 18px;
  font-weight: 700;
`

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`

export const Subtitle = styled.h4`
  ${Commom}
  line-height: 24px;
  color: ${greyColor};
`

export const Text = styled.p`
  ${Commom}
  line-height: 26px;
  color: ${greyColor};
  margin-top: 24px;
  font-weight: 400;
`

export const TotalValue = styled.span`
  ${Commom}
  color: ${pinkColor};
  line-height: 26px;
`

export const ReminderCard = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${props => props.flex || 'auto'};
  align-items: center;
  padding: 16px;
  gap: 16px;
  border-radius: 16px;
  margin-top: 24px;
  max-height: ${props => props.maxHeight || 'auto'};
  background-color: ${props => props.backgroundColor || 'rgba(254, 43, 143, 0.08)'};
`

export const ReminderListContainer = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  :is(ul) {
    padding-inline-start: 16px;
  }
`

export const ReminderTitle = styled.strong`
  color: ${pinkColor};
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  margin-bottom: 8px;
`

export const RemiderItem = styled.li`
  font-weight: 400;
  line-height: 22px;
  font-size: ${props => props.size || '14px'};
  color: ${greyColor};
  text-align: left;
  white-space: pre-line;
  :is(li:first-of-type) {
    padding-bottom: 24px;
  }
`

export const StyledIcon = styled(Icon)`
  color: ${pinkColor}!important;
  font-size: 24px;
  @media screen and (max-width: 768px) {
    display: none!important;
  }
`
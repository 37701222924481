import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  GET_COMPANY_BENEFITS_REQUESTING,
  GET_COMPANY_BENEFITS_SUCCESS,
  GET_COMPANY_BENEFITS_ERROR,

  GET_POINTS_CUSTOM_BENEFITS_REQUESTING,
  GET_POINTS_CUSTOM_BENEFITS_SUCCESS,
  GET_POINTS_CUSTOM_BENEFITS_ERROR,

  GET_GRAPH_INFO_ERROR,
  GET_GRAPH_INFO_REQUESTING,
  GET_GRAPH_INFO_SUCCESS,

  GET_MY_REPORTS_ERROR,
  GET_MY_REPORTS_REQUESTING,
  GET_MY_REPORTS_SUCCESS,
  
  SEND_REPORT_ERROR,
  SEND_REPORT_REQUESTING,
  SEND_REPORT_SUCCESS,
} from './constants'

function* getPointsCustomBenefitsFlow(action) {
  try {
    const response = yield call(getPointsCustomBenefits, action.payload)
    yield put({ type: GET_POINTS_CUSTOM_BENEFITS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_POINTS_CUSTOM_BENEFITS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getPointsCustomBenefits() {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const GET_POINTS_CUSTOM_BENEFITS_QUERY = gql`
  query Company ($companyId: ID!) {
    company(_id: $companyId) {
      flashPoints {
        customBenefits {
          _id
          name
        }
      }
    }
  }
  `
  const query = print(GET_POINTS_CUSTOM_BENEFITS_QUERY)
  const variables = { companyId }

  const response = await request.graphql(url, { query, variables })
  return response.data.company?.flashPoints?.customBenefits
}

function* getCompanyBenefitsFlow(action) {
  try {
    const response = yield call(getCompanyBenefits, action.payload)
    yield put({ type: GET_COMPANY_BENEFITS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_COMPANY_BENEFITS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCompanyBenefits() {
  const url = `${urlMap.company}/company/graphql`
  const { companyId } = getFromLS('clientData')
  const GET_COMPANY_BENEFITS_QUERY = gql`
    query($companyId: ID!) {
      getCompanyBenefits(
        companyId: $companyId
      ) {
        plastic {
          _id
          depositCategories {
            key
            name
          }
          key
          name
        }
        plasticExclusiveBalance {
          _id
          key
          name
        }
        virtual {
          _id
          key
          name
        }
      }
    }
  `
  const query = print(GET_COMPANY_BENEFITS_QUERY)
  const variables = { companyId }

  const response = await request.graphql(url, { query, variables })
  return response.data.getCompanyBenefits
}

function* getGraphInfoFlow({ payload }) {
  try {
    const response = yield call(getGraphInfoApi, payload)
    yield put({ type: GET_GRAPH_INFO_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: GET_GRAPH_INFO_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getGraphInfoApi(payload) {
  const { companyId } = getFromLS('clientData')
  const { type } = payload

  const GET_GRAPH_INFO_QUERY = gql`
    query($companyId: ID! $type: String!) {
      getGraphInfo( companyId: $companyId type: $type) {
        awards
        awardsPercent
        category
        convenience
        conveniencePercent
        culture
        culturePercent
        day
        education
        educationPercent
        employeesRegistered
        grocery
        groceryPercent
        health
        healthPercent
        homeOffice
        homeOfficePercent
        meal
        mealPercent
        mealGrocery
        mealGroceryPercent
        meanExpenses
        mobility
        mobilityPercent
        month
        name
        total
        transport
        transportPercent
        virtualAwards
        virtualAwardsPercent
        wellbeing
        wellbeingPercent
        depositsAndPlasticExpensesInfo {
          month
          total
          categories {
            key
            name
            value
            percent
          }
        }
      }
    }`
  const query = print(GET_GRAPH_INFO_QUERY)
  const variables = {
    companyId,
    type
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)

  return response
}


function* getMyReportsFlow({ payload }) {
  try {
    const response = yield call(getMyReportsApi, payload)
    yield put({ type: GET_MY_REPORTS_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: GET_MY_REPORTS_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function getMyReportsApi() {
  const { companyId } = getFromLS('clientData')

  const GET_MY_REPORTS_QUERY = gql`
    query($companyId: ID!) {
      getMyReports( companyId: $companyId) {
          key
          date
          params {
            flashProduct
            productType
            startDate
            endDate
          }
          link
      }
    }`
  const query = print(GET_MY_REPORTS_QUERY)
  const variables = {
    companyId,
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)

  return response
}

function* sendReportFlow({ payload }) {
  try {
    const response = yield call(sendReportApi, payload)
    yield put({ type: SEND_REPORT_SUCCESS, payload: response })
  } catch (error) {
    yield put({
      type: SEND_REPORT_ERROR,
      payload: { error: handleApiError(error) },
    })
  }
}

async function sendReportApi(payload) {
  const { input, reportName } = payload

  const { companyId, adminId } = getFromLS('clientData')

  const SEND_REPORT_QUERY = gql`
    query($companyId: ID! $adminId: ID! $reportName: String! $input: SendReportInput) {
      sendReportV2(
        companyId: $companyId
        adminId: $adminId
        reportName: $reportName
        input: $input
      )
    }`
  const query = print(SEND_REPORT_QUERY)
  const variables = {
    adminId,
    companyId,
    input,
    reportName,
  }
  const options = {
    headers: { 'Content-Type': 'application/json' }
  }
  const url = `${urlMap.company}/company/graphql`
  const response = await request.graphql(url, { query, variables }, options)
  return response
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_COMPANY_BENEFITS_REQUESTING, getCompanyBenefitsFlow),
    takeLatest(GET_POINTS_CUSTOM_BENEFITS_REQUESTING, getPointsCustomBenefitsFlow),
    takeLatest(GET_GRAPH_INFO_REQUESTING, getGraphInfoFlow),
    takeLatest(GET_MY_REPORTS_REQUESTING, getMyReportsFlow),
    takeLatest(SEND_REPORT_REQUESTING, sendReportFlow),
  ])
}

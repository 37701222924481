import { Layout } from 'antd'
import { NotificationPin } from '@flash-tecnologia/flamingo-ui'
import { buttonColor } from '@flash-tecnologia/flamingo-ui/src/baseStyles'
import { urls } from '@flash-tecnologia/flamingo-ui/src/config/env'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import { connect } from 'react-redux'
import AppStyles, {
  blackColor,
  dirtyWhiteColor,
  flexGradient,
  pinkColor,
} from '../../assets/css/baseTextStyles.css'
import BackgroundProcessLoading from '../../components/Molecules/background-process-loading/BackgroundProcessLoading'
import { TopbarUser } from '../../components/topbar'
import Dropdown, { DropdownMenu, MenuItem } from '../../components/uielements/dropdown'
import { getFromLS } from '../../services/security'
import { segmentTracking } from '../../services/utils/segmentTrack'
import { logout } from '../LogInAndOut/actions'

const HROS = process.env.HROS

const { Header } = Layout
const flashLogo = `${urls.s3Images}/flash-logo-gradient.png`
const menuIcon = `${urls.s3Images}/icon-menu-pink.png`

export class Topbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false,
      receivablesModalVisible: false,
      requesting: true,
      SelectedModal: null,
    }

    this.localStyles = {
      drawerStyle: { height: '100vh', backgroundColor: pinkColor },
      headerStyle: {
        backgroundColor: 'transparent',
        borderBottom: '0',
        height: 'auto',
        padding: '3px 0'
      },
      drawerBodyStyle: {
        bodyStyle: {
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          padding: '0'
        },
        linksDiv: {
          borderBottom: '1px solid rgb(0,0,0,0.1)',
          borderTop: '1px solid rgb(0,0,0,0.1)',
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '10px',
          textAlign: 'center'
        },
        linkStyle: (isSelected, isMobile) => ({
          color: '#FFF',
          fontWeight: isSelected ? 'bold' : 'regular',
          fontSize: '16px',
          marginBottom: '10px',
          ...(isMobile
            ? { borderRight: isSelected ? '2px solid white' : '0' }
            : { borderLeft: isSelected ? '2px solid white' : '0' }
          )
        }),
        footerDiv: {
          textAlign: 'center'
        },
        footerText: {
          color: '#FFF',
          cursor: 'pointer',
          fontSize: '16px'
        },
        badgesDiv: {
          background: '#FFFFFF1C',
          border: '2px solid #FFFFFF',
          borderRadius: '4px',
          bottom: '1%',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 10px',
          position: 'absolute',
          textAlign: 'center'
        },
        badgesText: {
          color: '#FFF',
          fontSize: '14px',
          height: '42px',
          lineHeight: 'inherit',
          marginTop: '5px'
        },
        badge: { margin: '5px 0' }
      },
      dropdownMenuText: {
        ...AppStyles.bodyStyle,
        alignItems: 'center',
        color: '#fff',
        cursor: 'pointer',
        display: 'flex',
        height: 'inherit',
        width: '100%'
      },
      headerStyling: {
        alignItems: 'center',
        backgroundColor: dirtyWhiteColor,
        display: 'flex',
        flexDirection: 'row',
        height: 70,
        justifyContent: 'space-between',
        width: '100%'
      },
      linksDiv: (isMobile) => ({
        alignItems: 'center',
        display: 'flex',
        flex: 3,
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'flex-start',
        margin: isMobile ? 0 : '0 80px'
      }),
      logoDiv: {
        marginLeft: '25px',
        alignItems: 'center',
        minWidth: '100px',
        maxWidth: '100px',
      },
      menuText: (path, pin = false) => ({
        ...AppStyles.menuStyle,
        color: this.isSelectedPathName(path) ? pinkColor : blackColor,
        marginRight: !pin && '40px',
        whiteSpace: 'nowrap',
        cursor: 'pointer'
      }),
      userDiv: {
        cursor: 'pointer',
        width: '10%'
      },
      pinMargin: { marginRight: '40px' },
      spaceLinkDropdown:{
        paddingRight: '5px'
      }
    }

    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.renderHeaderContent = this.renderHeaderContent.bind(this)
    this.renderMobileHeader = this.renderMobileHeader.bind(this)
  }

  toggleReceivablesModal = (visible, callback) => {
    this.setState({ receivablesModalVisible: visible }, () => {
      if (!visible) {
        callback()
      }
    })
  }

  benefitMenuHover = () => {
    const { featureConfig } = getFromLS('clientData')
    return (
      <DropdownMenu>
        { featureConfig?.marketplaceEnabled &&
          <MenuItem>
            <NotificationPin
              color={buttonColor}
              horizontalAlign={'right'}
              verticalAlign={'top'}
            >
              <Link to='/dashboard/company/marketplace' style={{ ...this.localStyles.dropdownMenuText,...this.localStyles.spaceLinkDropdown }} onClick={()=> segmentTracking('Portal_Menu_Benefit_Hub_Click')} >Flash Hub - Novidade! </Link>
            </NotificationPin>
          </MenuItem>
        }
        <MenuItem><Link to='/dashboard/company/benefits' style={this.localStyles.dropdownMenuText}>Seus benefícios ativos</Link></MenuItem>
      </DropdownMenu>
    )
  }

  financeMenuHover = () => {
    const localStorage = getFromLS('clientData')
    const {
      permissions: {
        billets,
        deposits,
        flashCash,
      },
    } = localStorage
    return (
      <DropdownMenu>
        { billets?.pageView &&
          <MenuItem><Link to='/dashboard/company/payments' style={this.localStyles.dropdownMenuText} >Boletos</Link></MenuItem>
        }
        { deposits?.pageView &&
          <MenuItem><Link to='/dashboard/company/deposits' style={this.localStyles.dropdownMenuText} >Depósitos</Link></MenuItem>
        }
        { flashCash?.pageView &&
          <MenuItem><Link to='/dashboard/company/balance' style={this.localStyles.dropdownMenuText} >Flash Cash</Link></MenuItem>
        }
      </DropdownMenu>
    )
  }

  buildCompanyTabs = () => {
    const { featureConfig, permissions } = getFromLS('clientData') || {}

    const {
      employeesReducer: {
        companyAdminData,
        companyAdminRequesting,
      },
    } = this.props

    return (
      <React.Fragment>
        { (!featureConfig?.marketplaceEnabled)
          ? <h3><Link to='/dashboard/company/benefits' style={this.localStyles.menuText('benefits')}>Benefícios</Link></h3>
          : <Dropdown
            overlay={this.benefitMenuHover}
            placement="bottomRight"
            style={this.localStyles.menuText('')}
            trigger={['click']}
          >
            <h3><Link to='/dashboard/company/benefits' style={this.localStyles.menuText(['benefits'])} onClick={() => segmentTracking('Portal_Menu_Benefit_Click')} >Benefícios</Link></h3>
          </Dropdown>
        }

        <h3><Link to='/dashboard/company/employees' style={this.localStyles.menuText('employees')} >Colaboradores</Link></h3>

        {
          (permissions?.billets?.pageView || permissions?.deposits?.pageView || permissions?.flashCash?.pageView) &&
            <Dropdown
              overlay={this.financeMenuHover}
              placement="bottomRight"
              style={this.localStyles.menuText('')}
              trigger={['click']}
            >
              <h3 style={this.localStyles.menuText(['balance', 'payments'])}>Financeiro</h3>
            </Dropdown>
        }
        {
          (companyAdminData.steps && companyAdminData.steps.visitedCards || companyAdminRequesting || !Object.keys(companyAdminData).length) ?
            <h3><Link to='/dashboard/company/cards' style={this.localStyles.menuText('cards')} >Cartões</Link></h3>
            :
            <div style={this.localStyles.pinMargin}>
              <NotificationPin
                horizontalAlign={'right'}
                verticalAlign={'top'}
              >
                <h3><Link to='/dashboard/company/cards' style={this.localStyles.menuText('cards', true)} >Cartões</Link></h3>
              </NotificationPin>
            </div>
        }
        {
          (permissions?.reports?.pageView) &&
            <h3>
              <Link to='/dashboard/company/reports' style={this.localStyles.menuText('reports')} onClick={()=> segmentTracking('Portal_Reports_Click')}>
                Relatórios
              </Link>
            </h3>
        }
        {
          (featureConfig?.expenseManagementEnabled && !Number(HROS)) &&
            <h3>
              <Link to='/dashboard/company/expenseManagement' style={this.localStyles.menuText('expenseManagement')} onClick={()=> segmentTracking('Portal_ExpenseManagement_Click')}>
                Despesas Corporativas
              </Link>
            </h3>
        }
        {featureConfig?.flashPointsEnabled && <h3><Link to='/dashboard/company/points' style={this.localStyles.menuText('points')} >Pontos</Link></h3>}
      </React.Fragment>
    )
  }

  isSelectedPathName = (path = '') => {
    const { pathname } = this.props
    const [, , , selectedPath] = pathname.split('/')
    if (typeof path === 'string') {
      return selectedPath === path
    } else if (Array.isArray(path)) {
      return path.reduce((isSelectedPath, pathString) => {
        if (selectedPath === pathString) {
          isSelectedPath = true
        }
        return isSelectedPath
      }, false)
    }
    return false
  }

  handleMenuClick() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  renderHeaderContent() {
    const {
      receivablesModalVisible,
      SelectedModal,
    } = this.state

    const {
      appReducer: {
        isMobile,
      },
    } = this.props

    return (
      <>
        <div style={this.localStyles.logoDiv}>
          <Link to={'/dashboard/company/employees'}>
            <img alt='flash-logo' src={flashLogo} height='30px' />
          </Link>
        </div>

        <div style={this.localStyles.linksDiv(isMobile)}>
          {this.buildCompanyTabs()}
        </div>

        <div
          onClick={() => this.setState({ selectedItem: 'user' })}
          style={this.localStyles.userDiv}
        >
          <TopbarUser />
        </div>
        {
          SelectedModal &&
          <SelectedModal
            isVisible={receivablesModalVisible}
            toggleModal={this.toggleReceivablesModal}
          />
        }
      </>
    )
  }

  renderMobileHeader() {
    const { isMenuOpen } = this.state
    return (
      <div id={'header'} style={{ display: 'flex', width: '100%', height: '64px' }}>
        <Menu
          right
          noOverlay
          isOpen={isMenuOpen}
          width={'60%'}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: flexGradient, height: '100%' }}>
            <div style={{ display: 'flex', width: '100%', height: '9%', alignSelf: 'center', borderBottom: '1px solid white' }}>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center', padding: '0 10px' }}>
                <img
                  alt='menu icon'
                  onClick={() => this.handleMenuClick()}
                  src={`${urls.s3Images}/icon-menu-negative.png`}
                />
              </div>
              <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <Link to='/'>
                  <img
                    alt='flash logo' height='30px'
                    src={`${urls.s3Images}/flash-logo-white.png`}
                  />
                </Link>
              </div>
              <div style={{ display: 'flex', flex: 1 }} />
            </div>
            {this.renderHeaderContent()}
          </div>
        </Menu>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', margin: '5%' }}>
          <div
            style={{ display: 'flex', alignSelf: 'center' }}
            onClick={() => this.handleMenuClick()}
          >
            <Link to='/'>
              <img
                alt='flash logo' height='30px'
                src={`${urls.s3Images}/flash-logo-gradient.png`}
              />
            </Link>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt='menu icon'
              onClick={() => this.handleMenuClick()}
              src={menuIcon}
            />
          </div>

        </div>
      </div>
    )
  }

  render() {
    const {
      appReducer: {
        isMobile,
      },
    } = this.props

    return (
      <>
        <Header
          style={this.localStyles.headerStyling}
        >
          {
            isMobile ?
              this.renderMobileHeader() :
              this.renderHeaderContent()
          }
        </Header>
        <BackgroundProcessLoading/>
      </>
    )
  }
}

const mapStateToProps = ({ employees2, appReducer }) => ({
  appReducer,
  employeesReducer: employees2
})

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar)

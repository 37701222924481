import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urls } from '@flash-tecnologia/flamingo-ui/src/config/env'
import { getFromLS } from '../../../services/security'
import { getBenefitDiscoveryList, getBenefitDiscovery, setDisplayModal } from '../actions'
import { getCompanyDeposits } from '../../Company/Deposits/actions'
import { segmentTracking } from '../../../services/utils/segmentTrack'

import {
  getDisplayBenefitDiscovery,
  getListBenefitDiscovery,
  getTotalDepositsCount,
} from '../benefitDiscovery-selectors'

import { ContainerStyled } from './styles'
import ModalBenefitsDiscovery from './modal'

const BenefitDiscovery = () => {
  const { groupId } = getFromLS('clientData')
  const dispatch = useDispatch()

  const benefitDiscoveryList = useSelector((state) => getListBenefitDiscovery(state))
  const displayBenefitDiscovery = useSelector((state) => getDisplayBenefitDiscovery(state))
  const totalDepositsCount = useSelector((state) => getTotalDepositsCount(state))

  const handleClick = () => {
    dispatch(setDisplayModal(true))
    segmentTracking('Portal_BenefitsDiscovery_SurveyButton_Click')
  }

  useEffect(() => {
    dispatch(getBenefitDiscoveryList())
    dispatch(getCompanyDeposits({ limit: 1, skip: 0, sortBy: { createdAt: -1 }}))
  }, [])
  useEffect(() => {    
    dispatch(getBenefitDiscovery(groupId))
  }, [totalDepositsCount])

  useEffect(() => {
    if (!!benefitDiscoveryList.length && displayBenefitDiscovery) {
      segmentTracking('Portal_BenefitsDiscovery_SurveyButton_View')
    }
  }, [benefitDiscoveryList, displayBenefitDiscovery])

  return (
    <>
      {!!benefitDiscoveryList.length && displayBenefitDiscovery && (
        <>
          <ContainerStyled>
            <button onClick={handleClick}>
              <img src={`${urls.s3Icons}/interrogation.svg`} alt='interrogation icon'/>              
            </button>
          </ContainerStyled>
          <ModalBenefitsDiscovery />
        </>
      )} 
    </>
  )
}

export default BenefitDiscovery

import {
  CREATE_FILE_SUPPORT_REQUESTING,
  CREATE_FILE_SUPPORT_SUCCESS,
  CREATE_FILE_SUPPORT_ERROR,
} from './constants'

const initialState = {
  createFileSupportResquesting: false,
  createFileSupportSuccessful: false,
  createFileSupportError: '',
}

export default function (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case CREATE_FILE_SUPPORT_REQUESTING:
      return {
        ...state,
        createFileSupportResquesting: true,
        createFileSupportSuccessful: false,
      }
    case CREATE_FILE_SUPPORT_SUCCESS:
      return {
        ...state,
        createFileSupportResquesting: false,
        createFileSupportSuccessful: true,
        createFileSupportError: '',
      }
    case CREATE_FILE_SUPPORT_ERROR:
      return {
        ...state,
        createFileSupportResquesting: false,
        createFileSupportSuccessful: false,
        createFileSupportError: payload.error,
      }
    default:
      return state
  }
}

const {
  encode64,
  decode64,
  encodeUtf8,
  decodeUtf8
} = require('node-forge').util

module.exports = { encode, decode }

function encode(str) {
  return encode64(encodeUtf8(str))
}

function decode(encoded) {
  if (!encoded) return
  return decodeUtf8(decode64(encoded))
}
import { print } from 'graphql'
import gql from 'graphql-tag'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleApiError, request, urlMap } from '../../../services/api'
import { getFromLS } from '../../../services/security'
import {
  GET_BILLET_REPORT_LINK_ERROR,
  GET_BILLET_REPORT_LINK_REQUESTING,
  GET_BILLET_REPORT_LINK_SUCCESS,
  GET_COMPANY_BILLETS_ERROR,
  GET_COMPANY_BILLETS_REQUESTING,
  GET_COMPANY_BILLETS_SUCCESS,
  UPDATE_BILLET_ERROR,
  UPDATE_BILLET_REQUESTING,
  UPDATE_BILLET_SUCCESS,
  UPDATE_DEPOSIT_DATE_ERROR,
  UPDATE_DEPOSIT_DATE_REQUESTING,
  UPDATE_DEPOSIT_DATE_SUCCESS,
} from './constants'


function* getBilletReportTempLinkFlow({ payload }) {
  try {
    const response = yield call(getBilletReportTempLinkApi, payload)
    yield put({ type: GET_BILLET_REPORT_LINK_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_BILLET_REPORT_LINK_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getBilletReportTempLinkApi({ reportKey }) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const QUERY_STRING = gql`
    query($companyId: ID! $reportKey: String! ) {
      getBilletReportDownloadableLink(
        companyId: $companyId
        reportKey: $reportKey
      )
    }
  `

  const query = print(QUERY_STRING)
  const variables = {
    companyId,
    reportKey,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.getBilletReportDownloadableLink
}

function* getCompanyBilletsFlow(action) {
  try {
    const response = yield call(getCompanyBillets, action.payload)
    yield put({ type: GET_COMPANY_BILLETS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_COMPANY_BILLETS_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function getCompanyBillets({
  limit,
  skip,
  sortBy,
  filter,
}) {
  const { companyId } = getFromLS('clientData')
  const url = `${urlMap.company}/company/graphql`

  const GET_BILLETS_QUERY = gql`
    query($companyId: ID! $limit: Int $skip: Int $sortBy: SortInput $filter: FilterInput) {
      getBillets(
        companyId: $companyId,
        limit: $limit,
        skip: $skip,
        sortBy: $sortBy
        filter: $filter
        ) {
          billets {
            _id
            accountAmountCharged
            billetDocNumber
            billetLink
            billetNumber
            cashDepositAmount
            companyDeposit {
              _id
              cancelled
              createdAt
              creationConfirmed
              employeeCreditDate
              employeeDepositsCount
              uploadedFile
              value
            }
            createdAt
            dueDate
            debitInvoiceLink
            employeesDepositAmount
            financialPartnerBilletId
            invoice {
              link
            }
            reportKey
            paidAt
            postPaid
            status
            type
          }
          totalBilletsCount
        }
    }
  `

  const query = print(GET_BILLETS_QUERY)
  const variables = { companyId, limit, skip, sortBy, filter }

  const response = await request.graphql(url, { query, variables })

  return response.data.getBillets
}

function* updateBilletFlow({ payload }) {
  try {
    const response = yield call(updateBillet, payload)
    yield put({ type: UPDATE_BILLET_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_BILLET_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateBillet({ billetId }) {
  const { companyId } = getFromLS('clientData')
  const UPDATE_BILLET_QUERY = gql`
    mutation($billetId: ID! $companyId: ID!) {
      cancelBillet(
        billetId: $billetId,
        companyId: $companyId,
      ){
        cancelled
      }
    }
  `
  const query = print(UPDATE_BILLET_QUERY)

  const url = `${urlMap.company}/company/graphql`
  const variables = { billetId, companyId }

  const response = await request.graphql(url, { query, variables })

  return response.data.cancelBillet
}

function* updateDepositDateFlow({ payload }) {
  try {
    const response = yield call(updateDepositDate, payload)
    yield put({ type: UPDATE_DEPOSIT_DATE_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: UPDATE_DEPOSIT_DATE_ERROR, payload: { error: handleApiError(error) }})
  }
}

async function updateDepositDate({ companyDepositId, creditDate }) {
  const {
    adminId,
    companyId,
  } = getFromLS('clientData')

  const UPDATE_DEPOSIT_DATE_QUERY = gql`
    mutation($adminId: ID! $companyId: ID! $companyDepositId: ID! $creditDate: DateTime!) {
      updateDepositCreditDate(
        adminId: $adminId,
        companyId: $companyId,
        companyDepositId: $companyDepositId,
        creditDate: $creditDate,
      )
    }
  `
  const query = print(UPDATE_DEPOSIT_DATE_QUERY)

  const url = `${urlMap.company}/company/graphql`
  const variables = {
    adminId,
    companyId,
    companyDepositId,
    creditDate,
  }

  const response = await request.graphql(url, { query, variables })

  return response.data.updateDepositCreditDate
}

export default function* rootSaga() {
  yield all([
    takeLatest(GET_BILLET_REPORT_LINK_REQUESTING, getBilletReportTempLinkFlow),
    takeLatest(GET_COMPANY_BILLETS_REQUESTING, getCompanyBilletsFlow),
    takeLatest(UPDATE_BILLET_REQUESTING, updateBilletFlow),
    takeLatest(UPDATE_DEPOSIT_DATE_REQUESTING, updateDepositDateFlow),
  ])
}
